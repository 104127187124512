<template>
    <v-dialog
        persistent
        @click:outside="falseButtonClicked()"
        @keydown.esc="falseButtonClicked()"
        v-model="internalShowDialog"
        min-width="400"
        max-width="500"
    >
        <v-card>
            <v-card-title class="text-title">
                {{
                    openedForOperation == "error"
                        ? $t("error")
                        : openedForOperation == "message"
                        ? $t("message")
                        : $t("confirmation")
                }}
            </v-card-title>

            <v-card-text v-html="text" class="dialog-text text-content" color="black">
                
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="falseText"
                    color="grey lighten-2"
                    class="px-4"
                    @click="falseButtonClicked()"
                >
                    {{ falseText }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn
                    v-if="trueText"
                    class="px-4"
                    :color="
                        openedForOperation == 'delete' ||
                        openedForOperation == 'error'
                            ? 'error'
                            : openedForOperation == 'message'
                            ? 'primary'
                            : openedForOperation == 'make_bill_error'
                            ? 'error'
                            : 'success'
                    "
                    @click="trueButtonClicked()"
                >
                    {{ trueText }}
                    <v-icon dark right v-if="openedForOperation == 'delete'">
                        mdi-delete
                    </v-icon>
                    <v-icon
                        dark
                        right
                        v-else-if="openedForOperation == 'logout'"
                    >
                        mdi-logout
                    </v-icon>
                    <v-icon dark right v-else> mdi-check </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
    name: "ConfirmationModel",
    components: {},
    props: {
        showDialog: [Number, Boolean],
        openedForOperation: [String],
        text: [String],
        trueText: [String],
        falseText: [String],
    },
    data() {
        return {
            internalShowDialog: this.showDialog,
        };
    },
    methods: {
        falseButtonClicked(item) {
            this.$emit("update:showDialog", false);
            this.$emit("buttonClicked", false);
            this.internalShowDialog = false;
        },
        trueButtonClicked(item) {
            this.$emit("update:showDialog", false);
            this.$emit("buttonClicked", true);
            this.internalShowDialog = false;
        },
    },
    computed: {},
    watch: {
        showDialog: {
            handler: function(val) {
                this.internalShowDialog = val;
            },
        },
    },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
    padding: 0 24px 27px !important;
}
.text-content {
    font-size: 18px !important;
}
.text-title {
    font-size: 24px !important;
}
</style>
