<template>
  <div>
  <v-dialog v-model="showDialog" @click:outside="closeOrderDetails()" @keydown.esc="closeOrderDetails()" persistent max-width="1024px">
    <v-card v-if="rowData">
      <v-card-title>
        <span class="text-h5">{{$t('stocks.order')}} - {{ columnData.text }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="mb-n8">
            <v-col cols="12" sm="10">
              <p class="orderTitle">
                {{ rowData.description }} 
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2">
              <v-btn :loading="loading" :disabled="loading" color="primary" @click="getStockAvailability()">
                {{ $t('stocks.refresh') }}
                <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      color="grey"
                      :size="15"
                      :width="1"
                    ></v-progress-circular>
                  <span class="ml-2"> {{ $t('stocks.fetching') }}</span>
                </template>
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <p class="orderSubTitle"> {{ rowData.identifier }} </p>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col align="right" style="color:red">
              <template v-if="form && form.quantity_to_order">
              {{ parseInt(form.quantity_to_order) > parseInt(this.rowData[`${this.columnData.field_stock}`]) ? $t('stocks.quantity_to_order_is_greater_then_available') : errorMessage }}
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-img
                v-if="! rowData || ! rowData.identifier"
                lazy-src="https://www.jktyre.com/smarttyre/img/tyre.png"
                max-height="550"
                max-width="250"
                src="https://www.jktyre.com/smarttyre/img/tyre.png"
              ></v-img> -->
              <v-layout justify-center align-center>
                <v-flex v-if="! imageError" shrink>
                  <v-img
                    style="max-height: 350px; max-width: 350px"
                    v-if="rowData && rowData.identifier"
                    :lazy-src="articleImageBaseUrl + rowData.identifier"
                    :src="articleImageBaseUrl + rowData.identifier"
                    v-on:error="onImgError"
                  >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  </v-img>
                </v-flex>
                <v-flex v-if="imageError" shrink>
                  <v-img
                    style="max-height: 350px; max-width: 350px"
                    v-if="rowData.identifier"
                    lazy-src="@/assets/images/no-image.png"
                    src="@/assets/images/no-image.png"
                  ></v-img>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col>
                <v-autocomplete
                  v-model="form.client"
                  class="mr-2 to-upper"
                  :items="clients"
                  :loading="isLoading"
                  :search-input.sync="search"
                  item-text="name"
                  item-value="id"
                  :label="$t('stocks.client_or_internal')"
                  placeholder="Search Client - Registration No - VIN"
                  @input="debounceInput"
                  return-object
                  no-filter
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  hide-no-data
                >
                </v-autocomplete>
              <v-row  class="order-detail-text">
                <v-col>
                  <v-row v-if="form.client"> 
                    <v-col sm="5">{{$t('stocks.label_ordered_for')}} </v-col>
                    <v-col class="order-detail-text-right">{{ form.client.name }} {{ form.client.first_name ? form.client.first_name : '' }}</v-col>
                  </v-row>
                  <v-row> 
                    <v-col sm="5">{{$t('stocks.label_stocks')}} </v-col>
                    <v-col class="order-detail-text-right">{{ rowData.stock }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="5">{{$t('stocks.label_reserve')}} </v-col>
                    <v-col class="order-detail-text-right">{{ rowData.reserve }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="5">{{$t('stocks.label_dispo')}} </v-col>
                    <v-col class="order-detail-text-right">{{ form ? form.dispo : '' }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="5">{{$t('stocks.label_new_dispo')}} </v-col>
                    <v-col class="order-detail-text-right">{{ form ? (isNaN(form.new_dispo) ? 0 : form.new_dispo) : '' }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="7">{{$t('stocks.label_current_stock_price')}} </v-col>
                    <v-col class="order-detail-text-right">{{ form ? (fixDecimal(isNaN(form.current_stock_price) ? 0 : form.current_stock_price)) : '' }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="7">{{$t('stocks.label_new_stock_price')}} </v-col>
                    <v-col class="order-detail-text-right">{{ form ? (fixDecimal(isNaN(form.new_stock_price) ? 0 : form.new_stock_price)) : '' }} </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                  <v-col class="order-detail-text">
                    <v-row>
                      <v-col sm="6"> {{$t('stocks.delivery_date')}} </v-col>
                      <v-col class="order-detail-text-right">{{ rowData[`${columnData.field_delivery_date}`]  }} </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="10"> {{$t('stocks.quantity_to_order')}} </v-col>
                      <v-text-field 
                        v-if="form"
                        :autocomplete="autocompleteValueLocal"
                        style="width:40px;"
                        v-model="form.quantity_to_order"
                        class="pt-0 pr-4 right-aligned-input to-upper"
                        hide-details="auto"
                        align="right"
                        v-on:keydown="handleInput($event,'int',3);"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                      ></v-text-field> 
                    </v-row>
                    <v-row>
                      <v-col sm="7"> {{$t('stocks.price')}}  </v-col>
                      <v-col class="order-detail-text-right">{{ fixDecimal(rowData[`${columnData.field_price}`]) }} </v-col>
                    </v-row>
                  </v-col>
              </v-row>
              <div id="divider" style="background-color:#441468; height: 2px; width:100%;margin:5px 0px 5px 0px"></div>
              <v-row>
                  <v-col sm="8" class="order-detail-text">
                    <strong> {{$t('stocks.total')}} <br> </strong>
                    <strong> {{$t('stocks.totalwva')}} ({{form ? form.tva : ''}}%): <br> </strong>
                  </v-col>
                  <v-col class="order-detail-text-right">
                    <strong> {{ form ? form.total_price : '' }} </strong> <br>
                    <strong> {{ form ? form.price_with_tva : '' }} </strong> <br>
                  </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-if="form" :autocomplete="autocompleteValueLocal" :outlined="is_boxes" :dense="is_boxes" class="mr-3 to-upper" :label="$t('stocks.order_reference')" v-model="form.order_reference"> </v-text-field>
                  <v-textarea v-if="form" :autocomplete="autocompleteValueLocal" :outlined="is_boxes" :dense="is_boxes" class="to-upper" rows="2" :label="$t('stocks.message_for_order')"  v-model="form.message_for_order"></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-2">
        <v-spacer></v-spacer>
        <v-btn :disabled="ordering" color="grey lighten-2" @click="closeOrderDetails()">
          {{ $t('stocks.cancel') }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn v-if="form" :loading="ordering" color="success" @click="submitOrderDetails()" :disabled="ordering || isNaN(parseInt(form.quantity_to_order)) || parseInt(form.quantity_to_order) == 0 || loading || parseInt(form.quantity_to_order) > parseInt(this.rowData[`${this.columnData.field_stock}`])">
          {{ $t('stocks.order') }}
          <v-icon dark right> mdi-content-save </v-icon>
          <template v-slot:loader>
              <v-progress-circular
                indeterminate
                color="grey"
                :size="15"
                :width="1"
              ></v-progress-circular>
            <!-- <template class="ma-2"> {{ $t('stocks.fetching') }}</template> -->
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <confirmation-model
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
  ></confirmation-model>
  </div>
</template>

<style scoped>
    .right-aligned-input >>> input {
      text-align: right
    }
    .v-alert {
      z-index: 99;
    }
    .v-alert-positation-wrapper .v-alert-positation {
      top: 1em;
    }
</style>

<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import { API_BASE_URL } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  components:{
    ConfirmationModel
  },
  props: {
    rowData: {
      type: Object,
    },
    columnData: {
      type: Object,
    },
    brands:{
      type: Array
    },
    showDialog:{
      type: Boolean
    }
  },
  data() {
    return {
      imageError: false,
      articleImageBaseUrl: API_BASE_URL + '/article/image/',
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      form: null,
      loader:                       null,
      loading:                      false,
      ordering:                     false,
      search:                       null,
      companyArticleDetails:        {},
      alerts:                       [],
      clients:                      [],
      isLoading:                    false,
      brand:                        null,
      internal_stock:               {
                                      id: 0,
                                      name: "INTERNAL STOCK",
                                      registration_number: null,
                                      vin: null
                                    },
      autocompleteValueLocal : 'off',
      header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
      errorMessage: null,
      confirmationDialog: false,
      confirmationDialogConfirmationText:null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      lastSupplierOrderCreated: null
    };
  },
  mounted(){
    this.autocompleteValueLocal = this.autocompleteValue();
    this.message_for_order = '';
    this.order_reference = '';
    
  },
  methods: {
    onImgError() {
      console.log("Invalid Image");
      this.imageError = true;
    },
    debounceInput: _.debounce(function (val) {
        console.log("xrdctfv");
      this.getClients(val);
    }, 500),
    retryPopUp(){
        this.confirmationDialogConfirmationText = this.$t("stocks.retry_order_confirmation");
        this.confirmationDialogFalseText = this.$t("stocks.cancel");
        this.confirmationDialogTrueText = this.$t("stocks.keep_trying");
        this.confirmationDialogOperation = 'keep_trying';
        this.confirmationDialog = true;
    },
    confirmationButtonClicked(buttonClicked){
        this.confirmationDialogButtonClickedIs = buttonClicked;
        this.confirmationDialog=false;
    },
    getCompanyArticleDeatails() {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/company_article/'+this.rowData.article_id+"?supplier_id="+this.columnData.supplier_detail.id, {headers: this.header})
        .then(({data}) => {
            this.companyArticleDetails = data;
            this.form.tva = data.tva.tax;
            resolve();
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
            reject();
        });
      });
    },
    closeOrderDetails: function (value) {
      this.showDialog = false;
      this.$emit('changeDialog', false);
    },
    getClients: function(searchString) {
        console.log("search string", searchString);
      if(!searchString)
        return;
      if(typeof searchString  == "object")
        return;
      
      this.isLoading = true;
      axios.get(API_BASE_URL + '/clients/search?searchRegNumber=true&search=' + searchString,{headers: this.header})
        .then(({ data }) => {
          let customData = [];
          if(data.data){
            data.data.forEach(element => {
              customData.push({
                id: element.id,
                name: element.name + ' ' +
                    (element.first_name ?? '') +
                      (element.registration_number ? ", Reg. No : " + element.registration_number  : "") +
                      (element.vin ? ", VIN : " + element.vin : ""),
                registration_number: element.registration_number,
                vin: element.vin
              });
            });
          }
          customData.push(this.internal_stock);
          this.clients = customData;
        })
        .catch(function (error) {
          console.log('an error occured ' + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
        this.isLoading = false;
    },
    calculateNewDispo(){
      
      if( this.form.client != null  && this.form.client.id == 0){ // means if it is Internal
        this.form.new_dispo = parseInt(this.rowData.stock) + parseInt(this.form.quantity_to_order);
      }
      else{
        this.form.new_dispo = parseInt(this.rowData.stock);
      }
      
    },
    makeOrderToSupplier(supplierOrderId){
      this.errorMessage = null;
      axios.post(API_BASE_URL + '/suppliers/order/'+supplierOrderId, this.form, {headers: this.header})
      .then(({data}) => {
          if(typeof data.data[Object.keys(data.data)[0]] == "string"){
            this.$noty.warning( data.data[Object.keys(data.data)[0]], { timeout: 5000, layout: 'bottomLeft'});
          }
          else{
            let response = data.data[Object.keys(data.data)[0]];
            if(response.error_code == 0){
              if(response.availability > -1){
                this.$noty.success( this.$t('stocks.order_placed_successfully') , { timeout: 5000, layout: 'bottomLeft'});
                this.closeOrderDetails();
              }
              else{
                this.retryPopUp();
                this.errorMessage = this.$t('stocks.error_while_making_connection_to_supplier');
              }
            }
            else{
              this.retryPopUp();
              this.$noty.error( response.error_message, { timeout: 5000, layout: 'bottomLeft'});
            }
          }
      })
      .catch(error => {
          this.retryPopUp();
          this.$noty.error( this.$t('stocks.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
      })
      .finally(() => {
        this.$store.state.isLoading = false;
        this.ordering = false;
      })
    },
    submitOrderDetails(){
      this.closeOrderDetails();
      this.$store.state.isLoading = true;
      this.ordering = true;
      axios.post(API_BASE_URL + '/orders', this.form, {headers: this.header})
        .then(response => {
          console.log(response,response.status);
          if (response.status === 201) {
            console.log("supplier order", response.data.data);
            this.lastSupplierOrderCreated = response.data.data;
            this.makeOrderToSupplier(response.data.data.id);
            
          }
          else {
            // alert('ERROR on saving. Please try again later');
            this.$noty.error( this.$t('stocks.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
            
          }
        })
        .catch(error => {
            this.$noty.error( this.$t('stocks.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
        })
        .finally(() => {
          this.$store.state.isLoading = false;
        })
    },
    calculateNewStockPrice(){
      // Please dont delete the below table, its for reference
      //            Stock      Reserve      Unit Price            Total       NewPrice
      // Current     4           2            30                   180          30.8
      // New         4                        32->(col. Price)     128
      let currentTotal = (this.rowData.stock + this.rowData.reserve ) * this.rowData.price;
      console.log(currentTotal,"Current TOtal");
      let newTotal = this.form.quantity_to_order * this.form.price;
      
      console.log(this.rowData.stock,"Stock");
      console.log(this.rowData.reserve,"reserve");
      console.log(this.form.quantity_to_order,"Quantity");
      console.log( currentTotal + newTotal,"New TOtal");
      console.log( currentTotal + newTotal,"Bottom Total");
      console.log(Number( ( currentTotal + newTotal ) / (parseInt(this.rowData.stock) + parseInt(this.rowData.reserve) + parseInt(this.form.quantity_to_order)) ).toFixed(this.getDecimalNumber()),"New Stock Price");
      this.form.new_stock_price = Number( ( currentTotal + newTotal ) / (parseInt(this.rowData.stock) + parseInt(this.rowData.reserve) + parseInt(this.form.quantity_to_order)) ).toFixed(this.getDecimalNumber());
    },
    getStockAvailability(){
        this.loader = true;
        this.errorMessage = null;
        axios.get(API_BASE_URL + '/suppliers/'+ this.columnData.supplier_detail.id +'/availability/'+ this.rowData.article_id, {headers: this.header})
        .then(({data}) => {
          let response = data.data[Object.keys(data.data)[0]];
          if(response.error_code == 0){
            if(response.availability > -1){
              this.rowData[`${this.columnData.field_stock}`] = response.stock;
              this.rowData[`${this.columnData.field_delivery_date}`]  = response.delivery_date;
            }
            else{
              this.errorMessage = this.$t('stocks.error_while_making_connection_to_supplier');
            }
          }
          else{
            this.$noty.error( response.error_message, { timeout: 5000, layout: 'bottomLeft'});
          }
          console.log("response data", response);
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    deleteSupplierOrder(){
      this.$store.state.isLoading = true;
      console.log("supplier order", this.lastSupplierOrderCreated);
      axios.delete(API_BASE_URL + '/suppliers/order/'+ this.lastSupplierOrderCreated.id, {headers: this.header})
        .then(({data}) => {
          
      })
      .catch(function (error) {
          console.log('an error occured ' + error);
      })
      .finally(() => {
        this.$store.state.isLoading = false;
      });
    },
    resetData(){
      this.form.quantity_to_order = 4;
      this.calculateNewDispo();
      this.getClients();
      this.rowData.stock = this.rowData.stock ?? 0;
      this.rowData.reserve = this.rowData.reserve ?? 0;
      this.rowData.message_for_order = "",
      this.rowData.order_reference = ""; 
    }
  },
  computed:{
    
  },
  watch:{
    showDialog(val){
      if(val == false){ // reset data on closing the dialog
        this.resetData();
      }
    },
    rowData(val){
      if(val){
        this.form = {
          quantity_to_order:          0,
          price:                      this.rowData[`${this.columnData.field_price}`] ,
          unit_client_price:          this.rowData[`${this.columnData.field_client_price}`] ,
          delivery_date:              this.rowData[`${this.columnData.field_delivery_date}`] ,
          total_price:                 0,
          tva:                        this.rowData.tax,
          price_with_tva:               0,
          client:                     '',
          dispo:                      this.rowData.stock - this.rowData.reserve,
          new_dispo:                   0,
          current_stock_price:          this.rowData.price,
          new_stock_price:              this.rowData.price,
          article_id:                 this.rowData.article_id,
          supplier_id:                (this.columnData && this.columnData.supplier_detail) ?  (this.columnData.supplier_detail.id == 0 ? this.rowData.brand_supplier_id : this.columnData.supplier_detail.id) : 0,
          message_for_order:          '',
          order_reference:            '',
        };

        this.clients = [
          this.internal_stock
        ];
        this.form.client = this.clients[0];
        if(this.form.supplier_id  == 0) { 
          // means user has clicked on brand column,  then in this case we will replace supplier_id with 0 to rowData.brand_id
          this.form.supplier_id = this.rowData.brand_id;
        }
        this.getCompanyArticleDeatails().then(() => {
          this.form.quantity_to_order = 4;
          this.calculateNewDispo();
          this.getClients();
          this.rowData.stock = this.rowData.stock ?? 0;
          this.rowData.reserve = this.rowData.reserve ?? 0;
        });

        // Add Brand Name in Pop Up
        if(this.rowData.brand_id){
          this.brands.forEach((brand) => {
            if(brand.id == this.rowData.brand_id){
              console.log("Brand ID", brand);
              this.brand = brand;
            }
          })
        }
      }
    },
    "form.quantity_to_order":{
      handler: function(newValue){
          this.form.total_price = Number(parseFloat(this.form.quantity_to_order * this.form.price)).toFixed(this.getDecimalNumber());
          this.form.price_with_tva = Number(parseFloat(parseFloat(this.form.total_price) + (parseFloat(this.form.total_price) *  parseFloat(this.form.tva) ) / 100)).toFixed(this.getDecimalNumber());
          this.calculateNewDispo();
          this.calculateNewStockPrice();
      },
    },
    confirmationDialogButtonClickedIs(newVal){
        if(newVal == false &&  this.confirmationDialogOperation != null){ // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
            if(this.confirmationDialogOperation == 'keep_trying'){
              if(this.confirmationDialogButtonClickedIs == false){ 
                this.deleteSupplierOrder();
              }
            }
            this.confirmationDialogButtonClickedIs = null;
        }
    },
    search (val) {
      if(val && typeof this.form.client == 'string' && val != this.form.client.name){
        if(val == this.form.client.name)
          return;
        // Items have already been requested
        if (this.isLoading)
          return;
        
        this.debounceInput(val);
      }
      else{
        this.debounceInput(val);
      }
    },
    "form.client":{
      handler: function(oldValue, newValue){
          this.calculateNewDispo();
      },
    },
  }
};
</script>