import {API_BASE_URL} from './config'
export default {
    data(){
        return{
            multiselectsValues: [],
            multiselects: [],
        };
    },
    computed:{
        userLanguageId(){
            return this.$store.state.languageId ;
        },
        cachedData(){
            return this.$store.state.cachedData
        },
        familyArticles(){
            return this.$store.state.familyArticles;
        },
        approvals(){
            return this.$store.state.approvals;
        },
        tyreTypes(){
            return this.$store.state.tyreTypes;
        },
        wheelTypes(){
            return this.$store.state.wheelTypes;
        },
        tyreSeasons(){
            return this.$store.state.tyreSeasons;
        },
        statuses(){
            return this.$store.state.statuses;
        },
        familyClients(){
            return this.$store.state.familyClients;
        },
        billTypes(){
            return this.$store.state.billTypes;
        },
        resourceTypes(){
            return this.$store.state.resourceTypes;
        },
        allCodes(){
            let codes = [];
            if(this.tyreTypes){
                codes = codes.concat(this.translateKeys( this.tyreTypes, "key", "types"))
            }
            if(this.tyreSeasons){
                codes = codes.concat(this.translateKeys( this.tyreSeasons, "key", "seasons"))
            }
            if(this.wheelTypes){
                codes = codes.concat(this.translateKeys( this.wheelTypes, "key", "types"))
            }
            return codes;
        }
    }
};
