<template>
    <v-card class="elevation-0">
        <v-tabs
            v-if="checkTag('tyre_depot') || checkTag('billing')"
            v-model="tab"
            background-color="light-blue darken-2"
            dark
            align-with-title
            center-active
            height="50px"
        >
            <v-tab v-if="checkTag('tyre_depot')" href="#vehicle-tires-hotel">
                {{ $t("vehicles.vehicle_tab.tyre_hotel") }}
            </v-tab>
            <v-tab v-if="checkTag('vehicles')" href="#vehicle-orders">
                {{ $t("vehicles.vehicle_tab.attachments") }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                :key="'vehicle-tires-hotel'"
                :value="'vehicle-tires-hotel'"
            >
                <depot-tab :vehicle="vehicle"></depot-tab>
            </v-tab-item>
            <v-tab-item :key="'vehicle-orders'" :value="'vehicle-orders'">
                <attachment-listing v-if="vehicle" :resource="'vehicles'" :data="vehicle.id"></attachment-listing>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import AttachmentsListing from "@/components/AttachmentsListing";
import DepotTab from "@/components/Depot/DepotTab";
import _ from "lodash";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
    components: {
        "depot-tab": DepotTab,
        "attachment-listing": AttachmentsListing,
    },
    props: {
        vehicle: [Object],
    },
    name: "Vehicle-Tabs",
    data() {
        return {
            tab: null,
        };
    },
    computed: {},
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped></style>
