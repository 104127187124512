<template>
        <v-row>
        <v-col class="pt-0 pb-0">
          <v-combobox
            :autocomplete="autocompleteValueLocal"
            v-model="registration_no"
            :items="client_vehicles"
            item-text="registration_number"
            item-value="id"
            class="to-upper"
            :prepend-icon="'mdi-plus'"
            @click:prepend="clearClientVehicles"
            :label="$t('billings.registration_no')"
            v-on:keydown="handleInput($event,'alphanumdash',15);"
            :error-messages="$store.state.validation ?  returnErrorMessage(
                [!$v.registration_no.maxLength,this.$t('billings.text_messages.registration_number_max_length')],
                [!$v.registration_no.required,this.$t('billings.text_messages.registration_number_required_if_other_details_filled_up')]
              ) : []"
            v-on:keydown.enter.prevent=""
            :outlined="is_boxes"
            :dense="is_boxes"
          >
            <template v-if="registration_no && typeof registration_no == 'object' && registration_no.is_out_of_circulation" v-slot:prepend>
              <v-tooltip top z-index="9999">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ma-0 mr-1" color="warning"  v-bind="attrs" v-on="on">
                    mdi-car-arrow-right
                  </v-icon>
                </template>
                {{ $t('vehicle_is_out_of_circulation') }}
              </v-tooltip>
            </template>
            <template v-slot:item="{ index, item }">
              <template v-if="item && item.is_out_of_circulation">
                <v-tooltip top z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" color="warning"  v-bind="attrs" v-on="on">
                      mdi-car-arrow-right
                    </v-icon>
                  </template>
                  {{ $t('vehicle_is_out_of_circulation') }}
                </v-tooltip>
              </template>
              {{ item.registration_number }}
            </template>
          </v-combobox>
        </v-col>
        <v-col class="pt-0 pb-0">
          <v-autocomplete
            :autocomplete="autocompleteValueLocal"
            v-model="brand"
            :items="brands"
            :search-input.sync="searchBrand"
            item-text="name"
            item-value="id"
            class="to-upper"
            :label="$t('billings.brand')"
            :error-messages="$store.state.validation && !$v.brand.maxLength ? [this.$t('billings.text_messages.brand_max_length')] : [] "
            v-on:keydown="handleInput($event,'justalphanumeric',25);"
            v-on:keydown.enter.prevent=""
            @keyup="$emit('update:comp_brand', brand);"
            @change="$emit('update:comp_brand', brand);"
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-autocomplete>
        </v-col>
        <v-col class="pt-0 pb-0">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="model"
            :error-messages="$store.state.validation && !$v.model.maxLength ? [this.$t('billings.text_messages.model_max_length')] : [] "
            class="to-upper"
            :label="$t('billings.model')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event,'alphanumeric',80);"
            @keyup="$emit('update:comp_model', model);"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="km"
            :error-messages="$store.state.validation && !$v.km.maxLength ? [this.$t('billings.text_messages.km_max_length')] : [] "
            class="to-upper"
            :label="$t('billings.km')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event,'float',15);"
            @keyup="$emit('update:comp_km', km);"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0">
          <v-menu
                v-model="menu_reg_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="reg_date"
                  :label="$t('billings.registration_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append="reg_date = null"
                  append-icon="mdi-close"
                  class="mr-2 to-upper"
                  :outlined="is_boxes"
                  :dense="is_boxes"
              ></v-text-field>
              </template>
              <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  v-model="reg_date"
                  @input="menu_reg_date = false"
                  no-title 
                  scrollable
                  :locale="userLanguageId"
              ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="pt-0 pb-0">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="vin"
            name="vin"
            class="to-upper"
            :label="$t('billings.vin')"
            v-on:keydown.enter.prevent=""
            @keyup="$emit('update:comp_vin', vin);"
            :error-messages="$store.state.validation && !$v.vin.maxLength ? [this.$t('billings.text_messages.vin_max_length')] : [] "
            v-on:keydown="handleInput($event,'alphanumdash',17);"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0">
          <v-combobox
            :autocomplete="autocompleteValueLocal"
            v-model="vehicle_type"
            :items="vehicle_types"
            item-text="text"
            item-value="id"
            class="to-upper"
            :label="$t('billings.vehicle_type')"
            v-on:keydown="handleInput($event,'alphanumdash',15);"
            v-on:keydown.enter.prevent=""
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-combobox>
        </v-col>
        <v-col class="pt-0 pb-0">
          <v-autocomplete
            :autocomplete="autocompleteValueLocal"
            v-model="creditor"
            :items="creditors"
            item-text="name"
            item-value="id"
            :label="$t('billings.creditors')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event,'justalphanumeric',35);"
            @change="$emit('update:comp_creditor', creditor);"
            clearable
            :outlined="is_boxes"
            :dense="is_boxes"
            class="to-upper"
            hide-no-data
          ></v-autocomplete>
        </v-col>
        <v-col class="mt-4 pt-0 pb-0" v-on:keydown.enter.prevent="is_leasing = !is_leasing">
          <v-checkbox
            class="mt-0"
            v-model="is_leasing"
            :label="$t('billings.is_leasing')"
            @change="$emit('update:comp_is_leasing', is_leasing);"
          ></v-checkbox>
        </v-col>
        <v-col class="mt-2 pt-0 pb-0" v-on:keydown.enter.prevent="is_leasing = !is_leasing">
        <v-btn :disabled="!registration_no || (registration_no && typeof registration_no != 'object')" id="attachments" class="ma-2 text-white-color"  color="success" @click="showAttachmentDialog()">
            {{ $t("attachments") }} ({{ $store.state.attachmentCounts}})
            <v-icon dark right> mdi-paperclip </v-icon>
        </v-btn>
        </v-col>
        <attachment-dialog
            :showDialog.sync="attachmentDialog"
            :data="registration_no && typeof registration_no == 'object' ? registration_no.id : null"
            :resource="'vehicles'"
        ></attachment-dialog>
      </v-row>
</template>
<script>
  import axios from "axios";
  import _ from 'lodash';
  import { API_BASE_URL, VIEW_MODE } from "@/config";
  import { validationMixin } from 'vuelidate'
  import { FAMILY_ARTICLE_CARS } from "@/config";
  import AttachmentDialog from "@/components/AttachmentDialog";
  import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha, minValue, maxValue } from 'vuelidate/lib/validators'

  export default {
    components: {
        "attachment-dialog":	AttachmentDialog,
    },
      mixins: [validationMixin],
      validations: {
        // Vehicle Validation
        model:{
          maxLength: maxLength(80)
        },
        registration_no:{
          maxLength(value) {
            if(typeof value == 'string' && value.length > 15){
              return false;
            }
            return true;
          },
          required: requiredIf(function () {
            return this.brand || this.model || this.vin || (this.km && parseFloat(this.km) >= 1) || this.creditor;
          }),
        },
        brand:{
          maxLength: maxLength(25)
        },
        vin:{
          maxLength: maxLength(17)
        },
        km:{
          maxLength: maxLength(10)
        }
      },
      props: {
          comp_vehicle_type: [String, Object],
          comp_registration_no: [String, Object],
          comp_brand: [String, Object],
          comp_model: [String, Object, Number],
          comp_km:    [String, Object, Number],
          comp_reg_date: {type : String, default: new Date().toISOString().substr(0, 10)},
          comp_creditor: [String,Object, Number],
          comp_creditors: [Array],
          comp_vin: [String, Number],
          comp_is_leasing: [Boolean, Number],
          comp_client_detail: [String, Object]
      },
      data(){
          return {
              is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
              autocompleteValueLocal : 'off',
              vehicle_types: [],
              vehicle_type: this.comp_vehicle_type,
              registration_no: this.comp_registration_no,
              client_detail: this.comp_client_detail,
              client_vehicles: this.comp_client_detail ? this.comp_client_detail.vehicles : [],
              brands: [],
              brand: this.comp_brand,
              model: this.comp_model,
              km: this.comp_km,
              reg_date: this.comp_reg_date,
              menu_reg_date: false,
              creditors: this.comp_creditors,
              creditor: this.comp_creditor,
              search_creditor:'',
              isLoadingCreditor: false,
              vin: this.comp_vin,
              is_leasing: this.comp_is_leasing,
              token: this.$store.state.AccessToken,
              nowDate: new Date().toISOString().slice(0,10),
              searchBrand: null,
              attachmentDialog: false,
              tempRegistrationNumber: null,
          }
      },
      mounted(){
        this.autocompleteValueLocal = this.autocompleteValue();
        this.header = { Authorization: 'Bearer ' + this.token };
        this.getBrands();
        this.getVehicleTypes();
      },
      activated(){
        this.setDefaultVehicleType();
      },
      methods:{
        editClientVehicles(){
            if(typeof this.registration_no == 'object'){
                this.$store.commit("seteditId",this.registration_no.id);
                this.$router.push('/vehicles/edit').catch(() => {});
            }
        },
        clearClientVehicles(){
            this.tempRegistrationNumber = null;
            this.registration_no = null;
            this.vehicle_type = null;
            this.brand  = null;
            this.model = null;
            this.creditor = null;
            this.vin = null;
            this.km = null;
            this.is_leasing = null;
            this.setDefaultVehicleType();
        },
        showAttachmentDialog(){
            this.attachmentDialog = true;
        },
        setDefaultVehicleType(){
          if(this.vehicle_types.length > 0){
            this.vehicle_types.forEach((element) => {
              if(element.key == 'sedan'){
                this.vehicle_type = element;
              }
            });
          }
        },
        fetchCreditorSuggListDebounced(){
            if(! this.search_creditor || typeof this.search_creditor == "object"){
                return ;
            }
            axios.get(API_BASE_URL + '/creditor?' + (this.search_creditor ? ('&search=' + this.search_creditor) : '') ,{headers: this.header})
            .then(({data}) => {
                this.creditors = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingCreditor = false;
            })
            .finally(() => (
                this.isLoadingCreditor = false
            ))
        },
        getBrands(){
          if(FAMILY_ARTICLE_CARS){
            if(this.cachedData['brands'][FAMILY_ARTICLE_CARS] && this.cachedData['brands'][FAMILY_ARTICLE_CARS].length > 0){
              this.brands = this.cachedData['brands'][FAMILY_ARTICLE_CARS];
            }
            else{
              this.$store.state.isLoading = true;
              axios.get(API_BASE_URL + '/brands?family='+ FAMILY_ARTICLE_CARS +'&items_per_page=-1',{headers: this.header})
                .then(({data}) => {
                    this.brands = data.data;
                    this.cachedData['brands'][FAMILY_ARTICLE_CARS] = this.brands;
                })
                .catch(function (error) {
                    console.log('an error occured ' + error);
                })
                .finally(() => {
                  this.$store.state.isLoading = false;
                });
            }
          }
        },
        getVehicleTypes(){
            if(this.cachedData['vehicle_types'] && this.cachedData['vehicle_types'].length > 0){
              this.vehicle_types = this.cachedData['vehicle_types'];
            }
            else{
              this.$store.state.isLoading = true;
              axios.get(API_BASE_URL + '/codes?class_key=vehicle_types&items_per_page=-1',{headers: this.header})
                .then(({data}) => {
                    this.vehicle_types = this.translateKeys(data.data, 'key', 'vehicles.types');
                    this.cachedData['vehicle_types'] = this.vehicle_types;
                })
                .catch(function (error) {
                    console.log('an error occured ' + error);
                })
                .finally(() => {
                  this.$store.state.isLoading = false;
                });
            }
        },
      },
      computed: {
          isEditingAllowed(){
            return this.$store.state.isEditingAllowed;
          }
      },
      watch:{
          vehicle_types(){
            this.setDefaultVehicleType();
          },
          vehicle_type(val){
            console.log("client data reset", val);
            if(this.comp_registration_no == null && this.comp_brand == null && this.comp_client_detail == null){
              this.setDefaultVehicleType();
            }
            this.$emit('update:comp_vehicle_type', val);
          },
          comp_vehicle_type:    {handler: function (){ this.vehicle_type = this.comp_vehicle_type;    }},
          comp_registration_no: {handler: function (){ this.registration_no = this.comp_registration_no;    }},
          comp_brand:           {handler: function (){ this.brand = this.comp_brand;    }},
          comp_model:           {handler: function (){ this.model = this.comp_model;  }},
          comp_km:              {handler: function (){ this.km = this.comp_km;  }},
          comp_creditor:        {handler: function (){ this.creditor = this.comp_creditor;      }},
          comp_creditors:       {handler: function (){ this.creditors = this.comp_creditors;      }},
          comp_vin:             {handler: function (){ this.vin = this.comp_vin;      }},
          comp_is_leasing:      {handler: function (){ this.is_leasing = this.comp_is_leasing;  }},
          comp_reg_date:        {handler: function (){ this.reg_date = this.comp_reg_date;  }},

          comp_client_detail:   {
            handler: function (){ 
              this.client_detail = this.comp_client_detail; 
              this.client_vehicles = this.comp_client_detail ? this.comp_client_detail.vehicles : [];
              this.vehicle_type = null;
              this.registration_no = null;
              this.brand = null;
              this.model = null;
              this.reg_date = null;
              this.creditor = null;
              this.vin = null;
              this.km = null;
              this.is_leasing = false;
            }
          },
          
          search_creditor (val) {
            if (!val || this.creditor == val) {
                return
            }
            this.isLoadingCreditor = true;

            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchCreditorSuggListDebounced()
            }, 500);
          },
          client_vehicles (val) {
            console.log("setting up client vehicles");
            this.registration_no = null;
            this.vehicle_type = null;
            this.brand  = null;
            this.model = null;
            this.creditor = null;
            this.vin = null;
            this.km = null;
            this.is_leasing = null;
            this.setDefaultVehicleType();
            if(val && val.length > 0){
              this.registration_no = val[0];
            }
          },
          registration_no (val){
            if(val){
              console.log("Registration Number",val.vehicle_type);
              if(typeof val == 'object'){ 
                this.tempRegistrationNumber = val;
                // this means we have data for that registration number
                // this.vehicle_type = val.vehicle_type ? (typeof val.vehicle_type == "object" ? val.vehicle_type.id : val.vehicle_type) : null;
                if(val.vehicle_type){
                  this.vehicle_types.forEach(element => {
                    if(element.id == val.vehicle_type.id){
                      this.vehicle_type = element;
                    }
                  });
                }
                else{
                  this.vehicle_type = null;
                }
                this.brand  = val.brand;
                this.model = val.model;
                this.creditor = null;
                this.vin = val.vin;
                this.km = val.km;
                this.is_leasing = val.is_leasing; 
                this.creditor = val.creditor;
                this.reg_date = val.registration_date;
              }
              else{
                // no operation need to perform
                console.log("updatingbefore", val);
                if(val == null || val == ""){
                    this.tempRegistrationNumber = null;
                }
                else{
                    if(this.tempRegistrationNumber){
                        this.tempRegistrationNumber.registration_number = val;
                        this.$emit('update:comp_registration_no', this.tempRegistrationNumber);
                        console.log("updating registration number", this.tempRegistrationNumber);
                        this.$nextTick(() => {
                            this.registration_no = this.tempRegistrationNumber;
                            console.log("updatinged registration number", this.registration_no);
                        })
                    }
                }
              }
              
            }
            else{
                this.tempRegistrationNumber = null;
            }
            this.$emit('update:comp_registration_no', val);
          },
          brand (val){
              this.$emit('update:comp_brand', val);
          },
          model (val){
            this.$emit('update:comp_model', val);
          },
          km (val){
            this.$emit('update:comp_km', val);
          },
          creditor (val){
            if(val){
              this.creditors.find((creditor) => {
                  if(creditor.id == val){
                    this.is_leasing = !!creditor.is_leaser;
                  }
              })
              
            }
            this.$emit('update:comp_creditor', val);
          },
          vin (val){
            this.$emit('update:comp_vin', val);
          },
          is_leasing (val){
            this.$emit('update:comp_is_leasing', val);
          },
          reg_date (val){
            this.$emit('update:comp_reg_date', val);
          }
        }
      
  };
</script>