<template>
  <div v-if="currentVehicle" class="mb-5">
    <v-row>
      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          disabled
          :value="registrationNumber"
          outlined
          dense
          flat
          filled
          :label="$t('rentals.reg_no')"
          style="margin-top: 10px"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="brandName"
          :label="$t('rentals.brand_name')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          disabled
          :value="vehicleModel"
          :label="$t('rentals.model_name')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          disabled
          :value="cylinderCapacity"
          :label="$t('rentals.cylinder_capacity')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="fuelType"
          :label="$t('rentals.fuel_type')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="gearBoxType"
          :label="$t('rentals.gear_box_type')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="currentVehicle.vin"
          label="VIN"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          type="number"
          v-model.number="mileage"
          :label="$t('rentals.mileage')"
          @change="updateVehicle"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import { API_BASE_URL } from "@/config";
import axios from "axios";
export default {
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    brandName() {
      return this.currentVehicle?.brand?.name || "";
    },
    fuelType() {
      return this.currentVehicle?.fuel_type?.name || "";
    },
    gearBoxType() {
      return this.currentVehicle?.gear_box_type?.name || "";
    },
    registrationNumber() {
      return this.currentVehicle?.registration_number || "";
    },
    vehicleModel() {
      return this.currentVehicle?.vehicle_model?.name || "";
    },
    cylinderCapacity() {
      return this.currentVehicle?.cylinder_capacity || "";
    },
  },
  data() {
    return {
      search: null,
      currentVehicle: null,
      mileage: null,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
    };
  },
  mounted() {
    this.currentVehicle = this.vehicle;
    this.mileage = this.currentVehicle.km;
  },
  watch: {
    vehicle: function(newVehicle) {
      this.currentVehicle = newVehicle;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    updateVehicle() {
      // mileage must be a number greater than 0
      let mileage = parseInt(this.mileage);
      if (mileage < 0) {
        this.$noty("rentals.mileage_error", {
          timeOut: 5000,
          layout: "bottomLeft",
        });

        return (this.mileage = this.currentVehicle.km);
      }

      this.currentVehicle.km = mileage;
      this.$store.state.isLoading = true;
      axios
        .put(
          API_BASE_URL + "/client_vehicles/" + this.currentVehicle.id,
          this.currentVehicle,
          {
            headers: this.header,
          }
        )
        .finally(() => {
          this.$store.state.isLoading = false;
        })
        .then((response) => {
          this.$noty.success(this.$t("rentals.mileage_update_success"), {
            timeout: 5000,
            layout: "bottomLeft",
          });
        })
        .catch((error) => {
          this.$noty.error(
            error?.response?.data?.message || this.$t("unknown_error"),
            {
              timeout: 5000,
              layout: "bottomLeft",
            }
          );
        });
    },
  },
};
</script>
<style scoped>
.remove-space-around {
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
}
</style>
