<template>
    <v-dialog
        v-model="modify_stock_dialog"
        @click:outside="closeStocks()"
        @keydown.esc="closeStocks()"
        persistent
        max-width="1024px"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{
                    $t("stocks.modify_stock.title")
                }}</span>
            </v-card-title>
            <v-card-text>
                <v-container class="pt-0">
                    <v-row class="mb-n8">
                        <v-col>
                            <p class="orderTitle">{{ rowData.description }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col>
                            <p class="orderSubTitle">
                                {{ rowData.identifier }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="pt-4">
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                flat
                                v-model="form.stock"
                                v-on:keydown="handleInput($event, 'int', 8)"
                                class="mr-2 to-upper right-aligned-input"
                                :label="this.$t('stocks.int_stock')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="24" sm="0" class="pt-9 pl-0 pr-0">
                            <h2>
                                /
                            </h2>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                flat
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.reserve"
                                v-on:keydown="handleInput($event, 'int', 8)"
                                class="mr-2 to-upper text-red right-aligned-input"
                                :label="this.$t('stocks.reserve')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field-percent
                                class="mr-2 to-upper right-aligned-input"
                                v-model="form.price"
                                :label="this.$t('stocks.price')"
                                :properties="{
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    clearable: false,
                                    placeholder: '',
                                }"
                                :options="{
                                    locale: userLanguageId,
                                    length: 6,
                                    precision: getDecimalNumber(),
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="2"
                            v-if="
                                selectedFamily.parent_family_id == 1 ||
                                    selectedFamily.id == 1
                            "
                        >
                            <v-btn
                                :disabled="loadingDots"
                                :color="
                                    sumOfDots() != form.stock
                                        ? 'warning'
                                        : 'primary'
                                "
                                class="mt-3"
                                @click="showModifyDotDialog(rowData)"
                            >
                                {{ $t("dot") }}
                                <template v-if="!loadingDots">
                                    <v-icon
                                        dark
                                        right
                                        v-if="sumOfDots() == form.stock"
                                    >
                                        mdi-circle-opacity</v-icon
                                    >
                                    <v-icon
                                        dark
                                        right
                                        v-if="sumOfDots() != form.stock"
                                    >
                                        mdi-alert-circle-outline</v-icon
                                    >
                                </template>

                                <v-progress-circular
                                    v-if="loadingDots"
                                    size="20"
                                    :width="3"
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-switch
                                class="mt-4 ml-1"
                                color="success"
                                v-model="form.is_verified"
                                :label="$t('stocks.verified')"
                                inset
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-2" @click="closeStocks()">
                    {{ $t("stocks.cancel") }}
                    <v-icon dark right> mdi-keyboard-return</v-icon>
                </v-btn>
                <v-btn color="success" @click="saveStocks()">
                    {{ $t("stocks.save") }}
                    <v-icon dark right> mdi-content-save</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <stock-article-dot
            v-if="modifyDots"
            :article="rowData"
            :passed_dot_items="dot_items"
            :total_stocks="form.stock"
            @change-modify-article-dialog="changeModifyArticleDialog()"
        ></stock-article-dot>
    </v-dialog>
</template>
<style scoped>
.right-aligned-input >>> input {
    text-align: right;
}

.text-red >>> input {
    color: red !important;
}
</style>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import StockArticleDot from "@/components/Stock/StockArticleDot";

export default {
    props: {
        rowData: {
            type: Object,
        },
        header: {
            type: String,
        },
        selectedFamily: {
            type: Object,
        },
    },
    components: {
        "stock-article-dot": StockArticleDot,
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            modify_stock_dialog: true,
            form: {
                stock: this.rowData.stock ?? 0,
                reserve: this.rowData.reserve ?? 0,
                price: this.rowData.price
                    ? this.fixDecimal(this.rowData.price)
                    : 0.0,
                is_verified: this.rowData.is_verified,
            },
            old_stock: this.rowData.stock ?? 0,
            old_reserve: this.rowData.reserve ?? 0,
            article_id: this.rowData.article_id,
            autocompleteValueLocal: "off",
            modifyDots: false,
            dot_items: [],
            loadingDots: false,
        };
    },
    mounted() {
        this.getArticleDots();
    },
    methods: {
        getArticleDots() {
            this.dot_items = [];
            console.log("console");
            this.loadingDots = true;
            axios
                .get(
                    API_BASE_URL +
                        "/stock_property/" +
                        this.article_id +
                        "?property_class_id=dot_property",
                    {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data.data, "dot items");
                    this.dot_items = response.data.data;
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.loadingDots = false;
                });
        },
        showModifyDotDialog(id) {
            this.modifyDots = true;
        },
        sumOfDots() {
            if (this.dot_items.length > 0) {
                let totalCounter = 0;
                this.dot_items.forEach((element) => {
                    totalCounter =
                        parseInt(totalCounter) + parseInt(element.counter);
                });
                console.log("total counter", totalCounter);
                return totalCounter;
            }
            return 0;
        },
        changeModifyArticleDialog() {
            this.modifyDots = !this.modifyDots;
            this.getArticleDots();
        },
        closeStocks() {
            this.modify_stock_dialog = false;
            this.$emit("modifyClose", false);
        },
        saveStocks() {
            let data = this.form;
            axios
                .put(API_BASE_URL + "/stocks/" + this.article_id, data, {
                    headers: this.header,
                })
                .then((response) => {
                    if (response.status === 200) {
                        // this.$router.push("/family-clients").catch(() => {});
                    } else {
                        alert("ERROR on saving. Please try again later");
                    }
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.modify_stock_dialog = false;
                    this.$emit("modifyClose", true);
                });
        },
    },
};
</script>
