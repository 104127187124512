<template>
  <v-card class="elevation-0">
    <v-tabs
        v-model="tab"
        height="50px"
        background-color="primary"
        dark
        v-if="checkTag('client_address') || checkTag('billing') || checkTag('vehicles') || checkTag('scheduler')"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#client-addresses" v-if="checkTag('client_address')">
        <v-icon>mdi-map-search-outline</v-icon>
        {{$t('clients.tabs.addresses')}}
      </v-tab>

      <v-tab href="#client-bills" v-if="checkTag('billing')">
        <v-icon>mdi-currency-eur</v-icon>
        {{$t('clients.tabs.bills')}}
      </v-tab>

      <v-tab href="#client-cars" v-if="checkTag('vehicles')">
        <v-icon>mdi-car-back</v-icon>
        {{$t('clients.tabs.vehicles')}}
      </v-tab>

      <v-tab href="#client-schedules" v-if="checkTag('scheduler')">
        <v-icon>mdi-calendar</v-icon>
        {{$t('clients.tabs.schedules')}}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="'client-addresses'" :value="'client-addresses'">
          <addresses-tab 
            :client="client"
          ></addresses-tab>
      </v-tab-item>

      <v-tab-item :key="'client-bills'" :value="'client-bills'">
          <bills-tab 
            class="pa-4"
            :client="client"
          ></bills-tab>
      </v-tab-item>

      <v-tab-item :key="'client-cars'" :value="'client-cars'" >
          <vehicle-tab
            :client="client"
          ></vehicle-tab>
      </v-tab-item>

      <v-tab-item :key="'client-schedules'" :value="'client-schedules'">
          <schedule-tab
            :client="client"
          ></schedule-tab>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AddressesTab from '@/components/Client/AddressesTab';
import BillsTab from '@/components/Client/BillsTab';
import VehicleTab from '@/components/Client/VehicleTab';
import ScheduleTab from '@/components/Client/ScheduleTab';

export default {
  name:       "ClientTabs",
  components: {
    'addresses-tab': AddressesTab,
    'bills-tab':     BillsTab,
    'vehicle-tab':     VehicleTab,
    'schedule-tab':     ScheduleTab,
  },
  props:{
    client: [Object]
  },
  data() {
    return {
      tab:  null,
      text: "",
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
