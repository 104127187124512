<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="mt-2" cols="12" sm="3">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 pl-5 to-upper"
            :label="$t('vehicles.search')"
            autofocus
            @input="debounceInput"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>

        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn
            class="ma-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
            @click="showRentalForm('add')"
          >
            {{ $t("rentals.new_rent") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          fixed-header
          :headers="headers"
          :options="options"
          :server-items-length="totalItems"
          :loading="isLoading"
          :loading-text="this.$t('loading')"
          class="elevation-2 my-space"
          :height="'calc(-200px + 100vh)'"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          :items="vehicles"
        >
          <template v-slot:item="{ item, index }">
            <tr
              @click="editHistory(item)"
              :style="determineRowBg(item)"
              class="mb-2"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.driver.full_name }}</td>
              <td>{{ item.client.full_name }}</td>
              <td>{{ filterDateFormat(item.start_date) }}</td>
              <td>{{ filterDateFormat(item.end_date) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import moment from "moment-timezone";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { ADD_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "RentalHistoryListing",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      alerts: [],
      search: "",
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      options: {},
      sortBy: "start_date",
      sortDesc: false,
      sortOrd: "DESC",
      sort: {},
      showAlert: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      isDebounceCancelled: false,
      vehicleId: this.$store.state.vehicleId,
      today: new Date(),
      colorCodes: this.$store.state.colorCodes,
    };
  },
  mounted() {
    this.$store.commit("setFormMode", VIEW_MODE);
    (this.header = { Authorization: "Bearer " + this.token }), this.fetchData();
    this.autocompleteValueLocal = this.autocompleteValue();
    this.headers.push({});
    this.headers.splice(-1);
  },
  methods: {
    showRentalForm(action) {
      if (action == "add") {
        this.$store.commit("setFormMode", ADD_MODE);
        return this.$router.push("/rentals/add");
      } else {
        this.$store.commit("setFormMode", EDIT_MODE);
        return this.$router.push("/rentals/edit");
      }
    },
    determineState(item) {
      let startDate = moment(item.start_date);
      let endDate = moment(item.end_date);
      let today = moment();
      let startDateIsToday = startDate.isSame(today, "day");
      let endDayIsToday = endDate.isSame(today, "day");

      if (startDate.isAfter(this.today)) {
        return "planned";
      } else if (
        (startDate.isBefore(this.today) || startDateIsToday) &&
        (endDate.isAfter(this.today) || endDayIsToday)
      ) {
        return "busy";
      } else {
        return "available";
      }
    },
    determineRowBg(item) {
      let itemState = this.determineState(item);

      switch (itemState) {
        case "planned":
          return "background-color: " + this.colorCodes.warning;
        case "busy":
          return "background-color: " + this.colorCodes.danger;
        case "available":
          return "background-color: " + this.colorCodes.success;
        default:
          return "";
      }
    },
    filterDateFormat(date) {
      return moment(date).format("L");
    },
    debounceInput: _.debounce(function(e) {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.fetchData();
    }, 500),
    getLocalisationFromDepot(item) {
      let localisation = "";
      let activeDepot = 0;
      if (item.depot) {
        item.depot.forEach((element) => {
          if (element.status_id == 10171001 && element.deleted_at == null) {
            // depot stored
            activeDepot++;
            localisation += element.localisation
              ? " " + element.localisation + ","
              : "";
          }
        });
        if (activeDepot > 0 && !localisation.trim()) {
          return this.$t("yes");
        } else if (activeDepot > 0 && localisation.trim()) {
          return localisation.slice(0, -1);
        } else {
          return "";
        }
      }
    },
    fetchData() {
      this.isLoading = true;
      const payload = {
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortOrd,
        search: this.search,
      };
      axios({
        method: "get",
        url:
          API_BASE_URL + "/vehicle/" + this.$store.state.vehicleId + "/rentals",
        headers: this.header,
        params: payload,
      })
        .then((data) => {
          this.$store.state.vehicles = data.data.data;
          this.$store.state.vehicles.forEach((vehicle) => {
            vehicle.is_leasing = vehicle.is_leasing == 1 ? true : false;

            vehicle.is_active =
              moment(this.filterDateFormat(vehicle.start_date)).isBefore(
                this.today
              ) &&
              moment(this.filterDateFormat(this.today)).isBefore(
                this.filterDateFormat(vehicle.end_date)
              ); // true
          });
          this.totalItems = data.data.total;
          this.isLoading = false;
        })
        .catch((error) => {
          this.$noty.success(
            this.$t("schedulestab.something_went_wrong_while_fetching_data"),
            { timeout: 5000, layout: "bottomLeft" }
          );
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      itemsPerPage == -1
        ? (this.itemsPerPage = this.totalItems)
        : (this.itemsPerPage = itemsPerPage);
      this.fetchData();
    },
    editHistory(item) {
      this.$store.commit("setRentalId", item.id);
      this.$router.push("/rentals/edit");
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
    },
  },
  computed: {
    headers() {
      return [
        { text: "S/N", value: "", sortable: "", width: "3px" },
        {
          text: "Driver",
          value: "driver.full_name",
          sortable: true,
        },
        { text: "Agency", value: "driver.company_id", sortable: "" },
        { text: "Start Date", value: "start_date", sortable: "" },
        { text: "End Date", value: "end_date", sortable: "" },
      ];
    },
    vehicles() {
      return this.$store.state.vehicles;
    },
  },
  watch: {
    vehicle() {
      this.fetchDepot();
    },

    sortOrd: {
      handler: function() {
        this.fetchData();
      },
    },
    sortBy: {
      handler: function() {
        this.fetchData();
      },
    },
  },
};
</script>
<style scoped>
.red-background {
  background-color: #ffcccb;
}
.my-space table td {
  padding: 0 26px !important;
}

thead {
  border: 1px solid #ddd;
}
</style>
