<template>
    <v-row>
        <v-col>
            <!-- <v-row>
                <v-spacer></v-spacer>
                <v-btn
                    id="top-create"
                    class="ma-8"
                    color="primary"
                    @click="addEditAddressModel = true"
                >
                    {{ $t("nav_buttons.add") }}
                    <v-icon dark right> mdi-plus </v-icon>
                </v-btn>
                <v-col class="pa-0 pt-4 ma-0" cols="12" sm="3">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        class="mr-3 to-upper"
                        :label="$t('addresses.search')"
                        @input="debounceInput"
                        prepend-icon="mdi-magnify"
                        v-bind:clearable="true"
                        clear-icon="mdi-close"
                        v-model="search"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                </v-col>
            </v-row> -->
            <v-row class="mt-4">
                <v-data-table
                    fixed-header
                    :footer-props="{
                        'items-per-page-options': dataTableOptions,
                    }"
                    :headers="depositHeader"
                    :hide-default-footer="true"
                    :items="depot"
                    :items-per-page="itemsPerPage"
                    :options.sync="options"
                    :sort-by="sortBy"
                    :loading-text="this.$t('loading')"
                    class="elevation-0"
                    item-key="id"
                    height="29vh"
                    style="width: 100%"
                    hide-no-data
                >
                    <template v-slot:item="{ item }">
                        <tr
                            :class="
                                item == selectedDepot ? 'bg-grey' : 'bg-white'
                            "
                            @click="selectDepot(item)"
                            v-if="item.deleted_at ? isHistory : true"
                        >
                            <td></td>
                            <td
                                :id="item.id"
                                :data="
                                    selectedDepot.id == item.id
                                        ? highlightRow(item.id)
                                        : ''
                                "
                            >
                                <span v-if="item.requested_out_for">
                                    <v-icon color="black">
                                        mdi-backburger
                                    </v-icon>
                                </span>
                                <span v-else-if="item.localisation">
                                    <v-icon color="green"> mdi-tag </v-icon>
                                </span>
                                <span v-else>
                                    <v-icon color="green"> mdi-home </v-icon>
                                </span>
                            </td>
                            <td>
                                <template v-if="item.localisation && item.localisation.length > 20">
                                    {{ item.localisation.substring(0, 20) + " .." }}
                                </template>
                                <template v-else>
                                    {{ item.localisation }}
                                </template>
                            </td>
                            <td>
                                {{ item.created_at }}
                            </td>
                            <td>
                                {{ item.requested_out_for }}
                            </td>
                            <td align="right">
                                <v-icon
                                    v-if="checkIfSomeErrorInDepot(item.details)"
                                    color="error"
                                >
                                    mdi-fridge-alert
                                </v-icon>
                            </td>
                            <!-- <td>
                                {{ item.requested_out_for }}
                            </td> -->
                        </tr>
                    </template>
                </v-data-table>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import axios from "axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import TyreHotelSearchArticle from "@/components/TyreHotel/SearchArticle";
import { validationMixin } from "vuelidate";
import {
    required,
    email,
    requiredIf,
    maxValue,
    phoneNum,
    numeric,
    minLength,
    maxLength,
    alpha,
} from "vuelidate/lib/validators";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
    name: "DepotOut",
    mixins: [validationMixin],
    validations: {},
    props: {
        vehicle: [Object],
        depotDetails: [Object],
        vehicleDriverDetails: [Object],
        alreadySelectedDepot: {
            default: Array,
            type: [Array]
        },
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            modify_stock_dialog: true,
            autocompleteValueLocal: "off",
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            modify_data: false,
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            isHistory: false,
            selected_vehicle: {},
            depot: [],
            page: 1,
            lastPage: null,
            totalItems: null,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            sortBy: "description",
            sortDesc: false,
            options: {},
            pagination: {},
            selectedDepot: null,
            selectedDepotForSubTable: [],
            return_request_menu: false,
            return_request_date: null,
            nowDate: new Date().toISOString().slice(0, 10),
            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            lastDepotSelected: null,
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.header = { Authorization: "Bearer " + this.token };
    },
    watch: {
        vehicle(val){
            if(val && val.id){
                this.fetchDepot(val.id);
            }
        }
    },
    methods: {
        highlightRow(itemId) {
            this.$nextTick(() => {
                if (
                    document.getElementsByClassName("tyre-depot-matched-row")
                        .length > 0
                ) {
                    document
                        .getElementsByClassName("tyre-depot-matched-row")[0]
                        .classList.remove("tyre-depot-matched-row");
                }
                document.getElementById(
                    itemId
                ).parentElement.parentElement.className =
                    "tyre-depot-matched-row";
            });
        },
        selectDepot(row) {
            this.selectedDepot = row;

            row.details.forEach((detail) => {
                detail.checked = true;
            });
            this.selectedDepotForSubTable = row.details;
        },
        checkIfSomeErrorInDepot(depotDetails) {
            let isError = false;
            depotDetails.forEach((rowdepot) => {
                if (
                    rowdepot.article_status &&
                    rowdepot.article_status.key == "damaged"
                ) {
                    isError = true;
                }
            });
            return isError;
        },
        fetchDepot(vehicleID) {
            this.depot = [];
            this.selectedDepot = null;
            this.selectedDepotForSubTable = [];
            this.$store.state.isLoading = true;
            axios
                .get(
                    API_BASE_URL +
                        "/depots?soft_deleted_also=true&" +
                        "vehicle_id=" +
                        vehicleID,
                    { headers: this.header }
                )
                .then(({ data }) => {
                    // Assign Checked property to all the depot items
                    let tempData = [];
                    let alreadySelectedDepotIds = [];
                    this.alreadySelectedDepot.forEach(
                        (alreadyItems, itemindex) => {
                            if (alreadyItems.depot_id) {
                                alreadySelectedDepotIds.push(
                                    alreadyItems.depot_id
                                );
                            }
                            if (alreadyItems.depot_in_id) {
                                alreadySelectedDepotIds.push(
                                    alreadyItems.depot_in_id
                                );
                            }
                        }
                    );
                    data.data.forEach((depot, depotindex) => {
                        if (this.alreadySelectedDepot.length > 0) {
                            if (!alreadySelectedDepotIds.includes(depot.id)) {
                                tempData.push(depot);
                            }
                        } else {
                            tempData = Object.assign([], data.data);
                        }
                    });

                    this.depot = tempData;

                    tempData.forEach((depot) => {
                        depot.checked = false;
                        if (!this.selectedDepot && !depot.deleted_at) {
                            this.selectedDepot = depot;
                        }
                    });

                    // Assign the first depot
                    if (tempData.length > 0 && this.selectedDepot) {
                        // Check all the sub positions by default
                        this.selectedDepot.details.forEach((detail) => {
                            detail.checked = true;
                        });
                        this.selectedDepotForSubTable = this.selectedDepot.details;
                    }
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        closeTyreDialog() {
            this.modify_stock_dialog = false;
            this.$emit("modifyClose", false, false);
        },
        saveTyreDialog() {
            this.modify_stock_dialog = false;
            this.$emit("modifyClose", false, this.selectedDepot);
        },
    },
    computed: {
        depositHeader() {
            return [
                {
                    text: " ",
                    align: "start",
                    sortable: false,
                    value: "checkbox",
                    width: "10",
                },
                {
                    text: this.$t("tyre_hotel.deposit.status"),
                    align: "start",
                    sortable: false,
                    value: "status",
                    width: "15%",
                },
                {
                    text: this.$t("tyre_hotel.deposit.localized"),
                    align: "start",
                    sortable: false,
                    value: "localisation",
                    width: "15%",
                },
                {
                    text: this.$t("tyre_hotel.deposit.entry_date"),
                    align: "start",
                    sortable: false,
                    value: "created_at",
                    width: "20%",
                },
                {
                    text: this.$t("tyre_hotel.deposit.return_request_date"),
                    align: "start",
                    sortable: false,
                    value: "requested_out_for",
                    width: "40%",
                },
                {
                    text: "",
                    align: "end",
                    sortable: false,
                    value: "someerrors",
                    width: "10%",
                },
            ];
        },
        depositDeatilsHeader() {
            return [
                {
                    text: " ",
                    align: "start",
                    sortable: false,
                    value: "checkbox",
                    width: "10",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.position"),
                    align: "start",
                    sortable: false,
                    value: "position",
                    width: "50",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.profile"),
                    align: "start",
                    sortable: false,
                    value: "article",
                    width: "350",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.width"),
                    align: "start",
                    sortable: false,
                    value: "article_width",
                    width: "5",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.height"),
                    align: "start",
                    sortable: false,
                    value: "article_height",
                    width: "5",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.rim"),
                    align: "start",
                    sortable: false,
                    value: "article_rim",
                    width: "5",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.li"),
                    align: "start",
                    sortable: false,
                    value: "article_li",
                    width: "5",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.si"),
                    align: "start",
                    sortable: false,
                    value: "article_si",
                    width: "5",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.mm"),
                    align: "start",
                    sortable: false,
                    value: "mm",
                    width: "5",
                },
                {
                    text: this.$t(
                        "tyre_hotel.deposit_details.tightening_torque"
                    ),
                    align: "start",
                    sortable: false,
                    value: "couple",
                    width: "150",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.pressure"),
                    align: "start",
                    sortable: false,
                    value: "pressure",
                    width: "5",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.rim_type"),
                    align: "start",
                    sortable: false,
                    value: "rim_type",
                    width: "100",
                },
                {
                    text: this.$t("tyre_hotel.deposit_details.with_enjo"),
                    align: "start",
                    sortable: false,
                    value: "with_enjo",
                    width: "5",
                },
                // { text: this.$t('tyre_hotel.deposit_details.alu'),              align: 'start',     sortable: false, value:'alu', width:'5' },
            ];
        },
    },
};
</script>
<style scoped></style>
