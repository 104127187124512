<template>
  <div>
    <VehicleReadOnlyInfo v-if="vehicle" :vehicle="vehicle" />

    <v-card class="border-1 outlined m-1 mt-0 p-1 rental-contract-from">
      <v-container fluid class="mb-0 removed-container" style="">
        <!-- New Design Ends Here -->

        <v-row class="border-b align-center">
          <v-col cols="12" sm="12" md="4">
            <h4 class="text-blue-color d-flex align-center">
              <v-icon class="text-blue-color mr-2" dark right>
                mdi-card-account-details </v-icon
              >{{ formMode === "add" ? "Create New" : "Edit" }} Rental Contract
            </h4>
          </v-col>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              id="top-print"
              class="ma-2 text-white-color"
              color="primary"
              disabled
              v-if="formMode === 'edit'"
            >
              {{ $t("rentals.print_contract") }}
              <v-icon dark right> mdi-printer </v-icon>
            </v-btn>
            <v-btn
              v-if="formMode === 'edit'"
              @click="attachmentDialog = true"
              id="top-attachments"
              class="ma-2 text-white-color"
              color="success"
              disabled
            >
              {{ $t("attachments") }} ({{ $store.state.attachmentCounts }})
              <v-icon dark right> mdi-paperclip </v-icon>
            </v-btn>

            <v-btn
              id="top-cancel"
              class="ma-2 text-black-color"
              color="grey lighten-2"
              @click="cancelRental"
            >
              {{ $t("nav_buttons.cancel") }}
              <v-icon dark right> mdi-keyboard-return </v-icon>
            </v-btn>
            <v-btn
              id="top-save"
              class="ma-2 text-white-color"
              color="success"
              @click="saveData"
            >
              {{ $t("nav_buttons.save") }}
              <v-icon dark right> mdi-content-save </v-icon>
            </v-btn>
          </div>
        </v-row>

        <!-- Heading Ends Here -->
        <v-divider class="mt-5"></v-divider>
      </v-container>
      <div>
        <div class="mt-0 removed-container" style="margin:10px!important">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <h4 class="text-blue-color">
                {{ $t("rentals.contract_details") }}
              </h4>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-combobox
                autocomplete="off"
                ref="clientName"
                v-model="form.client"
                :items="company_client_names"
                item-value="id"
                item-text="name"
                :label="$t('vehicles.client')"
                class="to-upper"
                required
                append-icon="mdi-close"
                v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                :search-input.sync="search_client"
                no-filter
                v-on:keydown.enter.prevent=""
                @click:append="
                  form.client = null;
                  company_client_names = [];
                "
                :error-messages="
                  $store.state.validation && !$v.form.client.required
                    ? [this.$t('vehicles.text_messages.client_required')]
                    : []
                "
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.name }} {{ item.first_name ? item.first_name : "" }}
                </template>
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <p class="client_name">
                      {{ item.name }} {{ item.first_name }}
                    </p>
                    <p
                      class="client_detail"
                      v-if="item.representative_company_id"
                    >
                      {{ $t("entity_type.company") }}
                    </p>
                    <p class="client_detail" v-else>
                      {{ $t("entity_type.private") }}
                    </p>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-menu
                v-model="menuDtpStart"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start_date"
                    :label="$t('pricing_rule.start_date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="form.start_date = null"
                    append-icon="mdi-close"
                    class="mr-2 to-upper"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  v-model="form.start_date"
                  :min="today"
                  @input="menuDtpStart = false"
                  no-title
                  scrollable
                  :locale="userLanguageId"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-menu
                v-model="menuDtpEnd"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.end_date"
                    :label="$t('pricing_rule.end_date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="form.end_date = null"
                    append-icon="mdi-close"
                    class="mr-2 to-upper"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  :min="form.start_date"
                  v-model="form.end_date"
                  @input="menuDtpEnd = false"
                  no-title
                  scrollable
                  :locale="userLanguageId"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-text-field
                autocomplete="off"
                v-model="form.contract_reference"
                class="to-upper"
                name="client_contract_ref"
                v-on:keydown="handleInput($event, 'alphanumeric', 100)"
                :label="$t('vehicles.rental.client_contract_reference')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                autocomplete="off"
                v-model="form.client_reference"
                class="to-upper"
                name="internal_contract_ref"
                v-on:keydown="handleInput($event, 'alphanumeric', 100)"
                :label="$t('vehicles.rental.internal_reference')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <div class="driver-from-item v-select-pickup-address">
                <v-combobox
                  autocomplete="off"
                  ref="pickup_address"
                  v-model="form.pickup_location"
                  :items="pickup_addresses"
                  label="Pickup Location"
                  required
                  class="to-upper"
                  append-icon="mdi-close"
                  v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                  :search-input.sync="search_pickup_location"
                  no-filter
                  v-on:keydown.enter.prevent=""
                  @click:append="
                    form.pickup_location = null;
                    pickup_addresses = [];
                  "
                  return-object
                  :outlined="is_boxes"
                  :dense="is_boxes"
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.address_line1 }} {{ item.address_line2 }}
                    {{ item.street ? item.street : "" }}
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                      <p class="client_name">
                        {{ item.address_line1 ? item.address_line1 : "" }}
                        {{ item.address_line2 ? item.address_line2 : "" }}
                      </p>
                      <p class="client_detail">
                        {{ item.city ? item.city : "" }}
                        {{ item.state ? item.state : "" }}
                        {{ item.country ? item.country : "" }}
                      </p>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-checkbox
                class="mt-6"
                v-model="delivery_add_same_as_pickup"
                :label="$t('vehicles.rental.same_as_pickup')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-combobox
                autocomplete="off"
                ref="delivery_address"
                v-model="form.delivery_location"
                :items="delivery_addresses"
                label="delivery Location"
                required
                class="to-upper"
                append-icon="mdi-close"
                :disabled="delivery_address_readonly"
                v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                @click:append="
                  delivery_address_readonly
                    ? ''
                    : (form.delivery_location = null);
                  delivery_addresses = [];
                "
                :search-input.sync="search_delivery_location"
                no-filter
                v-on:keydown.enter.prevent=""
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.address_line1 }} {{ item.address_line2 }}
                  {{ item.street ? item.street : "" }}
                </template>

                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <p class="client_name">
                      {{ item.address_line1 ? item.address_line1 : "" }}
                      {{ item.address_line2 ? item.address_line2 : "" }}
                    </p>
                    <p class="client_detail">
                      {{ item.city ? item.city : "" }}
                      {{ item.state ? item.state : "" }}
                      {{ item.country ? item.country : "" }}
                    </p>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <h4 class="text-blue-color">Driver</h4>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-combobox
                autocomplete="off"
                ref="driver_name"
                v-model="driver"
                :items="driver_names"
                item-value="id"
                item-text="name"
                :label="$t('clients.last_name')"
                required
                class="to-upper"
                append-icon="mdi-close"
                :search-input.sync="search_driver"
                no-filter
                @click:append="driver = null"
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ renderDriverLastName(item) }}
                </template>
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <p class="client_name">
                      {{ item.name }} {{ item.first_name }}
                    </p>
                    <p
                      class="client_detail"
                      v-if="item.representative_company_id"
                    >
                      {{ $t("entity_type.company") }}
                    </p>
                    <p class="client_detail" v-else>
                      {{ $t("entity_type.private") }}
                    </p>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                autocomplete="off"
                role="presentation"
                v-model="form.driver.first_name"
                :disabled="driverFormReadOnly"
                class="to-upper"
                name="driver_first_name"
                v-on:keydown="handleInput($event, 'alphanumeric', 100)"
                :label="$t('clients.first_name')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-text-field
                autocomplete="off"
                v-model="form.driver.license"
                class="to-upper"
                name="driver_license"
                :disabled="driverFormReadOnly"
                :label="$t('vehicles.clients_model.driving_licence')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                autocomplete="off"
                role="presentation"
                v-model="form.driver.gsm"
                class="to-upper"
                name="driver_gsm"
                :disabled="driverFormReadOnly"
                v-on:keydown="handleInput($event, 'alphanumeric', 100)"
                :label="$t('vehicles.clients_model.gsm')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-text-field
                autocomplete="off"
                role="presentation"
                v-model="form.driver.phone"
                class="to-upper"
                name="driver_phone"
                :disabled="driverFormReadOnly"
                v-on:keydown="handleInput($event, 'alphanumeric', 100)"
                :label="$t('vehicles.clients_model.phone')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-text-field
                autocomplete="off"
                role="presentation"
                v-model="form.driver.email"
                class="to-upper"
                name="driver_email"
                :disabled="driverFormReadOnly"
                v-on:keydown="handleInput($event, 'email', 60)"
                :label="$t('vehicles.clients_model.email')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-combobox
                autocomplete="off"
                role="presentation"
                ref="driver_address"
                v-model="form.driver.address"
                :items="driver_addresses"
                :disabled="driverFormReadOnly"
                :label="$t('tyre_hotel.vehicle.address')"
                required
                class="to-upper"
                append-icon="mdi-close"
                v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                :search-input.sync="search_driver_location"
                no-filter
                v-on:keydown.enter.prevent=""
                @click:append="
                  form.driver.address = null;
                  driver_addresses = [];
                "
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.address_line1 }} {{ item.address_line2 }}
                  {{ item.street ? item.street : "" }}
                </template>
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <p class="client_name">
                      {{ item.address_line1 ? item.address_line1 : "" }}
                      {{ item.address_line2 ? item.address_line2 : "" }}
                    </p>
                    <p class="client_detail">
                      {{ item.city ? item.city : "" }}
                      {{ item.state ? item.state : "" }}
                      {{ item.country ? item.country : "" }}
                    </p>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-textarea
                height="100"
                autocomplete="off"
                role="presentation"
                v-model="form.note"
                class="border-1"
                name="driver_notes"
                :label="$t('vehicles.note')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-textarea>
            </v-col>
          </v-row>

          <br />
        </div>
      </div>

      <attachment-dialog
        :showDialog.sync="attachmentDialog"
        :data="rentalId"
        :resource="'rentals'"
      ></attachment-dialog>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import axios from "axios";
import { API_BASE_URL } from "@/config";
import AttachmentDialog from "@/components/AttachmentDialog";
import RentalAttachmentsList from "../../components/Rentals/RentalAttachmentsList.vue";
import VehicleReadOnlyInfo from "../../components/Vehicles/VehicleReadOnlyInfo.vue";
import moment from "moment";
export default {
  name: "AddRentalsHistory",
  mixins: [validationMixin],
  components: {
    "attachment-dialog": AttachmentDialog,
    // "rental-attachments-list": RentalAttachmentsList,
    VehicleReadOnlyInfo,
  },
  props: {
    showBookingDialog: [Boolean],
  },
  data() {
    return {
      isTrue: true,
      vehicle: null,
      rentalHistory: null,
      rentalId: this.$store.state.rentalId,
      is_edit: this.formMode === "edit",
      company_client_names: [],
      autocompleteValueLocal: false,
      search_client: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      search_driver: null,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      menuDtp: false,
      menuDtpStart: false,
      menuDtpEnd: false,
      getAddressData: null,
      pickup_addresses: [],
      driver_addresses: [],
      delivery_add_same_as_pickup: false,
      delivery_address_readonly: false,
      delivery_addresses: [],
      driver_names: [],
      search_pickup_location: null,
      search_delivery_location: null,
      search_driver_location: null,
      attachmentDialog: false,
      vehiclesAttachmentDialog: false,
      form_mode: this.$store.state.form_mode,
      driver: null,
      form: {
        client: {},
        client_reference: null,
        contract_reference: null,
        delivery_location: null,
        driver: {},
        pickup_location: {},
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        note: null,
        vehicle: this.vehicle,
        vehicle_id: this?.vehicle?.id || null,
      },
    };
  },
  created() {
    if (!this.$store.state.vehicleId) {
      this.$router.push("/rentals");
    }

    this.$store.commit("setLoading", true);
    axios
      .get(API_BASE_URL + "/client_vehicles/" + this.$store.state.vehicleId, {
        headers: this.header,
      })
      .finally(() => this.$store.commit("setLoading", false))
      .then((response) => {
        this.vehicle = response.data || null;
      })
      .catch((error) => {
        this.$noty.error(error.response.data.message, {
          timeout: 5000,
          layout: "bottomLeft",
        });
      });

    if (this.formMode === "edit") {
      this.setRentalHistory();
    }
  },
  methods: {
    renderDriverLastName(item) {
      if (typeof item == "string") {
        return item.toUpperCase();
      }
      return this.computeDriverLastName(item);
    },
    computeDriverLastName(item) {
      if (item.full_name === "") {
        return item.full_name;
      }

      return item.full_name.replace(item.first_name + " ", "");
    },
    falseButtonClicked() {
      this.$store.commit("setRentalVehicleForm", false);
    },
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    showVehiclesAttachmentDialog() {
      this.vehiclesAttachmentDialog = true;
    },
    setAddress(newValue, field) {
      if (typeof newValue == "string") {
        this[field] = newValue;
        return;
      }
      this[field] =
        (newValue.address_line1 ?? "") +
        " " +
        (newValue.address_line2 ?? "") +
        " " +
        (newValue.city ?? "") +
        " " +
        (newValue.state ?? "") +
        " " +
        (newValue.country ?? "");
    },

    async map_search_address(field) {
      if (!this[field] || typeof this[field] == "object") {
        return;
      }
      this.$store.state.isLoading = true;

      let address_name = "pickup_addresses";

      if (field == "search_delivery_location") {
        address_name = "delivery_addresses";
      }

      if (field == "search_driver_location") {
        address_name = "driver_addresses";
      }

      await axios
        .get(
          "https://api.geoapify.com/v1/geocode/search?apiKey=" +
            process.env.VUE_APP_GEOAPIFY_API_KEY +
            "&text=" +
            this[field].toString() +
            "&format=json"
        )
        .then((data) => {
          this[address_name] =
            data && data.data && data.data.results ? data.data.results : [];
        });

      this.$store.state.isLoading = false;
    },

    formPayload() {
      this.form.driver.full_name =
        this.form.driver.first_name + " " + this.form.driver.name;
      return this.form;
    },

    setRentalHistory() {
      this.$store.state.isLoading = true;
      axios
        .get(API_BASE_URL + "/vehicle_rentals/" + this.rentalId, {
          headers: this.header,
        })
        .finally(() => this.$store.commit("setLoading", false))
        .then((response) => {
          let history = response.data;
          this.rentalHistory = history;
          // Fill Form Data
          this.form = history;
          this.driver = history.driver;
          this.form.start_date = moment(history.start_date).format(
            "YYYY-MM-DD"
          );
          this.form.end_date = moment(history.end_date).format("YYYY-MM-DD");
        })
        .catch((error) => {
          this.$noty.error(
            error?.response?.data?.message || this.$t("unknown_error"),
            {
              timeout: 5000,
              layout: "bottomLeft",
            }
          );
        });
    },

    updateData() {
      this.$store.state.isLoading = true;
      let payload = this.formPayload();
      axios
        .put(
          API_BASE_URL + "/vehicle_rentals/" + this.$store.state.rentalId,
          payload,
          { headers: this.header }
        )
        .then((data) => {
          this.$noty.success(
            this.$t("vehicles.text_messages.contract_updated_successfully"),
            { timeout: 5000, layout: "bottomLeft" }
          );
          this.$store.commit("setRentalVehicleForm", false);
          this.$router.push("/rentals/history");
        })
        .catch((error) => {
          this.$noty.error(
            error?.response?.data?.errors ||
              error?.response?.data?.errors[0] ||
              error?.response?.message ||
              "Unknown error ocurred",
            { timeout: 5000, layout: "bottomLeft" }
          );
        })
        .finally(() => {
          this.$store.state.isLoading = false;
        });
    },
    addNew() {
      if (this.vehicle.is_available != true) {
        this.$noty.error(this.$t("rentals.vehicle_not_available"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
        return;
      }

      this.$store.state.isLoading = true;

      axios
        .post(API_BASE_URL + "/vehicle_rentals", this.formPayload(), {
          headers: this.header,
        })
        .then((data) => {
          this.$noty.success(
            this.$t("vehicles.text_messages.contract_created_successfully"),
            { timeout: 5000, layout: "bottomLeft" }
          );
          this.$store.commit("setRentalId", data.data.data.id);
          this.$router.push("/rentals/edit");
        })
        .catch((error) => {
          this.$noty.error(
            error?.response?.data?.errors ||
              error?.response?.data?.errors[0] ||
              error?.response?.message ||
              "Unknown error ocurred",
            { timeout: 5000, layout: "bottomLeft" }
          );
        })
        .finally(() => {
          this.$store.state.isLoading = false;
        });
    },
    saveData() {
      if (!this.form.client || JSON.stringify(this.form.client) === "{}") {
        return this.$noty.error(this.$t("rentals.client_error"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      }

      if (
        !this.form.pickup_location ||
        JSON.stringify(this.form.pickup_location) === "{}"
      ) {
        return this.$noty.error(this.$t("rentals.pickup_location_error"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      }

      if (
        !this.form.delivery_location ||
        JSON.stringify(this.form.delivery_location) === "{}"
      ) {
        return this.$noty.error(this.$t("rentals.delivery_location_error"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      }

      if (!this.form.start_date) {
        return this.$noty.error(this.$t("rentals.start_date_error"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      }

      if (!this.form.end_date) {
        return this.$noty.error(this.$t("rentals.end_date_error"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      }

      if (!this.driver && !this.form.driver.name) {
        return this.$noty.error(this.$t("rentals.last_name_error"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      }

      if (typeof this.driver !== "object") {
        const requiredFields = [
          "name",
          "first_name",
          "license",
          "gsm",
          "phone",
          "email",
          "address",
        ];
        const missingFields = requiredFields.filter(
          (field) => !this.form.driver[field]
        );

        if (missingFields.length) {
          return this.$noty.error(this.$t("rentals.driver_fields_error"), {
            timeout: 5000,
            layout: "bottomLeft",
          });
        }
      }

      if (this.formMode == "edit") {
        this.updateData();
      } else {
        this.addNew();
      }
    },
    fetchClientsListDebounced() {
      if (!this.search_client || typeof this.search_client == "object") {
        return;
      }
      this.$store.state.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/clients/search?" +
            (this.search_client ? "&search=" + this.search_client : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.company_client_names = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.$store.state.isLoading = false));
    },
    fetchDriversListDebounced() {
      if (!this.search_driver || typeof this.search_driver == "object") {
        return;
      }
      this.$store.state.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/clients/search?" +
            (this.search_driver ? "&search=" + this.search_driver : ""),
          // "&driver=true"
          { headers: this.header }
        )
        .then(({ data }) => {
          this.driver_names = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.$store.state.isLoading = false));
    },
    cancelRental() {
      this.$store.commit("setRentalVehicleForm", false);
      this.removeRentalInfo();
    },
    removeRentalInfo() {
      this.$store.commit("setRentalId", null);
      this.company_client_names = [];
      this.autocompleteValueLocal = false;
      this.search_client = null;
      this.is_boxes = this.getCompanyPropertyFromLocalStorage(
        "boxes_on_fields",
        1
      );
      this.search_driver = null;
      this.token = this.$store.state.AccessToken;
      this.header = {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      };
      this.menuDtp = false;
      this.menuDtpStart = false;
      this.menuDtpEnd = false;
      this.form.start_date = new Date().toISOString().substr(0, 10);
      this.getAddressData = null;
      this.search_driver_location = null;
      this.attachmentDialog = false;
      this.vehiclesAttachmentDialog = false;
      this.form = {};
      this.driver = null;
      this.$router.push("/rentals");
    },
  },
  watch: {
    rentStartDate(val) {
      this.form.end_date = val;
    },
    delivery_add_same_as_pickup(val) {
      if (val) {
        this.form.delivery_location = this.form.pickup_location;
        this.delivery_address_readonly = true;
      } else {
        this.delivery_address_readonly = false;
      }
    },
    rentPickupLocation() {
      if (this.delivery_add_same_as_pickup) {
        this.form.delivery_location = this.form.pickup_location;
      }
    },

    driver(val) {
      if (typeof val === "string" || !val) {
        if (val) {
          val = val.toUpperCase();
        }
        this.form.driver.id = null;
        return (this.form.driver.name = val);
      }

      return (this.form.driver = val);
    },

    vehicle(val) {
      this.form.vehicle = val;
      this.form.vehicle_id = val.id;
    },

    search_client(val) {
      if (!val || this.client_id == val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchClientsListDebounced();
      }, 500);
    },
    search_driver(val) {
      if (!val || this.driver_id == val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchDriversListDebounced();
      }, 500);
    },
    search_pickup_location(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.map_search_address("search_pickup_location");
      }, 500);
    },
    search_driver_location(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.map_search_address("search_driver_location");
      }, 500);
    },
    search_delivery_location(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.map_search_address("search_delivery_location");
      }, 500);
    },
  },
  computed: {
    addRentalVehicleFormActive() {
      return this.$store.state.addRentalVehicleFormActive;
    },
    formMode() {
      return this.$route.path.includes("edit") ? "edit" : "add";
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    rentStartDate() {
      return this.form.start_date;
    },
    rentPickupLocation() {
      return this.form.pickup_location;
    },
    driverFormReadOnly() {
      return typeof this.driver === "object";
    },
  },
};
</script>

<style scoped>
.rental-contract-from {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin-top: 30px;
}

.driver-from {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.driver-from .driver-from-item {
  width: 31%;
}

.attachment-title {
  position: relative;
  background: #fff;
  z-index: 0;
}

.attachment-title:after {
  content: "";
  width: Calc(100% + 10px);
  height: 1px;
  display: block;
  background: rgba(0, 0, 0, 0.42);
  position: absolute;
  top: 50%;
  left: -5px;
  z-index: -1;
}

.attachment-title span {
  background: #fff;
  display: inline-block;
  width: initial;
  position: relative;
  z-index: 0;
  padding: 0 5px;
}

.print-btn {
  background-color: #fff !important;
  border: 1px solid;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.v-select__slot {
  border: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-bottom: 0;
}

.v-select__selections {
  border-right: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.contract-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.contract-btn button {
  font-size: 10px !important;
}
</style>
