var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}),_c('v-navigation-drawer',{staticStyle:{"z-index":"9"},attrs:{"app":""},model:{value:(_vm.$store.state.showNavDrawer),callback:function ($$v) {_vm.$set(_vm.$store.state, "showNavDrawer", $$v)},expression:"$store.state.showNavDrawer"}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"admin-head-top"},[_c('div',[_c('v-list-item-title',{staticClass:"title"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"d-flex align-center profile-dropdown active",staticStyle:{"color":"black !important"}},on),[_vm._v(" "+_vm._s(_vm.$store.state.loginUser)+" "),_c('v-icon',{staticStyle:{"color":"black !important"}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.profile_list),function(item,index){return [_c('v-list-item',{key:index,staticClass:"cursor-pointer",class:{
                                                activeLanguage: item.active,
                                            }},[_c('v-hover',[_c('v-list-item-title',{on:{"click":function($event){return _vm.onListClick(
                                                            item.action,
                                                            item.data
                                                        )}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")],1)],1)],1),(item.divider)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("roles.sh_" + _vm.getUserRole()))+"@"+_vm._s(_vm.getCompanyName())+" ")])],1)])],1),_c('v-divider'),_c('v-treeview',{attrs:{"open":_vm.active_tree_opened_items,"items":_vm.nav_items,"active":_vm.active_tree_items,"item-key":"name","activatable":"","dense":"","open-on-click":"","open-all":""},on:{"update:active":[function($event){_vm.active_tree_items=$event},function($event){return _vm.selectActiveKey($event)}]},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
                                                        var item = ref.item;
return [(item.to)?_c('v-icon',{on:{"click":function($event){_vm.$router.push(item.to).catch(function () {})}}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])]}},{key:"label",fn:function(ref){
                                                        var item = ref.item;
return [(item.to)?_c('span',{on:{"click":function($event){_vm.$router.push(item.to).catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("" + item.name))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("" + item.name))+" ")])]}}]),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }