<template>
    <v-dialog persistent v-model="internalShowDialog" @click:outside="cancelDialog('cancel')" @keydown.esc="cancelDialog('cancel')" max-width="900">
        <v-card>
            <v-card-title class="headline">
            {{ $t("vehicles.clients_model.add_client") }}
            </v-card-title>
            <v-card-text>
            <v-form class="main_form" id="client-form">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-select
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.entityType"
                                :items="entityTypeSelectItems"
                                item-text="text"
                                class="to-upper"
                                item-value="id"
                                return-object
                                :label="$t('vehicles.clients_model.entity_type')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" :sm="isClientCompanySelected ? '8' : '5'">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.name"
                                name="name"
                                class="to-upper"
                                ref="client_name"
                                v-on:keydown="handleInput($event,'justalphanumeric',50);"
                                :label="$t('vehicles.clients_model.client_name')"
                                :error-messages="$store.state.validation && !$v.form.name.required ? [this.$t('vehicles.clients_model.text_messages.name_required')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="! isClientCompanySelected" :sm="isClientCompanySelected ? '8' : '5'">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.first_name"
                                name="name"
                                class="to-upper"
                                ref="client_name"
                                v-on:keydown="handleInput($event,'justalphanumeric',50);"
                                :label="$t('vehicles.clients_model.client_first_name')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" v-if="isClientCompanySelected">
                            <v-select
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.denomination"
                                :items="denominations"
                                item-value="id"
                                class="to-upper"
                                item-text="text"
                                :label="$t('vehicles.clients_model.denomination')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.email"
                                class="to-upper"
                                v-on:keydown="handleInput($event,'email',60);"
                                :label="$t('vehicles.clients_model.email')"
                                :error-messages="$store.state.validation && !$v.form.email.email ? [this.$t('vehicles.clients_model.text_messages.invalid_email')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.phone"
                                class="to-upper"
                                :label="$t('vehicles.clients_model.phone')"
                                v-on:keydown="handleInput($event,'phonenumber',45);"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.gsm"
                                class="to-upper"
                                v-on:keydown="handleInput($event,'phonenumber',45);" 
                                :label="$t('vehicles.clients_model.gsm')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.fax"
                                class="to-upper"
                                v-on:keydown="handleInput($event,'phonenumber',45);"
                                :label="$t('vehicles.clients_model.fax')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" :sm="isClientCompanySelected ? '3' : '4'">
                            <v-select
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.familyClient"
                                :items="familyClients"
                                item-value="id"
                                item-text="name"
                                menu-props="auto"
                                :label="$t('vehicles.clients_model.family_client')"
                                :error-messages="$store.state.validation && !$v.form.familyClient.required ? [this.$t('vehicles.clients_model.text_messages.family_client_required')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" :sm="isClientCompanySelected ? '3' : '4'">
                            <v-select
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.currency"
                                :items="currencies"
                                item-value="id"
                                item-text="text"
                                :label="$t('vehicles.clients_model.currency')"
                                :error-messages="$store.state.validation && !$v.form.currency.required ? [this.$t('vehicles.clients_model.text_messages.currency_required')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" :sm="isClientCompanySelected ? '3' : '4'" >
                            <v-select
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.language"
                                :items="languages"
                                item-value="id"
                                item-text="text"
                                :label="$t('vehicles.clients_model.languages')"
                                :error-messages="$store.state.validation && !$v.form.language.required ? [this.$t('vehicles.clients_model.text_messages.language_required')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3" v-if="isClientCompanySelected">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.tva"
                                ref="driver_tva"
                                class="to-upper"
                                required
                                v-on:keydown="handleInput($event,'justalphanumeric',45)"
                                :label="$t('vehicles.clients_model.tva')"
                                :error-messages="$store.state.validation && !$v.form.tva.required ? [this.$t('vehicles.clients_model.text_messages.tva_required')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-2" @click="cancelDialog('cancel')">
                    {{ $t('vehicles.Cancel') }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn color="success" @click="submit()">
                    {{ $t('vehicles.Save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import axios from '@/plugins/axios';
    import _ from 'lodash';
    import {API_BASE_URL} from "@/config";
    import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from '@/config';
    import { validationMixin } from 'vuelidate'
    import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha, minValue, maxValue } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        name:       "EditClientModel",
        components: {},
        props: {
            showDialog: [Number, Boolean],
            defaultClient: [Object]
        },
        validations: {
            form:{
                name:{
                    required: requiredIf(function () {
                        return !this.form.name || !this.form.name.length;
                    }),
                },
                email:{
                    email: (val) => email((val ?? "").toLowerCase()),
                },
                tva:{
                    required: requiredIf(function () {
                        return this.isClientCompanySelected;
                    }),
                },
                currency:{
                    required
                },
                language:{
                    required
                },
                familyClient:{
                    required
                }
            },
        },
        data() {
            const defaultForm = Object.freeze({
                entityType: { id: 1 },
                id: null,
                company: {},
                language: localStorage.getItem('languageId') || 'en',
                name: null,
                first_name: null,
                phone: null,
                gsm: null,
                fax: null,
                email: null,
                web: null,
                currency: localStorage.getItem('currencyId') || 'USD',
                representative_company_id: null,
                user: null,
                note: null,
                status: 10041001,
                familyClient: null,
                created_at: null,
                updated_at: null,
                denomination: 10061001,
                registration_code: null,
                tva: null
            });
            return {
                is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
                entityTypes: [{ id: 0, value: 'Private' }, { id: 1, value: 'Company' }],
                internalShowDialog:this.showDialog,
                autocompleteValueLocal : 'off',
                client: {},
                DeleteConfirm: false,
                form: Object.assign({}, defaultForm),
                denominations: [],
                clientStatuses: [],
                currencies: [],
                languages: [],
                operation: 'edit',
                token: this.$store.state.AccessToken,
                header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
                defaultClientNewData: null
            }
        },
        mounted(){
            this.$store.commit("setValidation",false); // enable validation
            console.log("defaultClient", this.defaultClient);
            this.autocompleteValueLocal = this.autocompleteValue();
            this.getCompanyDenominations();
            this.getStatuses();
            this.getCurrencies();
            this.getLanguages();
            this.setDefaultFamilyClient();
        },
        methods: {
            setDefaultFamilyClient(){
                this.familyClients.forEach(element => {
                    if(element.is_default == 1){
                        this.form.familyClient = element;
                    }
                });
                
            },
            getCompanyDenominations() {
                this.$store.state.isLoading = true;
                axios.get(API_BASE_URL + '/codes?class_key=company_denomination', {headers: this.header})
                .then(({ data }) => {
                    this.denominations = this.translateKeys(data.data, 'key', 'company_denomination');
                })
                .catch(function (error) {
                    console.log('an error occured ' + error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            },
            getCurrencies() {
                this.$store.state.isLoading = true;
                axios.get(API_BASE_URL + '/currencies', {headers: this.header})
                .then(({ data }) => {
                    this.currencies = this.translateKeys(data.data, 'key', 'currencies');
                })
                .catch(function (error) {
                    console.log('an error occured ' + error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            },
            getStatuses() {
                this.$store.state.isLoading = true;
                axios.get(API_BASE_URL + '/codes?class_key=client_statuses', {headers: this.header})
                .then(({ data }) => {
                    this.clientStatuses = data.data.map((item) => {
                        item.key = this.$t("clients."+item.key)
                        return item;
                    })
                })
                .catch(function (error) {
                    console.log('an error occured ' + error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            },
            getLanguages() {
                this.$store.state.isLoading = true;
                axios.get(API_BASE_URL + '/languages', {headers: this.header})
                .then(({ data }) => {
                    this.languages = this.translateKeys(data.data, 'key', 'languages');
                })
                .catch(function (error) {
                    console.log('an error occured ' + error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            },
            editClient (data) {
                this.$store.state.isLoading = true;
                axios.put(API_BASE_URL + '/clients/' + this.defaultClient.id, data, {headers: this.header})
                .then(data => {
                    this.$noty.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'), { timeout: 5000, layout: 'bottomLeft'});
                    this.cancelDialog('added',data.data.data);
                })
                .catch((error) =>{
                    this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft'});
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            },
            getClient(data) {
                this.$store.state.isLoading = true;
                axios.get(API_BASE_URL + '/clients/' + data.id, {headers: this.header})
                .then(response => {
                    this.defaultClientNewData = response.data.data;
                    this.setValues(response.data.data);
                })
                .catch((error) =>{
                    this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft'});
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            },
            setValues(val){
                console.log("client data updated", val);
                let isCompany = !!val.representative_company_id;
                this.form.name = val.name;
                this.form.first_name = val.first_name;
                this.form.entityType = isCompany ? this.entityTypeSelectItems[1] : this.entityTypeSelectItems[0];
                this.company_client_first_name = val.first_name;

                this.form.familyClient = val.family_client_id;
                this.form.email = val.email;
                this.form.gsm = val.gsm;
                this.form.language = val.language;
                this.form.phone = val.phone;
                this.form.fax = val.fax;
                this.form.representative_company_id = val.representative_company_id;
                
                if(val.company){
                    this.form.denomination = val.company.denomination_id;
                    this.form.tva = val.company.tva
                }
            },
            cancelDialog(operationPerformed, data) {
                this.$emit('update:showDialog', false);
                this.$emit('buttonClicked', operationPerformed == 'added' ? data : false);
                this.internalShowDialog = false;
            },
            submit() {
                this.$store.commit("setValidation",true); // enable validation
                if(this.$v.$invalid ){
                    this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft'});
                    return false;
                }
                else{
                    this.editClient(this.makeSubmitData());
                }
            },
            makeSubmitData(){
                return {
                    name: this.form.name,
                    first_name: this.form.first_name,
                    family_client_id:  typeof this.form.familyClient == 'object' ? this.form.familyClient.id : this.form.familyClient,
                    denomination_id: this.form.entityType.id > 0 ? this.form.denomination : 0,
                    representative_company_id:  null,
                    status_id: this.form.status,
                    currency_id: this.form.currency,
                    email: this.form.email,
                    phone: this.form.phone,
                    gsm: this.form.gsm,
                    fax: this.form.fax,
                    tva: this.form.tva,
                    language_id: typeof this.form.language == "object" ? this.form.language.id : this.form.language,
                };
            },
        },
        computed: {
            isClientCompanySelected () {
                return this.form.entityType.id === 1;
            },
        },
        beforeDestroy(){
            this.$store.commit("setValidation",false);
        },
        watch:   {
            showDialog:{
                handler: function(val){
                    this.internalShowDialog = val;
                }
            },
            defaultClient:{
                handler: function(val){
                    console.log("object client", val, !!val.representative_company_id);
                    if(val && typeof val == "object"){
                        this.getClient(val);
                    }
                },
                immediate: true,
            }
        }
}
</script>

<style scoped>

</style>
