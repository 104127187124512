<template>
  <v-row>
    <v-row>
      <v-col class="d-flex pl-6 pa-2 ma-0" cols="12" sm="12">
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('billings.search')"
          prepend-icon="mdi-magnify"
          v-bind:clearable="true"
          v-model="search"
          @focus="search=null"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('billings.identifiant')"
          v-bind:clearable="true"
          v-model="ftrByIdentifiant"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
        <!-- <v-autocomplete
            flat
            :items="companies"
            v-model="company"
            :label="$t('billings.company')"
            class="mr-2 to-upper"
            item-value="id"
            item-text="name"
            @focus="company=null"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
        ></v-autocomplete> -->
        <v-autocomplete
            flat
            :items="clients"
            v-model="client"
            :label="$t('billings.client')"
            class="mr-2 to-upper"
            item-value="id"
            item-text="clientname"
            @click:append="client = null"
            append-icon="mdi-close"
            :search-input.sync="searchClient"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
        ></v-autocomplete>
        <v-autocomplete
            flat
            :items="orderstatuses"
            v-model="status"
            :label="$t('billings.status')"
            class="mr-2 to-upper"
            item-value="id"
            item-text="text"
            @click:append="status = null"
            append-icon="mdi-close"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
        ></v-autocomplete>
        <v-select
            :items="billTypes"
            :label="$t('billings.type')"
            v-model="billType"
            class="d-flex to-upper"
            item-value="id"
            item-text="text"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
        >
        </v-select>

        <v-col class="d-flex" cols="12" sm="2">
          <v-combobox
            v-on:keydown="handleInput($event,'int',2);"
            @keyup="handleAutoFocus($event,2,'day','int')"
            autocomplete="donotautocomplete"
            v-model="day"
            :items="days"
            class="pt-0 to-upper"
            item-value="day"
            item-text="day"
            ref="day"
            :label="$t('billings.day')"
            v-on:keydown.enter.prevent=""
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-combobox>
          <v-combobox
              v-on:keydown="handleInput($event,'int',2);"
              @keyup="handleAutoFocus($event,2,'month','int')"
              autocomplete="donotautocomplete"
              v-model="month"
              ref="month"
              :items="months"
              class="pt-0 to-upper"
              item-value="id"
              item-text="text"
              :label="$t('billings.month')"
              v-on:keydown.enter.prevent=""
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-combobox>
          <v-combobox
              v-on:keydown="handleInput($event,'int',4);"
              autocomplete="donotautocomplete"
              v-model="year"
              ref="year"
              :items="years"
              class="pt-0 to-upper"
              item-value="year"
              item-text="year"
              :label="$t('billings.year')"
              v-on:keydown.enter.prevent=""
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-combobox>
        </v-col>
        
      </v-col>
    </v-row>

    <v-row>
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions
        }"
        :headers="headers"
        :items="billings"
        :options="options"
        :server-items-length="totalItems"
        :loading="isLoading"
        :loading-text="this.$t('loading')"
        class="elevation-0"
        :items-per-page="itemsPerPage"
        item-key="identifier"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        style="width:100%"
        :height="'calc(-200px + 100vh)'"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortOrder"
      >
      <template v-slot:item="{ item }">
        
              <tr :class="setRowColorClass(item)" @click="editItem(item)" :key="item.id">
                <td>
                  <v-tooltip right z-index="9999" >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning"  v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <v-list-item class="pa-0 ma-0" v-for="(articles, index) in item.details" :key="index">
                        <template v-if="articles.article && articles.article.descriptions.length > 0 " >
                          {{ articles.quantity }} X {{ articles.article.descriptions[0].description }}
                        </template>
                        <template v-else>
                          <template v-if="articles && articles.text">
                          {{ articles.quantity }} X {{ articles.text }}
                          </template>
                        </template>
                    </v-list-item>
                  </v-tooltip>
                </td>
                <td>{{ item.id || "-" }}</td>
                <!-- <td>{{ item.parent_order_id }}</td> -->
                <td>{{ item.bill_number }}</td>
                <td>
                  <span v-if="item.client">{{ item.client.name }} {{ item.client.first_name ? item.client.first_name : ''}}</span>
                </td>
                <!-- <td>{{ item.reference }}</td> -->
                <td align="right">{{ item.total_without_vat }}</td>
                <td align="right">{{ item.total }}</td>
                <td>
                  <span v-if="item.payedBy">{{ item.payedBy.key }}</span>
                </td>
                <td>{{ $t("billings.statuses."+item.status.value) }}</td>
                <td>
                  <template v-if="item.supplierorder && item.supplierorder.supplier_order_details">
                    {{ item.supplierorder.supplier_order_details.delivery_date }}
                  </template>
                  <template>
                    
                  </template>
                </td>
                <td>{{ item.created_at }}</td>
              </tr>
          
      </template>
      </v-data-table>
    </v-row>
  </v-row>
</template>

<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";


export default {
  name: "BillingListing",
  data () {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      isLoading:                true,
      totalItems:               0,
      page:                     1,
      lastPage:                 1,
      sortBy:                   'id',
      sortDesc:                 true,
      sortOrd:                  'DESC',
      itemsPerPage:             DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      billings:                 [],
      billType:                 '',
      search:                   '',
      ftrByIdentifiant:        '',
      ftrByCompany:            '',
      date:                     null,
      menu_date:                false,
      options:                  {},
      dataTableOptions:         DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token:                    this.$store.state.AccessToken,
      header:                   {},
      companies:                [],
      company:                  '',
      clients:                  [],
      client:                   '',
      nextEvents:               [],
      nextEvent:                '',
      allStatuses:              [],
      orderstatuses:                 [],
      status:                   '',
      years:                    [],
      year:                     '',
      months:                   [],
      month:                    '',
      days:                     [],
      day:                      '',
      isPageLoaded:             false,
      searchClient:             null,
      stopSearch:               true
    }
  },
  mounted () {
    // this.search = '';
    this.fillYears();
    this.fillMonths();
    this.header = { Authorization: 'Bearer ' + this.token },
    this.$store.commit('setFormMode', VIEW_MODE);
    this.$store.commit('setAddButtonRoute', 'AddClient')
    
    this.getStatuses()
    .then(() => {
        this.billType = this.billTypes[1];
    })
    .then(() => {
      this.applyPreviousFilters();
    })
    .then(() => {
      this.setDefaultStatusAndSearch();
    })
    .then(() => {
      this.$nextTick(() => {
        this.isPageLoaded = true;
        this.stopSearch = false;
        this.fetchData();
      })
    });
    
  },
  methods: {
    /**
     * This Function is responsible to autofocus on next property field, if current's max length reached
     */
    handleAutoFocus(event,maxlength,propertyName,dataType){
      let currentFieldLength = 0;
      if(dataType == 'int' || dataType == 'float'){
        currentFieldLength = (event.target.value + '').replace('.', '').length; 
      }
      else{
        currentFieldLength = (event.target.value + '').length; 
      }
      if(propertyName == 'day' && maxlength != null && currentFieldLength == maxlength){
        this.$refs['day'].blur();
        this.$refs['month'].focus();
      }
      if(propertyName == 'month' && maxlength != null && currentFieldLength == maxlength){
        this.$refs['month'].blur();
        this.$refs['year'].focus();
      }
    },
    setRowColorClass(item){
      let className = '';
      console.log("Row Item", item);
      if(item.due_date_status == 0 && this.billType.key == 'bill'){
        className = 'dueDate'
      }
      
      if(item.supplierorder && item.supplierorder.supplier_order_details && item.supplierorder.supplier_order_details.delivery_date && this.billType.key == 'purchase_order' && new Date() < new Date(this.formatDate(item.supplierorder.supplier_order_details.delivery_date, 'yyyy-mm-dd'))){
        className = 'yellowColor'
      }

      if(item.supplierorder && item.supplierorder.supplier_order_details && item.supplierorder.supplier_order_details.delivery_date && this.billType.key == 'purchase_order' && new Date() >= new Date(this.formatDate(item.supplierorder.supplier_order_details.delivery_date, 'yyyy-mm-dd'))){
        className = 'redColor'
      }

      if(item.supplierorder && item.supplierorder && item.supplierorder.status_id == 10091005 /** Fully Received */ && this.billType.key == 'purchase_order'){
        className = 'greenColor'
      }

      if(item.payed_on != null && item.payed_by_id != null){
        className = 'greenColor'
      }
      return className;
    },
    fillYears(){
      for(let i = new Date().getFullYear(); i > 2014 ; i--){
        this.years.push({year: i});
      }
      this.year = this.years[0];
    },
    fillMonths(){
      // this.months = [{'id'  : "01",'text' : 'January'},{'id'  : "02",'text' : 'February'},{'id'  : "03",'text' : 'March'},{'id'  : "04",'text' : 'April'},{'id'  : "05",'text' : 'May'},{'id'  : "06",'text' : 'June'},{'id'  : "07",'text' : 'July'},{'id'  : "08",'text' : 'August'},{'id'  : "09",'text' : 'September'},{'id'  : "10",'text' : 'October'},{'id'  : "11",'text' : 'November'},{'id'  : "12",'text' : 'December'}];
      this.months = [{'id'  : "01",'text' : '01'},{'id'  : "02",'text' : '02'},{'id'  : "03",'text' : '03'},{'id'  : "04",'text' : '04'},{'id'  : "05",'text' : '05'},{'id'  : "06",'text' : '06'},{'id'  : "07",'text' : '07'},{'id'  : "08",'text' : '08'},{'id'  : "09",'text' : '09'},{'id'  : "10",'text' : '10'},{'id'  : "11",'text' : '11'},{'id'  : "12",'text' : '12'}];
    },
    fetchData () {
      if(! this.stopSearch){
        this.isLoading = true
        this.billings = [];
        axios.get(API_BASE_URL + '/orders?page=' + this.page + '&items_per_page=' + this.itemsPerPage + 
            (this.sortBy ? ('&sortBy=' + this.sortBy) : '') +  
            (this.sortOrd ? ('&sortOrd=' + this.sortOrd) : '') +
            (this.search ? ('&search=' + this.search) : '' )+ 
            (this.ftrByIdentifiant ? ('&document_number=' + this.ftrByIdentifiant) : '' ) + 
            (this.company ? ('&company_id=' + this.company.company_id) : ''  ) +
            (this.client ? ('&client_id=' + this.client.id) : ''  ) +
            (this.status ? ('&status_id=' + this.status.id) : '' ) +
            (this.day ? ('&day=' + this.day) : '' ) + 
            (this.year ? ('&year=' + this.year.year) : '' ) + 
            (this.month ? ('&month=' + this.month.id) : '' ) + 
            (this.billType ? ('&type_id=' + this.billType.id) : '' ) 
        , {
          headers: this.header
        })
          .then(({ data }) => {
            this.updateLocalStorage();
            this.billings = data.data;
            this.lastPage = data.meta.last_page;
            this.totalItems = data.meta.total;
            this.page = data.meta.current_page;
            // this.itemsPerPage = parseInt(data.meta.per_page);
            // this.sortBy = null;
            // this.sortDesc = null;
            this.isLoading = false
          })
          .catch((err) => {
            console.log(err)
            this.isLoading = false
          })
          .finally(() => {
            this.stopSearch = false;
          });
      }
    },
    updateLocalStorage(){
        var filters = {
          items_per_page : this.itemsPerPage,
          sortby : this.sortBy,
          sorttype : this.sortOrd,
          search: this.search,
          ftrByIdentifiant: this.ftrByIdentifiant,
          company : this.company,
          client : this.client,
          status : this.status,
          day : this.day,
          year : this.year, 
          month : this.month,
          billType: this.billType 
        };
        localStorage.setItem("BILLING_LISTING_FILTERS",JSON.stringify(filters));
    },
    applyPreviousFilters(){
      let applyOnRoutes = ['BillingListing'];
      if(applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY")) ){
        var billingFilters = JSON.parse(localStorage.getItem("BILLING_LISTING_FILTERS"));
        if(billingFilters != null){
          this.billType = billingFilters.billType;
          this.items_per_page = billingFilters.items_per_page;
          this.sortby = billingFilters.sortby;
          this.sorttype = billingFilters.sorttype;
          this.search = billingFilters.search;
          this.ftrByIdentifiant = billingFilters.ftrByIdentifiant;
          this.company = billingFilters.company;
          if(billingFilters.client){
            this.client = billingFilters.client,
            this.clients = [this.client];
          }
          this.status = billingFilters.status;
          this.day = billingFilters.day;
          this.year = billingFilters.year;
          this.month = billingFilters.month;
        }
      }
    },
    filterOutStatuses(){
      return new Promise((resolve, reject) => {
        if(this.billType && this.billType.key == "estimation"){
          this.orderstatuses = this.allStatuses.filter((element) => {
            if(this.listingStatusFilters.estimation.find((el) => {return element.key == el})){
              return element;
            }
          });
        }
        else if(this.billType && this.billType.key == "purchase_order"){
          this.orderstatuses = this.allStatuses.filter((element) => {
            if(this.listingStatusFilters.purchase_order.find((el) => {return element.key == el})){
              return element;
            }
          });
        }
        else if(this.billType && this.billType.key == "bill"){
          this.orderstatuses = this.allStatuses.filter((element) => {
            if(this.listingStatusFilters.bill.find((el) => {return element.key == el})){
              return element;
            }
          });
        }
        else if(this.billType && this.billType.key == "credit_note"){
          this.orderstatuses = this.allStatuses.filter((element) => {
            if(this.listingStatusFilters.credit_note.find((el) => {return element.key == el})){
              return element;
            }
          });
        }
        resolve();
      });
    },
    getStatuses() {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/codes?class_key=order_status',{headers: this.header})
            .then(({data}) => {
                this.allStatuses = this.translateKeys(data.data, 'key', 'billings.statuses');
                resolve();
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
                reject();
            });
      });
    },
    getCompanies() {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/companies',{headers: this.header})
            .then(({data}) => {
              console.log(data.data);
                this.companies = data.data.result.data;
                resolve();
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
                reject();
            });
      });
    },
    getNextEvents() {
      return new Promise((resolve, reject) => {
      axios.get(API_BASE_URL + '/codes?class_key=order_next_event',{headers: this.header})
        .then(({data}) => {
          console.log(data.data);
            this.nextEvents = this.translateKeys(data.data, 'key', 'billings.nextEvents');
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
      });
    },
    
    updatePagination (page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage (itemsPerPage) {
      this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
      this.fetchData();
      this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
    },
    editItem (item) {
      
      this.$store.commit("seteditId",item.id);
      console.log(this.$store.state.editId,"commited Bill ID");
      this.$router.push('/billing/edit').catch(() => {});
    },
    deleteItem (item) {
      console.log("Delete Item");
    },
    

    updateSort (sortBy) {
      console.log('data =>', sortBy);
      if (typeof sortBy == 'object') {
        this.sortBy = sortBy[0]
      } else {
        this.sortBy = sortBy
      }
      this.fetchData()
      console.log('sort by => ', this.sortBy);
    },
    updateSortOrder (sortDesc) {
      console.log('data => ', sortDesc);
      if (typeof sortDesc == 'object') {
        !sortDesc[0] ? this.sortOrd = 'ASC' : this.sortOrd = 'DESC'
      } else {
        !sortDesc ? this.sortOrd = 'ASC' : this.sortOrd = 'DESC'
      }
      
      this.fetchData()
      console.log('sort order => ', this.sortOrd);
    },
    setDefaultStatusAndSearch(){
      this.filterOutStatuses()
      .then(() => {
        let applyOnRoutes = ['BillingListing'];
        console.log("applying", applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY")) && JSON.parse(localStorage.getItem("BILLING_LISTING_FILTERS")));
        if(applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY")) && JSON.parse(localStorage.getItem("BILLING_LISTING_FILTERS"))){
          var billingFilters = JSON.parse(localStorage.getItem("BILLING_LISTING_FILTERS"));
          if(billingFilters != null){
            this.status = billingFilters.status;
          }
          localStorage.removeItem("BILLING_LISTING_FILTERS")
          console.log("applying da", JSON.parse(localStorage.getItem("BILLING_LISTING_FILTERS")));
        }
        else if(this.status == this.orderstatuses[0]){
          this.fetchData();
        }
        else{
          this.status = this.orderstatuses[0];
        }
      });
    }
  },
  computed:{
    headers(){
      return [
        { text: this.$t('billings.info'),           value: 'id',              width:50,  sortable:false },
        { text: 'ID',                               value: 'id',              width:50,  sortable:true },
        { text: this.$t('billings.identifiant'),    value: 'bill_number',     width:100, sortable:false },
        { text: this.$t('billings.client'),         value: 'client_id' ,      width:100, sortable:false},
        { text: this.$t('billings.totalThtva'),     value: 'message' ,        width:120, sortable:false, align: 'end'},
        { text: this.$t('billings.total'),          value: 'message' ,        width:100, sortable:false, align: 'end'},
        { text: this.$t('billings.payed_by'),       value: 'payed_by_id' ,    width:90,  sortable:false, align: 'center'},
        { text: this.$t('billings.status'),         value: 'status.key',      width:100, sortable:false },
        { text: this.$t('billings.delivery_date'),  value: 'status.key',      width:100, sortable:false },
        { text: this.$t('billings.createdAt'),      value: 'created_at' ,     width:200, sortable:true},
      ];
    }
  },
  watch: {
    searchClient: _.debounce(function (e) {
      this.searchClient = this.searchClient.trim();
      if(this.searchClient && this.searchClient.length > 0 ){
        if(this.client && this.client.name == this.searchClient){
            return
        }
        this.clients = [];
        axios.get(API_BASE_URL + '/clients/search?search='+ this.searchClient,{headers: this.header})
        .then(({data}) => {
            data.data.forEach((element) => {
                element.clientname = element.name + ' ' + (element.first_name ?? '');
            })
            this.clients = data.data;
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
      }
    }, 1000),
    search:_.debounce(function (e) {
        if(this.isPageLoaded){
          this.fetchData();
        }
    }, 1000),
    ftrByIdentifiant:_.debounce(function (e) {
        if(this.isPageLoaded){
          this.fetchData();
        }
    }, 1000),
    company:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    client:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    status:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    billType:{
      handler:function(){
        if(this.isPageLoaded){
          localStorage.removeItem("BILLING_LISTING_FILTERS");
          this.setDefaultStatusAndSearch();
        }
      }
    },
    date:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    year:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    day:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    month:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
  }
}

</script>

<style scoped>
    tr:hover {
      border: 2px solid blue;
      color:#000;
      font-weight: 500;
    }
    tr.dueDate {
      background-color: rgb(255, 220, 220) !important;
    }
    tr.dueDate:hover {
      background: rgb(255, 153, 153) !important;
      border: 2px solid blue;
      color:#fff;
      font-weight: 500;
    }
    
    tr.greenColor {
      background-color: rgb(221, 255, 220) !important;
    }
    tr.greenColor:hover {
      background: rgb(153, 255, 162) !important;
      border: 2px solid blue;
      color:rgb(34, 34, 34);
      font-weight: 500;
    }


    tr.yellowColor {
      background-color: rgb(250, 255, 220) !important;
    }
    tr.yellowColor:hover {
      background: rgb(255, 240, 153) !important;
      border: 2px solid blue;
      color:rgb(39, 38, 38);
      font-weight: 500;
    }

    tr.redColor {
      background-color: rgb(255, 221, 220) !important;
    }
    tr.redColor:hover {
      background: rgb(255, 153, 153) !important;
      border: 2px solid blue;
      color:rgb(39, 38, 38);
      font-weight: 500;
    }
    
    .v-tooltip__content {
      font-size: 14px !important;
      background: rgb(255, 255, 255) !important;
      border: 1px solid black;
      line-height: 0%;
      opacity: 1 !important;
    }

</style>
