<template>
  <v-card class="ma-4 justify-center elevation-0 smooth-bg-color">
    
    <v-row>
      <v-col cols="12" sm="12" align="left">
        <div class="container pt-5 pb-5">

          <div class="row">
              <div class="col-md-3">
              </div>
              <div class="col-md-6">
                  <div class="card">
                      <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <!-- <p class="mb-0 text-muted">{{ $t('dashboard_content.welcome') }} {{ $store.state.loginUser }}</p> -->
                                        <h2>{{ $t('dashboard_content.welcome') }} {{ $store.state.loginUser }}</h2>
                                    </div>
                                </div>
                                <div class="m-t-40">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <span class="badge badge-primary badge-dot"></span>
                                            <span class="text-gray font-weight-semibold font-size-13">{{ $t('dashboard_content.tagline') }}</span>
                                        </div>
                                    </div>
                                    <div class="progress progress-sm w-100 m-b-0 mt-2">
                                        <div class="progress-bar bg-primary" style="width: 70%"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <v-layout justify-center align-center>
                                    <!-- Image Error -->
                                    <v-flex v-if="! imageError" shrink>
                                        <v-img
                                            style="max-height: 350px; max-width: 350px"
                                            :lazy-src="companyLogo"
                                            :src="companyLogo"
                                            v-on:error="onImgError"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-flex>
                                    <v-flex v-if="imageError" shrink>
                                        <v-img
                                            style="max-height: 100px; max-width: 80px"
                                            lazy-src="../assets/logo.png"
                                            src="../assets/logo.png"
                                        ></v-img>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </div>

                      </div>
                  </div>
              </div>
          </div>
          

          <!-- <div class="row">
              <div class="col-md-4">
                  <div class="card">
                      <div class="card-body">
                          <div class="d-flex justify-content-between align-items-center">
                              <div>
                                  <p class="mb-0 text-muted">Total Monthly Sales</p>
                                  <h2>€ 23,523</h2>
                              </div>
                          </div>
                          <div class="m-t-40">
                              <div class="d-flex justify-content-between">
                                  <div class="d-flex align-items-center">
                                      <span class="badge badge-primary badge-dot mr-2"></span>
                                      <span class="text-gray font-weight-semibold font-size-13"> </span>
                                  </div>
                                  <span class="text-dark font-weight-semibold font-size-13 mt-2"> </span>
                              </div>
                              <div class="progress progress-sm w-100 m-b-0 mt-2">
                                  <div class="progress-bar bg-primary" style="width: 70%"></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>


              <div class="col-md-4">
                  <div class="card">
                      <div class="card-body">
                          <div class="d-flex justify-content-between align-items-center">
                              <div>
                                  <p class="mb-0 text-muted">Total Users</p>
                                  <h2>{{ total_users }}</h2>
                              </div>
                          </div>
                          <div class="m-t-40">
                              <div class="d-flex justify-content-between">
                                  <div class="d-flex align-items-center">
                                      <span class="badge badge-primary badge-dot mr-2"></span>
                                      <span class="text-gray font-weight-semibold font-size-13"> </span>
                                  </div>
                                  <span class="text-dark font-weight-semibold font-size-13 mt-2"> </span>
                              </div>
                              <div class="progress progress-sm w-100 m-b-0 mt-2">
                                  <div class="progress-bar bg-primary" style="width: 70%"></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>



              <div class="col-md-4">
                  <div class="card">
                      <div class="card-body">
                          <div class="d-flex justify-content-between align-items-center">
                              <div>
                                  <p class="mb-0 text-muted">Total Vehicles</p>
                                  <h2> {{ total_vehicles }} </h2>
                              </div>
                          </div>
                          <div class="m-t-40">
                              <div class="d-flex justify-content-between">
                                  <div class="d-flex align-items-center">
                                      <span class="badge badge-primary badge-dot mr-2"></span>
                                      <span class="text-gray font-weight-semibold font-size-13"> </span>
                                  </div>
                                  <span class="text-dark font-weight-semibold font-size-13 mt-2"> </span>
                              </div>
                              <div class="progress progress-sm w-100 m-b-0 mt-2">
                                  <div class="progress-bar bg-primary" style="width: 70%"></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> -->

          <!-- <div class="row">
              <div class="col-md-4">
                  <div class="card">
                      <div class="card-body">
                          <v-sparkline
                            :fill="fill"
                            :gradient="selectedGradient"
                            :line-width="width"
                            :padding="padding"
                            :smooth="radius || false"
                            :value="value"
                            auto-draw
                          ></v-sparkline>
                      </div>
                  </div>
              </div>
              
          </div> -->


        </div>
      </v-col>
    </v-row>
    <v-row class="mt-20">

    </v-row>
  </v-card>
</template>

<script>

import { API_BASE_URL } from "@/config";
import axios from '@/plugins/axios';

export default {
  
  name: "Dashboard",
  data() {
    return {
      imageError: false,
      companyLogo: null,
      fill: false,
      selectedGradient: ['#222'],
      padding: 8,
      radius: 10,
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      width: 2,
      total_users: 0,
      total_vehicles: 0,
      total_monthly_sales: 91235
    };
  },
  methods:{
    onImgError() {
      console.log("Invalid Image");
      this.imageError = true;
    }
  },
  mounted(){
        this.companyLogo = this.getCompanyPropertyFromLocalStorage('DASHBOARD_LOGO') ? this.getCompanyPropertyFromLocalStorage('DASHBOARD_LOGO').value : null;
        if(!this.companyLogo){
            this.imageError = true;
        }
        axios.get(API_BASE_URL + '/dashboard', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
      .then(({data}) => {
        this.total_users = data.data.total_users;
        this.total_vehicles = data.data.total_vehicles;
      })
      .catch(function (error) {
          console.log('an error occured ' + error);
      });
  }
}
</script>
<style scoped>
  .chart {
    height: 400px;
  }
  .c-dashboardInfo {
    margin-bottom: 15px;
  }
  .c-dashboardInfo .wrap {
    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
    height: 100%;
  }
  .c-dashboardInfo__title,
  .c-dashboardInfo__subInfo {
    color: #6c6c6c;
    font-size: 1.18em;
  }
  .c-dashboardInfo span {
    display: block;
  }
  .c-dashboardInfo__count {
    font-weight: 600;
    font-size: 2.5em;
    line-height: 64px;
    color: #323c43;
  }
  .c-dashboardInfo .wrap:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    content: "";
  }
  .c-dashboardInfo:nth-child(1) .wrap:after {
    background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
  }
  .c-dashboardInfo:nth-child(2) .wrap:after {
    background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
  }
  .c-dashboardInfo:nth-child(3) .wrap:after {
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
  }
  .c-dashboardInfo:nth-child(4) .wrap:after {
    background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
  }
  .c-dashboardInfo__title svg {
    color: #d7d7d7;
    margin-left: 5px;
  }
  .MuiSvgIcon-root-19 {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }

  .card {
    position: relative;
    background-color: #fff;
    margin-bottom: 1.25rem;
    border: 1px solid #edf2f9;
    border-radius: .25rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }
  .card-body {
      padding: 1.5rem;
      position: relative;
  }
  .card-body .h2 {
      font-size: 24px;
  }
  .badge-cyan {
      color: #00c9a7;
      background: rgba(0,201,167,0.1);
      border-color: transparent;
      font-weight: 500;
  }
  .font-size-13 {
      font-size: 13px !important;
  }
</style>