<template>
    <div :row="row.index" :appointmentid="appointment.data.id" :column="colindex-1" class="draggableObject" :class="addClass(row, colindex)" :style="addTaskStyle(row.items[colindex-1])">
        <div class="draggableObjectData" > 
            <div @click="editAppointment(row.index, colindex-1)">
                <span class="text-sm-left"> 
                    <template v-if="appointment.data">
                        <template v-if="appointment.data.client && appointment.data.client.name">
                            <v-icon style="font-size:20px" color="white" v-if="appointment && appointment.data && appointment.data.appointment_status && appointment.data.appointment_status.key == 'appointment_is_executed'">mdi-account-check</v-icon>
                            {{ appointment.data.client.name }} {{ appointment.data.client.first_name ? appointment.data.client.first_name : '' }} 
                        </template>
                        <template v-if="appointment.data.vehicle">
                            <template v-if="appointment.data.client.name && appointment.data.client.name.length <= 22">
                                <br>
                            </template>
                            <template v-else>
                                -
                            </template>

                            <template v-if="appointment.data.vehicle.brand_id && appointment.data.vehicle.brand">
                                {{ appointment.data.vehicle.brand.name }} 
                            </template>
                            <template v-if="appointment.data.vehicle.model">
                                {{ appointment.data.vehicle.model }} / 
                            </template>
                            {{ appointment.data.vehicle.registration_number }}
                        </template>
                    </template>
                </span>
                <span class="text-sm-left" v-if="appointment.data.note">
                    <br>
                    <strong>{{ $t("scheduler.appointment.note") }}</strong>
                    {{ appointment.data.note }}
                </span>
                <span class="original_st" v-if="appointment.data && appointment.data.original_start_time && appointment.data.original_start_time !=  appointment.data.start_time">
                    <v-icon color="white" v-if="appointment.data.original_start_time < appointment.data.start_time">mdi-code-greater-than</v-icon>
                    <v-icon color="white" v-if="appointment.data.original_start_time > appointment.data.start_time">mdi-code-less-than</v-icon>
                </span>
            </div>
            <!-- <div class="bottom-box">
                <template v-if="isBill(row, colindex)">
                    <v-btn icon color="rgb(18, 170, 31)" @click="editAppointment(row.index, colindex-1)">
                        <v-icon>mdi-check-bold</v-icon>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn v-if="! isPastDate()" icon color="white" @click="deleteAppointment(row.index, colindex-1, row)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon color="white" @click="editAppointment(row.index, colindex-1)">
                        <v-icon>mdi-calendar-edit</v-icon>
                    </v-btn>
                </template>
                
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    
    components: {

    },
    props:{
        rows: [Array],
        otherAppointmentCounts: [Number],
        appointmentIndexInSameSlot: [Number],
        appointment: [Object],
        row: [Object],
        colindex: [Number],
        noofcolumns: [Number]
    },
    data() {
        return {
            maxWidth: 180
        }
    },
    mounted(){
        // this.maxWidth = this.getDayViewWidth() / this.noofcolumns;
        
    },
    methods:{
        addClass(row, colindex){
            let classToBeAdded = this.isBill(row, colindex) ? 'bill' : '';
            if(this.appointment && this.appointment.data && this.appointment.data.appointment_status && this.appointment.data.appointment_status.key == 'appointment_not_executed'){
                classToBeAdded += ' not-executed';
            }
            return classToBeAdded;
        },
        isBill(row, colindex){
            return (row.items[colindex-1].data && row.items[colindex-1].data) ? (row.items[colindex-1].data.order_type_id == 10241002 ? true : false) : false;
        },
        addTaskStyle(task){
            let unitAppointmentMaxWidth = this.maxWidth;
            if(this.otherAppointmentCounts > 0){
                unitAppointmentMaxWidth = this.maxWidth / this.otherAppointmentCounts;
            }

            let style = {
                "height": `${this.appointment.height}px`,
                "width": `${unitAppointmentMaxWidth}px`,
                "margin-left": `${unitAppointmentMaxWidth * this.appointmentIndexInSameSlot}px`,
                "background-color": `#${this.appointment.data.color}`,
            };
            
            return style
        },
        editAppointment(rowindex, columnindex){
            this.$store.commit("setAppointmentRedirectData",{
                'id': this.appointment.data.id
            });
            this.$router.push('/scheduler/edit').catch(() => {});
        },
    }
}
</script>
<style scoped>

        .draggableObject{
            position: absolute;
            /* background-color: rgba(67, 180, 52, 0.733); */
            z-index: 1;
            min-height: 20px;
            margin-top: -25px;
            border: 2px solid white;
            /* width: 200px !important; */
            box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
            cursor: move !important;
            overflow: hidden;
        }
        .draggableObject:hover{
            box-shadow: 0px 2px 11px rgba(0,0,0,0.41);
            transform: scale(1.01,1.01);
            z-index: 9;
            /* overflow: auto !important; */
        }
        .draggableObject:hover > .draggableObjectData > .bottom-box{
            z-index: 9;
            transition: all 0.7s ease;
            background-color: rgba(12, 0, 0, 0.021);
            opacity: 1;
            bottom: 0px;
            right: 0px;
        }
        .draggableObjectData{
            color: white;
            margin: 10px;
            font-weight: 400;
            line-height: 18px;
            font-size: 14px;
            margin: 5px;
        }
        .draggableObjectData:hover{
            cursor: pointer;
        }

        .bill{
            border: 2px solid rgb(18, 170, 31);
        }

        .bottom-box{
            opacity: 0;
            position: absolute;
            bottom: -10px;
            right: -10px;
        }
        .not-executed{
            background-color: #ea9191 !important;
            border: 3px solid red;
        }
        .original_st{
            position: absolute;
            top: 0px;
            float: right;
            right: 0px;
            color: white;
        }
</style>