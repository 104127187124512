import {API_BASE_URL} from './config'
import CryptoJS from 'crypto-js';

export default {
    data(){
        return{
            entityTypeSelectItems: [
                { id: 0, value: 'private', text:'' }, 
                { id: 1, value: 'company', text:''  }
            ],
            statusFilters: {
                estimation: ['open','closed'],
                purchase_order: ['open','closed', 'canceled'],
                bill: ['waiting','payed','canceled'],
                credit_note: ['waiting','payed','canceled'],
            },
            listingStatusFilters: {
                estimation: ['open','closed'],
                purchase_order: ['open','closed', 'canceled'],
                bill: ['open','waiting','payed','canceled'],
                credit_note: ['open','waiting','payed','canceled'],
            },
            creditNoteMultiplicationDefaultValue:{
                value: -1
            },
            availableLocales: [],
        };
    },
    computed:{
        daysOfWeek(){
            return [
                this.$t('days.sunday'),
                this.$t('days.monday'),
                this.$t('days.tuesday'),
                this.$t('days.wednesday'),
                this.$t('days.thursday'),
                this.$t('days.friday'),
                this.$t('days.saturday')
            ];
        }
    },
    beforeMount() {
        this.getLocales();
    },
    mounted() {
        this.entityTypeSelectItems = this.translateKeys(this.entityTypeSelectItems, 'value', 'entity_type');
    },
    methods: {
        /** Swap Article array elements from one position to another */
        swap_article_array_elements(arr, old_index, new_index) {
            arr.forEach((element) => {
                console.log("moving",element.article_original_name.identifier)
            })
            // let tempElement = arr[old_index];
            // arr[old_index] = arr[new_index];
            // arr[new_index] = tempElement; 
            let removedItem = arr.splice(old_index, 1)[0]
            arr.splice(new_index, 0, removedItem)
            let count = 0;
            arr.forEach((element) => {
                element.srno = count++;
                element.row = count;
            })
            console.log("-----------------");
            arr.forEach((element) => {
                console.log("moving---------",element.article_original_name.identifier)
            })
            return arr;
        },       
        getLocales(){
            this.availableLocales = [];
            const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
            locales.keys().forEach(key => {
                const locale = key.replaceAll("/","").replaceAll(".json", "").replaceAll(".", "");
                this.availableLocales.push(locale);
            })
        },
        findCompanyLocale(passedLocale){
            this.getLocales();
            let data = JSON.parse(localStorage.getItem('data'));
            let companyIdentifier = data.userDetails.company.identifier;
            let userLanguage = localStorage.getItem('languageId');
            let localLocale = passedLocale ? (companyIdentifier + '' + passedLocale) : (companyIdentifier + '' + userLanguage);
            let localFound = false;
            this.availableLocales.find((element) => {
                if(element == localLocale){
                    localFound = true;
                }
            });
            return localFound ? localLocale : localStorage.getItem('languageId');
        },
        copyText () {
            document.getElementById('textToCopy').select();
            document.execCommand("copy");
        },
        checkTag(tag){
            return JSON.parse(localStorage.getItem('applicationResources')).includes(tag);
        },
        getDayViewWidth(){
            if(document.getElementById('scrollableDayView')){
                return parseInt(document.getElementById('scrollableDayView').offsetWidth) - 150;
            }
            return 250;
        },
        setTableThTdWidth(width){
            let boxes = document.querySelectorAll('.tablethtd');
            boxes.forEach(box => {
                box.style.minWidth = `180px`;
            });
        },
        getDay(date){
            let i = new Date(date).getDay(date)
            return this.daysOfWeek[i]
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatDate(date, format) {
            date = new Date(date);
            if(format === 'mm/dd/yyyy'){
                return [ this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate()), date.getFullYear(), ].join('/');
            }
            if(format === 'yyyy/mm/dd'){
                return [ date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join('/');
            }
            if(format === 'yyyy-mm-dd'){
                return [ date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join('-');
            }
            if(format === 'dd-mm-yyyy'){
                return [ this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear() ].join('-');
            }
        },
        /**
         * It handles the type of values that can be entered in the text field based upon its datatypes
         * This is also responsible for the behaviour like : when max length reached no more data can be entered.
         */
        handleInput: function(event, dataType,maxlength, decimalPlaces) {
            event = (event) ? event : window.event;
            var charCode = (event.which) ? event.which : event.keyCode;
            
            if( charCode == 9 || charCode == 8 || charCode == 46 || (charCode >= 37 && charCode <=40 )){
                return true
            }
            console.log("event triggered", maxlength, maxlength, decimalPlaces, Number(maxlength) + Number(decimalPlaces ?? 0));
            if( maxlength != null && (event.target.value + '').replace('.','').length >=  Number(maxlength) + Number(decimalPlaces ?? 0)){
                if(event.target.selectionStart == event.target.selectionEnd){
                    event.preventDefault()
                }
                else{
                    if(event.target.selectionEnd == maxlength){ // Allow the user to delete the selected text and press the key typed
                        return true;
                    }
                    else{
                        event.preventDefault();
                    }
                }
            }
            if(dataType == 'int'){
                if ( charCode == 8 || charCode == 32 ||  ( /^[0-9]$/i.test(event.key) )) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }
            if(dataType == 'float'){
                let dotExists = false;
                if((event.target.value + '').indexOf('.') != -1){
                    dotExists = true;
                }
                if ( !event.shiftKey && ((charCode == 190 && !dotExists) || charCode == 8 || ( /^[0-9.,]$/i.test(event.key) )) ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }
    
            if(dataType == 'alpha'){
                if ( charCode == 8 || charCode == 32 || ( /^[a-zA-Z]$/i.test(event.key) )) {
                return true;
                } else {
                event.preventDefault();
                }
            }

            if(dataType == 'email'){
                if ( charCode == 8 || charCode == 32 || ( /^[a-zA-Z0-9@+-/_]$/i.test(event.key) )) {
                return true;
                } else {
                event.preventDefault();
                }
            }
    
            if(dataType == 'alphanumeric'){
                if ( charCode == 8 || charCode == 32 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ) ) {
                return true;
                } else {
                event.preventDefault();
                }
            }

            if(dataType == 'alphanumericwithenter'){
                console.log(charCode, charCode == 8 || charCode == 32 || charCode == 13 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ));
                if ( charCode == 8 || charCode == 32 || charCode == 13 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ) ) {
                return true;
                } else {
                event.preventDefault();
                }
            }
            if(dataType == 'everything'){
                // console.log(charCode, charCode == 8 || charCode == 32 || charCode == 13 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ));
                // if ( charCode == 8 || charCode == 32 || charCode == 13 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ) ) {
                // return true;
                // } else {
                // event.preventDefault();
                // }
            }

            if(dataType == 'justalphanumeric'){
                if ( charCode == 8 || charCode == 32 || ( /^[0-9a-zA-Z]$/i.test(event.key) ) ) {
                return true;
                } else {
                event.preventDefault();
                }
            }
            if(dataType == 'justalphanumericwithoutspace'){
                if ( charCode == 8 || ( /^[0-9a-zA-Z]$/i.test(event.key) ) ) {
                return true;
                } else {
                event.preventDefault();
                }
            }

            if(dataType == 'alphanumdash'){
                if ( charCode == 8 || charCode == 32 || ( /^[0-9a-zA-Z/-]$/i.test(event.key) ) ) {
                return true;
                } else {
                event.preventDefault();
                }
            }
            if(dataType == 'phonenumber'){
                if ( charCode == 8 || charCode == 32 || ( /^[0-9+ /)/(/-]$/i.test(event.key) ) ) {
                return true;
                } else {
                event.preventDefault();
                }
            }
            
    
            return true;
        },
        handleUnkownInputs: function(event) {
            console.log(event,"handle inputs");
        },
        checkBrowser: function () {
            if(navigator.userAgent.indexOf("Firefox") > -1 || typeof InstallTrigger !== 'undefined'){
                return 'firefox';
            }
            if(navigator.userAgent.indexOf("Safari") > -1 || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))){
                return 'safari';
            }
    
            if(navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("rv:") > -1 || /*@cc_on!@*/false || !!document.documentMode){
                return 'IE';
            }
    
            if(!(/*@cc_on!@*/false || !!document.documentMode) && !!window.StyleMedia){
                return 'edge';
            }
    
            if(navigator.userAgent.indexOf("Chrome") > -1 || !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)){
                return 'chrome';
            }
        },
        autocompleteValue: function() {
            let browser = this.checkBrowser();
            console.log("browser is" + browser,browser=='firefox',"browser ");
            if(browser == 'firefox'){
                return 'off';
            }
            if(browser == 'chrome'){
                return 'offf';
            }
            return 'offf';
        },

        encryptData(pvtData) {
            pvtData = pvtData+"";
            let secret = this.getCurrentDate();
            return CryptoJS.AES.encrypt(pvtData, secret).toString();
        },
        
        decryptData(secretData) {
            let secret = API_BASE_URL + this.getCurrentDate() ;
            return CryptoJS.AES.decrypt(secretData, secret).toString(CryptoJS.enc.Utf8);
        },
        getCurrentDate(){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;
            return today;
        },
        getEverythingFamilyArticle(){
            return {
                "id":0,
                "parent_family_id":null,
                "company_id":null,
                "tva_id":1,
                "name":"everything",
                "created_at":"2021-03-10",
                "updated_at":"2021-03-10",
                "migration_id":null,
                "properties":[]
            }
        },
        updateFamilyArticleInLocalStorage(familyArticleId){
            var articleFilters = JSON.parse(localStorage.getItem("ARTICLE_MODEL_FILTERS") );
            articleFilters['family'] = familyArticleId;
            localStorage.setItem("ARTICLE_MODEL_FILTERS",JSON.stringify(articleFilters));
        },
        returnErrorMessage(...args) {
            for (let index = 0; index < args.length; index++) {
                if(args[index] instanceof Array && args[index][0] == true){
                    return args[index][1];
                }
            }
        },
        generateRandomString(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * 
                charactersLength));
            }
            return result;
        },
        filterProperties(customFilters, properties){
            var filteredProperties =[];
            filteredProperties = customFilters.filter((property) => { 
            let found = false;
            properties.forEach(element => {
                if(element == property.property_name){
                found=true;
                }
            });
            return found ? property : false;
            });
            return filteredProperties;
        },

        filterPropertiesExceptProperties(customFilters, propertiesToExcept){
            var filteredProperties =[];
            filteredProperties = customFilters.filter((property) => { 
                let found = false;
                propertiesToExcept.forEach(element => {
                    if(element == property.property_name){
                        found=true;
                    }
                });
            
                return found ? false : property;
            });
            return filteredProperties;
        },

        getCompanyPropertyFromLocalStorage(propertyNameToFind, valuetomatch = null){
            let propertyValue = "";
            let companyProperties = JSON.parse(localStorage.getItem('company_properties'));
            let foundValue = null;
            if(companyProperties.length > 0){
                companyProperties.forEach(companyProperty => {
                    if((companyProperty.company_setting_key).toLowerCase() == (propertyNameToFind).toLowerCase()){
                        
                        if(valuetomatch){
                            if(companyProperty.value == valuetomatch)
                                foundValue = companyProperty;
                        }
                        else{
                            foundValue = companyProperty;
                        }
                    }
                });
                return foundValue;
            }
            return null;
        },
        getDecimalNumber(){
            let decimal_precision = this.getCompanyPropertyFromLocalStorage('decimal_precision');
            return  decimal_precision ? decimal_precision.value : 2;
        },
        fixDecimal(number, n){
            if(n == undefined){
                n = this.getDecimalNumber();
            }
            return (number ? parseFloat(number) :  0).toFixed(n);
        },
        getDatePartFromDateTimeStr(dtstr){
            if(dtstr){
                return dtstr.substr(0,10);
            }
            return "";
        },
        makeZeros(decimalQuantity){
            if(decimalQuantity && decimalQuantity > 0){
                let zerostring = "0.";
                for (let count = 0; count < decimalQuantity; count++) {
                    zerostring += "0";
                }
                return zerostring;
            }
            return "0";
        },
        getKeyFromCode(dataArray,code){
            for (let index = 0; index < dataArray.length; index++) {
                if(dataArray[index].id == code){
                return dataArray[index].key;
                }
            }
            return null;
        },
        translateKeys(data, field, section) {
            if(typeof data == 'object'){
                for (let index in data) {
                    data[index].text = this.$t(section + '.' + data[index][field]);
                }
                return data;
            }
            else{
                return this.$t(section + '.' + data);
            }
            
        },
        encryptToMD5(data) {
            return CryptoJS.MD5(data);
        },
        resetBillType(){
            this.billTypes.forEach((billType) => {
                if(billType.key == "purchase_order"){
                    this.$store.commit("setBillType", billType);
                }
            })
        }
    },
};
