<template>
	<v-card class="elevation-0">
		<v-row>
			<v-col cols="12" lg="2" md="3" sm="5" class="listing-wrp">
				<v-card class="mx-auto" max-width="500">
          <filter-by-registration-number @set_isLoading = "new_isLoading => isLoading = new_isLoading " @set_vehicles="new_vehicles => vehicles = new_vehicles" @set_is_reload_component="new_is_reload_component => is_reload_component = new_is_reload_component"  :vehicles="vehicles"/>
				</v-card>
			</v-col>
			<v-col cols="12" lg="10" md="9" sm="7">
				<History :is_reload_component="is_reload_component" />
			</v-col>
		</v-row>

		<!-- <v-row>
		<v-col>
			<v-row>
				<v-spacer></v-spacer>
				<v-col class="pa-0 ma-0" cols="12" sm="3">
					<v-text-field
						:autocomplete="autocompleteValueLocal"
						class="mr-3 to-upper"
						:label="$t('vehicles.search')"
						autofocus
						@input="debounceInput"
						prepend-icon="mdi-magnify"
						v-bind:clearable="true"
						clear-icon="mdi-close"
						v-model="search"
						:outlined="is_boxes"
						:dense="is_boxes"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-data-table
					:footer-props="{
						'items-per-page-options': dataTableOptions
					}"
					fixed-header
					:headers="headers"
					:options="options"
					:server-items-length="totalItems"
					:loading="isLoading"
					:loading-text="this.$t('loading')"
					class="elevation-0"
					:height="'calc(-200px + 100vh)'"
					style="width: 100%"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:items-per-page="itemsPerPage"
					item-key="identifier"
					@update:sort-by="updateSort"
					@update:sort-desc="updateSortOrder"
					@update:page="updatePagination"
					@update:items-per-page="updateItemsPerPage"
					:items="vehicles"
				>
					<template v-slot:item="{ item }">
						<tr>
							<td>
								{{ item.id}}
							</td>
							<td>
								<template v-if="item.is_out_of_circulation">
									<v-tooltip top z-index="9999">
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="warning"  v-bind="attrs" v-on="on">
												mdi-car-arrow-right
											</v-icon>
										</template>
										{{ $t('vehicle_is_out_of_circulation') }}
									</v-tooltip>
								</template>
							</td>
							<td @click="rentalHistory(item)">
								{{ item.registration_number}}
							</td>
							<td>
								<span v-if="item.client"> {{ item.client.name}} {{ item.client.first_name ? item.client.first_name : '' }}</span>
							</td>
							<td> <span v-if="item.brand"> {{ item.brand.name}} </span> </td>
							<td>
								{{ item.model}}
							</td>
							<td>
								<v-icon v-if="item.is_leasing" class="mr-1" color="success">
									mdi-check
								</v-icon>
							</td>
							<td>
								<v-icon v-if="item.is_for_rent" class="mr-1" color="success">
									mdi-check
								</v-icon>
							</td>
							<td>
								<span v-if="item.driver"> 
                                    <span v-if="item.driver"> {{ item.driver.name}} {{ item.driver.first_name ? item.driver.first_name : '' }}</span>
                                </span>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-row>
		</v-col>
	</v-row>  -->
	</v-card>




</template>
<script>
	import axios from '@/plugins/axios';
	import _ from 'lodash';
	import { API_BASE_URL } from "@/config";
	import { VIEW_MODE } from "@/config";
	import { EDIT_MODE } from "@/config";
	import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
	import History from './History.vue';
	import FilterByRegistrationNumber from './FilterByRegistrationNumber.vue';

	export default {
		name: "RentalsListing",
		data() {
			return {
				is_reload_component: false,
				is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
				dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
				vehicles: [],
				alerts: [],
				search: "",
				isLoading: true,
				totalItems: 0,
				page: 1,
				lastPage: 1,
				itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
				options: {},
				sortBy: "registration_number",
				sortDesc: false,
				sortOrd: "ASC",
				sort: {},
				showAlert: false,
				token: this.$store.state.AccessToken,
				header: { Authorization: "Bearer " + this.$store.state.AccessToken },
				autocompleteValueLocal: "off",
				isDebounceCancelled: false
			};
		},
		mounted() {
			// this.$store.commit("setVehicleId", null);
			this.$store.commit("setFormMode", VIEW_MODE);
			this.header = { Authorization: "Bearer " + this.token },
			this.fetchData();
			this.autocompleteValueLocal = this.autocompleteValue();
			this.headers.push({});
			this.headers.splice(-1);
			let search_data = "surat Gujarat";
			this.$store.dispatch("fetchAddressData", search_data);
		},
		methods: {
			debounceInput: _.debounce(function (e) {
				if (this.isDebounceCancelled) {
					this.isDebounceCancelled = false;
					return false;
				}
				this.fetchData();
			}, 500),
			getLocalisationFromDepot(item) {
				let localisation = "";
				let activeDepot = 0;
				if (item.depot) {
					item.depot.forEach(element => {
						if (element.status_id == 10171001 && element.deleted_at == null) { // depot stored
							activeDepot++;
							localisation += (element.localisation ? " " + element.localisation + "," : "");
						}
					});
					if (activeDepot > 0 && !localisation.trim()) {
						return this.$t("yes");
					}
					else if (activeDepot > 0 && localisation.trim()) {
						return localisation.slice(0, -1);
					}
					else {
						return "";
					}
				}
			},
			fetchData() {
				this.isLoading = true;
				const payload = {
					page: this.page,
					items_per_page: this.itemsPerPage,
					order_by: this.sortBy,
					order_direction: this.sortOrd,
					search: this.search
				};
				axios({ method: "get", url: API_BASE_URL + "/vehicle_rentals", headers: this.header, params: payload })
					.then(data => {
						console.log("client", data.data);
						this.vehicles = data.data.data;
						this.vehicles.forEach(vehicle => {
							vehicle.is_leasing = vehicle.is_leasing == 1 ? true : false;
						});
						this.totalItems = data.data.total;
						this.isLoading = false;
					}).catch((error) => {
						console.log(error.response.data.error);
						this.$noty.success(this.$t("vehicle.something_went_wrong_while_fetching_data"), { timeout: 5000, layout: "bottomLeft" });
					});
			},
			updatePagination(page) {
				this.page = page;
				this.fetchData();
			},
			updateItemsPerPage(itemsPerPage) {
				console.log(itemsPerPage);
				itemsPerPage == -1 ? this.itemsPerPage = this.totalItems : this.itemsPerPage = itemsPerPage;
				this.fetchData();
			},
			rentalHistory(item) {

				this.$store.commit("setVehicleId", item.id);

				this.is_reload_component = false;

				this.$nextTick(() => {
					this.is_reload_component = true;
				})
			},
			updateSort(sortBy) {
				if (typeof sortBy == "object") {
					this.sortBy = sortBy[0];
				}
				else {
					this.sortBy = sortBy;
				}
			},
			updateSortOrder(sortDesc) {
				console.log("data => ", sortDesc);
				if (typeof sortDesc == "object") {
					!sortDesc[0] ? this.sortOrd = "ASC" : this.sortOrd = "DESC";
				}
				else {
					!sortDesc ? this.sortOrd = "ASC" : this.sortOrd = "DESC";
				}
			}
		},
		computed: {
			headers() {
				return [
					{ text: this.$t("vehicles.id"), value: "id", sortable: true },
					{ text: "", value: "id", sortable: false, width: "10" },
					{ text: this.$t("vehicles.registration_number"), value: "registration_number", sortable: true },
					{ text: this.$t("vehicles.client_name"), value: "client_name", sortable: false },
					{ text: this.$t("vehicles.brand_name"), value: "brand_name", sortable: false },
					{ text: this.$t("vehicles.model"), value: "model", sortable: true },
					// { text: this.$t('vehicles.vin'), value: 'vin', sortable: true},
					{ text: this.$t("vehicles.is_leasing"), value: "is_leasing", sortable: true },
					{ text: this.$t("vehicles.is_for_rent"), value: "is_for_rent", sortable: true },
					{ text: this.$t("vehicles.driver_name"), value: "driver_name", sortable: false },
					// { text: this.$t('vehicles.hotel'), sortable: false },
					// { text: this.$t('vehicles.note'), value: 'note', sortable: true }
				];
			}
		},
		watch: {
			sortOrd: {
				handler: function () {
					this.fetchData();
				}
			},
			sortBy: {
				handler: function () {
					this.fetchData();
				}
			}
		},
		components: { History, "filter-by-registration-number": FilterByRegistrationNumber }
	}
</script>
<style scoped>
@media only screen and (max-width: 767px) {
.listing-wrp{border-bottom: 1px solid rgba(0, 0, 0, 0.42);margin-bottom: 30px;}
}


</style>