<template>
  <v-row justify="center" class="mt-10 loginbox">
    <v-col cols="12" sm="10" md="6" lg="4">
      <v-card ref="form" class="box-shad-none card-authenticated-wrapper">
        <v-card-text class="pl-0 pr-0 pt-0">
          <v-card-title class="pl-3 pr-3 mb-0 card-title-heading">
            <h3 class="text-white">{{ $t("login.title") }}</h3>
          </v-card-title>
            <v-progress-linear :active="isLoading" indeterminate color="primary"></v-progress-linear>
          <form class="pl-3 pr-3 pt-3">
            <div v-for="alert in alerts" :key="alert" :hidden="!alert.show">
              <v-alert :type="alert.type" dismissible>
                {{ alert.msg }}
              </v-alert>
            </div>
            <v-text-field
              v-model="email"
              :label="$t('login.email')"
              :error-messages="$store.state.validation ?  returnErrorMessage(
                  [!$v.email.required,this.$t('login.email_required')],
                  [!$v.email.email, this.$t('login.email_required')]
              ) : []"
              required
              v-on:keydown.enter.prevent="submitLogin()"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :label="$t('login.password')"
              :error-messages="$store.state.validation ?  returnErrorMessage(
                  [!$v.password.required,this.$t('login.password_required')],
                  [!$v.password.minLength, this.$t('login.password_minimum_length')]
              ) : []"
              required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              v-on:keydown.enter.prevent="submitLogin()"
            ></v-text-field>
            <div class="text-right d-flex align-center justify-content-between">
              <v-checkbox
                v-model="chkRemember"
                :label="$t('login.rememberMe')"
                @change="onRememberme()"
              ></v-checkbox>
              <span class="forgot-password-txt">
                <a href="/forgot-password">{{ $t("login.forgotPassword") }}</a>
              </span
              >
            </div>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mr-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="submitLogin">
                {{ $t("login.title") }}
                <v-icon dark>mdi-login-variant</v-icon>
              </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import axios from '@/plugins/axios';
import { API_BASE_URL } from "@/config";

export default {
  mixins: [validationMixin],
  validations: {
    email: { 
      required, 
      email: (val) => email((val ?? "").toLowerCase()),
    },
    password: { required, minLength: minLength(5) }
  },

  data(){
    return {
      email: null,
      showPassword: false,
      password: null,
      chkRemember: false,
      updatedIP: null,
      alerts: [],
      isLoading: false,
    }
  },
  created () {
    if (this.$cookies.get('remember') == 'true') {
      this.email = this.$cookies.get("email")
      this.chkRemember = true;
    } else {
      this.$cookies.remove("email");
      this.$cookies.remove("password");
      this.$cookies.remove("remember");
    }
  },
  computed: {

  },

  methods: {
    submitLogin() {
      this.$store.commit("setValidation",true);
      if(this.password && this.email && (!this.$v.$invalid) ){
        this.isLoading = true
        const payload = {
          email: this.email,
          password: this.password
        }
        axios.post(API_BASE_URL + '/login', payload)
        .then(loginRes => {
              localStorage.setItem('data', JSON.stringify(loginRes.data.data));
              localStorage.setItem('accessToken', loginRes.data.data.access_token);
              localStorage.setItem('companyID', loginRes.data.data.userDetails.company_id);
              localStorage.setItem('loginUser', loginRes.data.data.userDetails.name);
              localStorage.setItem('loginUserId', loginRes.data.data.userDetails.id);
              localStorage.setItem('languageId', loginRes.data.data.userDetails.language_id);
              localStorage.setItem('currencyId', loginRes.data.data.userDetails.company.currency_id);
              localStorage.setItem('company_name', loginRes.data.data.userDetails.company.name);
              localStorage.setItem('company_properties', JSON.stringify(loginRes.data.data.companyProperties));
              localStorage.setItem('userRole', loginRes.data.data.userDetails.role.name);
              let applicationResources = [];
              loginRes.data.data.applicationResources.forEach(appresource => {
                applicationResources.push(appresource.application_resource.key);
              });
              localStorage.setItem('applicationResources', JSON.stringify(applicationResources));
              
            //   this.$store.state.AccessToken = loginRes.data.data.access_token;
            //   this.$store.state.companyId = loginRes.data.data.userDetails.company_id;
            //   this.$store.state.loginUser = loginRes.data.data.userDetails.name;
            //   this.$store.state.languageId = loginRes.data.data.userDetails.language_id;
            //   this.$store.state.currencyId = loginRes.data.data.userDetails.company.currency_id;
            //   this.$store.state.role = loginRes.data.data.userDetails.role.name;
            //   this.$i18n.locale = this.findCompanyLocale(loginRes.data.data.userDetails.language_id);
              // remember me
              if (this.chkRemember === true) {
                this.$cookies.set("email", this.email);
                this.$cookies.set("password", this.password);
                this.$cookies.set("remember", this.chkRemember);
              }
            //   this.$router.push({ name: 'Home' }).then(() => { this.$router.go() }).catch(() => {});
            window.location.href='/';
        }).catch((error) => {
              this.$noty.error(this.$t('login.text_messages.' + error.response.data.errors[0]), { timeout: 5000, layout: 'bottomLeft'});
        })
        .finally(() => {
          this.isLoading = false;
        });
      }
    },
    onRememberme () {
      this.chkRemember === true ? this.$cookies.set("remember", this.chkRemember) : this.$cookies.remove("remember");
      console.log(this.$cookies);
    }
  },
}
</script>
<style scoped>

</style>