<template>
    <v-card class="elevation-0">
        <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="search"
            class="mr-2 mt-n8 to-upper"
            :placeholder="$t('filter_customer.placeholder_search_customer')"
            append-icon="mdi-close"
            v-on:keydown="handleInput($event,'alphanumeric',20);"
            v-on:keydown.enter.prevent=""
            @input="debounceInput"
            @click:append="search = null;getClientVehicles()"
            :outlined="is_boxes"
            :dense="is_boxes"
        >
        </v-text-field>
        <v-card class="ml-n5 number-plate-area elevation-0">
                <v-list two-line>
                    <v-list-item-group
                        v-model="selected_index"
                        return-object
                        active-class="selected_customer"
                    >
                        <template v-for="(item, index) in client_vehicles">
                            <v-list-item :key="item.title">
                                <template >
                                    <v-list-item-content>
                                        <!-- <template v-if="item" v-slot:prepend>
                                            
                                        </template> -->
                                        <v-list-item-title>
                                            <template v-if="item.is_out_of_circulation">
                                                <v-tooltip top z-index="9999">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="ma-0 mr-1" color="warning"  v-bind="attrs" v-on="on">
                                                            mdi-car-arrow-right
                                                        </v-icon>
                                                    </template>
                                                    {{ $t('vehicle_is_out_of_circulation') }}
                                                </v-tooltip>
                                            </template>
                                            {{ item.registration_number }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                            v-if="item.client"
                                            class="text--primary"
                                            v-text="makeClientName(item)"
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>

                            <v-divider
                                v-if="index < client_vehicles.length - 1"
                                :key="index"
                            ></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
        </v-card>
    </v-card>
</template>
<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import {API_BASE_URL} from "@/config";

export default {
    name:"FilterCustomer",
    props:{
        selected_vehicle: [ Object ]
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            token: this.$store.state.AccessToken,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            autocompleteValueLocal: 'off',
            client_vehicles:{},
            search:null,
            selected_index: null,
            isDebounceCancelled: false
        }
    },
    mounted(){
        this.header = { Authorization: 'Bearer ' + this.token };
        this.autocompleteValueLocal = this.autocompleteValue();
        this.getClientVehicles(true);
    },
    methods:{
        makeClientName(item){
            return (item.client.name ?? '') + ' ' + (item.client.first_name ?? '');
        },
        debounceInput: _.debounce(function (e) {
            if(this.isDebounceCancelled){
                this.isDebounceCancelled = false;
                return false;
            }
            this.getClientVehicles();
        }, 500),
        getClientVehicles(autoSelectFirst = false) {
            this.$store.state.isLoading = true;
            this.selected_index = null;
            axios.get(API_BASE_URL + '/client_vehicles' + 
                "?page=1" +
                "&items_per_page=20" +
                (this.search ? ('&search=' + this.search) : '')
                ,{headers: this.header}
            )
            .then(({ data }) => {
                console.log(data.data,"client vehicles");
                this.client_vehicles = data.data;
                if(autoSelectFirst){
                    this.selected_index = 0;
                }
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            })
            .finally(() => {
                this.$store.state.isLoading = false;
            });
        },
    },
    watch:{
        selected_index(){
            console.log("selected index changed");
            this.$emit('update:selected_vehicle', this.client_vehicles[this.selected_index]);
        }
    }
}
</script>
<style scoped>
    .number-plate-area{
        max-height: 82vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .selected_customer{
        background-color: rgb(230, 230, 230) !important;
    }
</style>