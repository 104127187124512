<template>
    <v-row>
        <v-col>
            <v-row>
                <v-spacer></v-spacer>
                <v-btn
                    id="top-create"
                    class="ma-8"
                    color="primary"
                    @click="addEditAddressModel = true"
                >
                    {{ $t("nav_buttons.add") }}
                    <v-icon dark right> mdi-plus </v-icon>
                </v-btn>
                <!-- <v-col class="pa-0 pt-4 ma-0" cols="12" sm="3">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        class="mr-3 to-upper"
                        :label="$t('addresses.search')"
                        @input="debounceInput"
                        prepend-icon="mdi-magnify"
                        v-bind:clearable="true"
                        clear-icon="mdi-close"
                        v-model="search"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                </v-col> -->
            </v-row>
            <v-row>
                <v-data-table
                    :footer-props="{
                        'items-per-page-options': dataTableOptions,
                    }"
                    fixed-header
                    :headers="headers"
                    :options="options"
                    :server-items-length="totalItems"
                    :loading="isLoading"
                    :loading-text="this.$t('loading')"
                    class="elevation-0"
                    :height="'calc(-600px + 100vh)'"
                    style="width: 100%"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :items-per-page="itemsPerPage"
                    item-key="identifier"
                    @update:sort-by="updateSort"
                    @update:sort-desc="updateSortOrder"
                    @update:page="updatePagination"
                    @update:items-per-page="updateItemsPerPage"
                    :items="addresses"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ item.address.street }}
                            </td>
                            <td>
                                {{ item.address.zip }}
                            </td>
                            <td>
                                <template v-if="item.address.city">
                                    {{ item.address.city.name }}
                                </template>
                            </td>
                            <td>
                                {{ item.address.county }}
                            </td>
                            <td>
                                <template v-if="item.address.city">
                                    {{ item.address.city.country.key }}
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td>
                                <v-icon
                                    v-if="item.is_default"
                                    class="mr-1"
                                    color="success"
                                >
                                    mdi-check
                                </v-icon>
                            </td>
                            <td>
                                <v-btn
                                    class="ma-2 text-white-color"
                                    color="primary"
                                    small
                                    @click="editAddress(item)"
                                >
                                    {{ $t("edit") }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-row>
        </v-col>
        <v-dialog
            persistent
            @click:outside="cancelAddress()"
            @keydown.esc="cancelAddress()"
            v-model="addEditAddressModel"
            min-width="600"
            max-width="1024"
        >
            <v-card>
                <v-card-title class="text-title">
                    {{
                        selectedAddressForEdit
                            ? $t("addresses.edit_title")
                            : $t("addresses.add_title")
                    }}
                </v-card-title>

                <v-card-text class="dialog-text text-content" color="black">
                    <v-row>
                        <v-col class="pt-0 pb-0" cols="4">
                            <v-combobox
                                class="to-upper"
                                :autocomplete="autocompleteValueLocal"
                                @input.native="fetchAddressSuggListDebounced()"
                                v-model="address"
                                :items="fetchedAddresses"
                                item-value="id"
                                item-text="street"
                                :label="$t('billings.address')"
                                :search-input.sync="search_address"
                                :loading="isLoadingSearchAddress"
                                v-on:keydown.enter.prevent=""
                                @change="$emit('update:comp_address', address)"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-combobox>
                        </v-col>
                        <v-col class="pt-0 pb-0">
                            <v-text-field
                                class="to-upper"
                                :autocomplete="autocompleteValueLocal"
                                v-model="zipcode"
                                :label="$t('billings.zip_code')"
                                v-on:keydown.enter.prevent=""
                                v-on:keydown="
                                    handleInput($event, 'alphanumeric', 10)
                                "
                                :error-messages="
                                    $store.state.validation &&
                                    address &&
                                    !$v.zipcode.required
                                        ? [
                                              this.$t(
                                                  'billings.text_messages.zipcode_required'
                                              ),
                                          ]
                                        : []
                                "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pt-0 pb-0">
                            <v-combobox
                                class="to-upper"
                                :autocomplete="autocompleteValueLocal"
                                @input.native="fetchCitySuggListDebounced()"
                                v-model="city"
                                :items="cities"
                                item-value="id"
                                item-text="name"
                                :label="$t('billings.city')"
                                :search-input.sync="search_city"
                                :loading="isLoadingSearchCity"
                                v-on:keydown.enter.prevent=""
                                v-on:keydown="handleInput($event, 'alpha', 50)"
                                :error-messages="
                                    $store.state.validation &&
                                    address &&
                                    !$v.city.required
                                        ? [
                                              this.$t(
                                                  'billings.text_messages.city_required'
                                              ),
                                          ]
                                        : []
                                "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-combobox>
                        </v-col>
                        <v-col class="pt-0 pb-0">
                            <v-combobox
                                class="to-upper"
                                :autocomplete="autocompleteValueLocal"
                                @input.native="fetchCountySuggListDebounced()"
                                v-model="county"
                                :items="counties"
                                item-value="county"
                                item-text="county"
                                :label="$t('billings.county')"
                                :search-input.sync="search_county"
                                :loading="isLoadingSearchCounty"
                                v-on:keydown="handleInput($event, 'alpha', 50)"
                                v-on:keydown.enter.prevent=""
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-combobox>
                        </v-col>
                        <v-col class="pt-0 pb-0">
                            <v-autocomplete
                                class="to-upper"
                                :autocomplete="autocompleteValueLocal"
                                flat
                                :items="countries"
                                v-model="country"
                                :label="$t('billings.country')"
                                item-value="id"
                                item-text="key"
                                v-on:keydown.enter.prevent=""
                                :loading="isLoadingSearchCountry"
                                :error-messages="
                                    $store.state.validation &&
                                    address &&
                                    !$v.country.required
                                        ? [
                                              this.$t(
                                                  'billings.text_messages.country_required'
                                              ),
                                          ]
                                        : []
                                "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                hide-no-data
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="pt-0 pb-0">
                            <v-checkbox
                                class="ma-0 pa-0 pt-4"
                                v-model="is_default"
                                :label="$t('addresses.is_default')"
                                hide-details="auto"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pr-2">
                    <v-spacer></v-spacer>
                    <v-btn class="px-4" color="error" @click="deleteAddress()">
                        {{ $t("nav_buttons.delete") }}
                        <v-icon right>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="px-4"
                        color="grey lighten-2"
                        @click="cancelAddress()"
                    >
                        {{ $t("addresses.cancel_btn_text") }}
                        <v-icon right>
                            mdi-keyboard-return
                        </v-icon>
                    </v-btn>
                    <v-btn
                        color="success"
                        dark
                        class="px-4"
                        @click="addEditAddress()"
                    >
                        {{ $t("save") }}
                        <v-icon dark right> mdi-content-save </v-icon>
                    </v-btn>
                </v-card-actions>

                <confirmation-model
                    :showDialog.sync="confirmationDialog"
                    :openedForOperation.sync="confirmationDialogOperation"
                    :text.sync="confirmationDialogConfirmationText"
                    :trueText.sync="confirmationDialogTrueText"
                    :falseText.sync="confirmationDialogFalseText"
                    @buttonClicked="confirmationButtonClicked"
                ></confirmation-model>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import axios from "axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import ConfirmationModel from "@/components/ConfirmationModel";
import { validationMixin } from "vuelidate";
export default {
    mixins: [validationMixin],
    name: "AddressTab",
    props: {
        client: [Object],
    },
    components: {
        ConfirmationModel,
    },
    validations: {
        address: {
            required,
        },
        zipcode: {
            required: requiredIf(function(model) {
                return this.address;
            }),
        },
        city: {
            required: requiredIf(function(model) {
                return this.address;
            }),
        },
        country: {
            required: requiredIf(function(model) {
                return this.address;
            }),
        },
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            addresses: [],
            alerts: [],
            search: "",
            isLoading: true,
            totalItems: 0,
            page: 1,
            lastPage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            options: {},
            sortBy: "",
            sortDesc: false,
            sortOrd: "ASC",
            sort: {},
            showAlert: false,
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            autocompleteValueLocal: "off",
            isDebounceCancelled: false,
            addEditAddressModel: false,
            fetchedAddresses: [],
            isLoadingSearchAddress: false,
            isLoadingSearchCity: false,
            isLoadingSearchCounty: false,
            isLoadingSearchCountry: false,
            zipcode: null,
            address: null,
            search_address: null,
            cities: [],
            city: null,
            search_city: null,
            counties: [],
            county: null,
            search_county: null,
            countries: [],
            country: null,
            search_country: null,
            selectedAddressForEdit: null,
            is_default: false,

            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
        };
    },

    created() {
        (this.header = { Authorization: "Bearer " + this.token }),
            this.fetchData();
        this.autocompleteValueLocal = this.autocompleteValue();
    },
    mounted() {
        this.getCountries();
    },
    methods: {
        callDeleteAddress() {
            this.$store.state.isLoading = true;
            let data = {
                is_private : this.client.representative_company_id ? false : true
            };
            axios
                .delete(API_BASE_URL + "/client_addresses/" + this.client.id + "/" + this.selectedAddressForEdit.address_id, {
                    data: data,
                    headers: this.header,
                })
                .then((data) => {
                    this.$noty.success(
                        this.$t("addresses.text_messages.deleted_successfully"),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.fetchData();
                })
                .catch((error) => {
                    this.$noty.error(error, {
                        timeout: 5000,
                        layout: "bottomLeft",
                    });
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                    this.cancelAddress();
                });
        },
        deleteAddress() {
            this.confirmationDialogConfirmationText = this.$t(
                "addresses.delete_address_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("addresses.cancel");
            this.confirmationDialogTrueText = this.$t(
                "addresses.delete_address"
            );
            this.confirmationDialogOperation = "delete_address";
            this.confirmationDialog = true;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        cancelAddress() {
            this.selectedAddressForEdit = null;
            this.addEditAddressModel = false;

            this.address = null;
            this.city = null;
            this.county = null;
            this.country = null;
            this.zipcode = null;
            this.is_default = false;
        },
        editAddress(item) {
            this.selectedAddressForEdit = item;
            this.addEditAddressModel = true;
        },
        addEditAddress() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.$v.$invalid) {
                this.$noty.warning(this.$t("please_fill_form_correctly"), {
                    timeout: 5000,
                    layout: "bottomLeft",
                });
                return false;
            } else {
                if (this.selectedAddressForEdit) {
                    this.updateAddress(this.makeNewClientAddress());
                } else {
                    this.addAddress(this.makeNewClientAddress());
                }
            }
        },
        addAddress(data) {
            this.$store.state.isLoading = true;
            axios
                .post(
                    API_BASE_URL + "/client_addresses/" + this.client.id,
                    data,
                    { headers: this.header }
                )
                .then((response) => {
                    this.$noty.success(
                        this.$t(
                            "addresses.text_messages.address_added_successfully"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.fetchData();
                })
                .catch((error) => {
                    this.$noty.error(
                        this.$t(
                            "clients.text_messages." +
                                error.response.data.errors[0]
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                    this.cancelAddress();
                });
        },
        updateAddress(data) {
            axios
                .put(
                    API_BASE_URL +
                        "/client_addresses/" +
                        this.client.id +
                        "/" +
                        this.selectedAddressForEdit.address_id,
                    data,
                    {
                        headers: this.header,
                    }
                )
                .then((response) => {
                    this.$noty.success(
                        this.$t(
                            "addresses.text_messages.address_updated_successfully"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.fetchData();
                })
                .catch((error) => {
                    this.$noty.error(
                        this.$t(
                            "clients.text_messages." +
                                error.response.data.errors[0]
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                    this.cancelAddress();
                });
        },
        makeNewClientAddress() {
            return {
                zipcode: this.zipcode ?? null,
                city: this.city
                    ? typeof this.city == "object"
                        ? parseInt(this.city.id)
                        : this.city
                    : null,
                county: this.county
                    ? typeof this.county == "object"
                        ? this.county.county
                        : this.county
                    : null,
                country: this.country
                    ? typeof this.country == "object"
                        ? this.country.id
                        : this.country
                    : null,
                address: this.address
                    ? typeof this.address == "object"
                        ? parseInt(this.address.id)
                        : this.address
                    : null,
                is_default: this.is_default ? true : false,
            };
        },

        getCountries() {
            axios
                .get(API_BASE_URL + "/countries", { headers: this.header })
                .then(({ data }) => {
                    this.countries = data.data;
                    this.shipping_countries = data.data;
                    this.countries.forEach((element) => {
                        if (element.is_default == 1) {
                            this.defaultCountry = element;
                        }
                    });
                    this.country = this.defaultCountry.id;
                    this.shipping_country = this.defaultCountry.id;
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                });
        },
        fetchCountySuggListDebounced() {
            if (!this.search_county || typeof this.search_county == "object") {
                return;
            }

            this.isLoadingSearchCounty = true;
            axios
                .get(
                    API_BASE_URL +
                        "/counties?" +
                        (this.search_county
                            ? "&search=" + this.search_county
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.counties = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchCounty = false;
                })
                .finally(() => (this.isLoadingSearchCounty = false));
        },
        fetchAddressSuggListDebounced() {
            if (
                !this.search_address ||
                typeof this.search_address == "object"
            ) {
                return;
            }
            this.isLoadingSearchAddress = true;
            axios
                .get(
                    API_BASE_URL +
                        "/addresses?" +
                        (this.search_address
                            ? "&search=" + this.search_address
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.fetchedAddresses = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchAddress = false;
                })
                .finally(() => (this.isLoadingSearchAddress = false));
        },
        fetchCitySuggListDebounced() {
            if (!this.search_city || typeof this.search_city == "object") {
                return;
            }

            this.isLoadingSearchCity = true;
            axios
                .get(
                    API_BASE_URL +
                        "/cities?" +
                        (this.search_city ? "&search=" + this.search_city : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.cities = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchCity = false;
                })
                .finally(() => (this.isLoadingSearchCity = false));
        },
        fetchCountrySuggListDebounced() {
            if (
                !this.search_country ||
                typeof this.search_country == "object"
            ) {
                return;
            }
            axios
                .get(
                    API_BASE_URL +
                        "/countries?" +
                        (this.search_country
                            ? "&search=" + this.search_country
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.countries = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchCountry = false;
                })
                .finally(() => (this.isLoadingSearchCountry = false));
        },
        debounceInput: _.debounce(function(e) {
            if (this.isDebounceCancelled) {
                this.isDebounceCancelled = false;
                return false;
            }
            this.fetchData();
        }, 500),
        fetchData() {
            this.isLoading = true;

            axios({
                method: "get",
                url: API_BASE_URL + "/client_addresses/" + this.client.id,
                headers: this.header,
            })
                .then((resAddresses) => {
                    this.addresses = resAddresses.data.data;
                    this.totalItems = resAddresses.data.data.length;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.$noty.success(
                        this.$t(
                            "schedulestab.something_went_wrong_while_fetching_data"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                });
        },
        updatePagination(page) {
            this.page = page;
            this.fetchData();
        },
        updateItemsPerPage(itemsPerPage) {
            console.log(itemsPerPage);
            itemsPerPage == -1
                ? (this.itemsPerPage = this.totalItems)
                : (this.itemsPerPage = itemsPerPage);
            this.fetchData();
        },
        updateSort(sortBy) {
            if (typeof sortBy == "object") {
                this.sortBy = sortBy[0];
            } else {
                this.sortBy = sortBy;
            }
            this.fetchData();
        },
        updateSortOrder(sortDesc) {
            console.log("data => ", sortDesc);
            if (typeof sortDesc == "object") {
                !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            } else {
                !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            }
            console.log("data => ", this.sortOrd);
            this.fetchData();
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t("addresses.street"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.zipcode"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.city"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.county"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.country"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.is_default"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.action"),
                    value: "address",
                    sortable: false,
                },
            ];
        },
    },
    watch: {
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
                if (this.confirmationDialogOperation == "delete_address") {
                    if (this.confirmationDialogButtonClickedIs == true) {
                        this.callDeleteAddress();
                    }
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        selectedAddressForEdit(val) {
            if (val) {
                console.log("address", val);
                this.address = val.address;
                this.city = val.address.city;
                this.county = val.address.county;
                this.country = val.city.country_id;
                this.zipcode = val.address.zip;
                this.is_default = val.is_default;
            } else {
                this.address = null;
                this.city = null;
                this.county = null;
                this.country = null;
                this.zipcode = null;
                this.is_default = false;
            }
        },
    },
};
</script>
<style scoped></style>
