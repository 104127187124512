<template>                       
    <v-dialog v-model="modify_stock_dialog" @click:outside="closeTyreDialog()" @keydown.esc="closeTyreDialog()" persistent max-width="1024px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ modify_title }}</span>
            </v-card-title>
            <v-card-text @click="modify_data = true">
                <v-row>
                    <v-text-field  
                        flat
                        :autocomplete="autocompleteValueLocal"
                        v-model="localisation"
                        class="mr-2 to-upper"
                        :label="this.$t('tyre_hotel.add_modify_tyre.localisation')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                    <v-autocomplete
                        :autocomplete="autocompleteValueLocal"
                        flat
                        v-model="wheel_type"
                        :items="wheel_types"
                        :label="$t('tyre_hotel.add_modify_tyre.wheel')"
                        class="mr-2 to-upper"
                        item-value="id"
                        item-text="text"
                        @click:append="wheel_type = null"
                        append-icon="mdi-close"
                        return-object
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-no-data
                    ></v-autocomplete>
                    <v-text-field  
                        flat
                        :autocomplete="autocompleteValueLocal"
                        v-model="note"
                        class="mr-2 to-upper"
                        :label="this.$t('tyre_hotel.add_modify_tyre.note')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                </v-row>
                <v-row class="mt-6">
                    <v-col cols="12" sm="1" class="ma-0 pa-0 mt-4 pl-3">
                        <v-row  class=" ">
                            <span style="font-size:18px">
                                    {{ $t('tyre_hotel.add_modify_tyre.front') }} 
                            </span>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="11" class="ma-0 pa-0 mt-n4">
                        <tyre-hotel-search-article
                                :class="front_selected_article ? 'articleset': 'articlenotset'"
                                @changeDialog="changeDialog()" 
                                :compSelectedArticleId.sync="frontParentSearchSelectedArticleId"
                                :internalStockOnly.sync="parentInternalStockOnly"
                                ref="frontSearchArticle"
                        ></tyre-hotel-search-article>
                        <div class="v-messages theme--light error--text mt-n8"> 
                            {{ $store.state.validation && !$v.frontParentSearchSelectedArticleId.required ? this.$t('tyre_hotel.add_modify_tyre.front_article_required') : '' }}
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-4 mb-4">
                    <v-col cols="12" sm="4" class="ml-8" align="right">
                            {{ $store.state.validation && !$v.atLeastOneShouldBeActive.required ? this.$t('tyre_hotel.add_modify_tyre.at_least_one_should_be_active') : '' }}
                        
                        <v-row class="mt-4">
                            <v-col>
                                <v-row>
                                    <v-col class="pa-0">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="front_left.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="front_left.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.front_left') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    <v-col class="pa-0" cols="12" sm="3">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: 0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_left.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 mr-2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation ?  returnErrorMessage(
                                                [!$v.front_left.mm.maxValue,this.$t('tyre_hotel.add_modify_tyre.mm_max_value')],
                                                [!$v.atLeastOneShouldBeActive.required, this.$t('tyre_hotel.add_modify_tyre.at_least_one_should_be_active')]
                                            ) : []"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="5" class="mt-4 mr-n4" align="right">
                                        <span style="font-size:initial"> {{ $t('tyre_hotel.add_modify_tyre.with_enjo') }} </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" align="right">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-4"
                                            v-model="front_left.with_enjo"
                                            label=" "
                                            hide-details="auto"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    
                                </v-row>
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_left.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_left.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="8" align="right">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="front_left.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="front_left.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-10">
                            <v-col>
                                <v-row>
                                    <v-col class="pa-0">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="back_left.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="back_left.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.rear_left') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    <v-col class="pa-0" cols="12" sm="3">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: 0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_left.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 mr-2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.back_left.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="5" class="mt-4 mr-n4" align="right">
                                        <span style="font-size:initial"> {{ $t('tyre_hotel.add_modify_tyre.with_enjo') }} </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" align="right">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-4"
                                            v-model="back_left.with_enjo"
                                            hide-details="auto"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_left.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_left.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="8" align="right">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="back_left.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="back_left.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-img
                            height="450"
                            width="230"
                            
                            src="@/assets/carimage.jpg"
                        ></v-img>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-row class="mt-4">
                            <v-col>
                                <v-row>
                                    <v-col class=" pa-0" cols="12" sm="9">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="front_right.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="front_right.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.front_right') }}</span>
                                            </v-btn>
                                        </v-btn-toggle>
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: -4px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_right.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 pt-n2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.front_right.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" align="right">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-2"
                                            v-model="front_right.with_enjo"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.with_enjo')"
                                            hide-details="auto"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_right.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_right.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="8" align="right">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="front_right.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="front_right.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-10">
                            <v-col>
                                <v-row>
                                    <v-col class=" pa-0" cols="12" sm="9">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="back_right.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="back_right.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.rear_right') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: -3px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_right.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 mr-2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.back_right.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" align="left">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-3"
                                            v-model="back_right.with_enjo"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.with_enjo')"
                                            hide-details="auto"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-2">
                                    <v-col cols="12" sm="4" align="left">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_right.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="lfet">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_right.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="8" align="left">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="back_right.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="back_right.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-6">
                    <v-col cols="12" sm="1" class="ma-0 pa-0 mt-4 pl-3">
                        <v-row  class=" ">
                            <span style="font-size:18px">
                                    {{ $t('tyre_hotel.add_modify_tyre.back') }} 
                            </span>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="11" class="ma-0 pa-0 mt-n4">
                        <tyre-hotel-search-article
                                :class="front_selected_article ? 'articleset': 'articlenotset'"
                                @changeDialog="changeDialog()" 
                                :compSelectedArticleId.sync="backParentSearchSelectedArticleId"
                                :internalStockOnly.sync="parentInternalStockOnly"
                                ref="frontSearchArticle"
                        ></tyre-hotel-search-article>
                        <div class="v-messages theme--light error--text mt-n8"> 
                            {{ $store.state.validation && !$v.backParentSearchSelectedArticleId.required ? this.$t('tyre_hotel.add_modify_tyre.back_article_required') : '' }}
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn class="px-4" color="grey lighten-2"  @click="closeTyreDialog(false)">
                    {{ $t('tyre_hotel.add_modify_tyre.cancel') }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn class="px-4" color="success" @click="saveTyreDialog()">
                    {{ $t('tyre_hotel.add_modify_tyre.save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped>
    .right-aligned-input >>> input {
        text-align: right
    }
    .text-red >>> input{
        color: red !important;
    }
    .border-error{
        border: 1px solid red;
    }
</style>
<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import {API_BASE_URL} from "@/config";
import TyreHotelSearchArticle from "@/components/TyreHotel/SearchArticle";
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf, maxValue, phoneNum, numeric ,minLength, maxLength, alpha } from 'vuelidate/lib/validators'

export default {
    name: "DepoAddModifyTyre",
    mixins: [validationMixin],
    validations: {
        // wheel_type: { 
        //     required
        // },
        front_left:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        front_right:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        back_left:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        back_right:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        frontParentSearchSelectedArticleId: {
            required: requiredIf(function () {
                return this.front_left.active == 0 || this.front_right.active == 0;
            }),
        },
        backParentSearchSelectedArticleId: {
            required: requiredIf(function () {
                return this.back_left.active == 0 || this.back_right.active == 0;
            }),
        },
        atLeastOneShouldBeActive: {
            required: requiredIf(function () {
                return this.front_left.active == undefined && this.front_right.active == undefined && this.back_left.active == undefined && this.back_right.active == undefined;
            }),
        },
    },
    components:{
        "tyre-hotel-search-article":TyreHotelSearchArticle
    },
    props: {
        depotDetails:   [ Object ],
        vehicleDriverDetails:  [ Object ]
    },
    data() {
        return {
            note: null,
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            modify_stock_dialog :   true,
            autocompleteValueLocal : 'off',
            localisation:   null,
            depotDetailStatuses: [],
            wheel_types:[],
            wheel_type: null,
            front_selected_article: null,
            frontParentSearchSelectedArticleId: null,
            backParentSearchSelectedArticleId: null,
            parentInternalStockOnly: false,
            front_left:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            front_right:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            back_left:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            back_right:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            token: this.$store.state.AccessToken,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            tempDepotDetails: {},
            modify_data: false,
            modify_title: this.$t('tyre_hotel.add_modify_tyre.title'),
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.header = { Authorization: 'Bearer ' + this.token };
        this.getDepotDetailStatuses();
        this.getWheelMaterial();
        this.updateValues();
    },
    watch:{
        depotDetails:{
            handler: function(){
                this.updateValues();
            }
        },
        frontParentSearchSelectedArticleId(val){
            this.backParentSearchSelectedArticleId = val;
        },
        'front_left.mm':_.debounce(function (e) {
            this.front_left.active = 0;
            if(this.modify_data){
                this.front_right.mm = this.front_left.mm;
                this.back_left.mm = this.front_left.mm;
                this.back_right.mm = this.front_left.mm;
            }
        }, 200),
        'front_left.with_enjo':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.with_enjo = this.front_left.with_enjo;
                this.back_left.with_enjo = this.front_left.with_enjo;
                this.back_right.with_enjo = this.front_left.with_enjo;
            }
        }, 200),
        'front_left.pressure':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.pressure = this.front_left.pressure;
                this.back_left.pressure = this.front_left.pressure;
                this.back_right.pressure = this.front_left.pressure;
            }
        }, 200),
        'front_left.couple':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.couple = this.front_left.couple;
                this.back_left.couple = this.front_left.couple;
                this.back_right.couple = this.front_left.couple;
            }
        }, 200),
        'front_right.mm':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.active = 0;
            }
        }, 200),
        'back_left.mm':_.debounce(function (e) {
            this.back_left.active = 0;
            if(this.modify_data){
                this.back_right.mm = this.back_left.mm;
            }
        }, 200),
        'back_left.with_enjo':_.debounce(function (e) {
            if(this.modify_data){
                this.back_right.with_enjo = this.back_left.with_enjo;
            }
        }, 200),
        'back_left.pressure':_.debounce(function (e) {
            if(this.modify_data){
                this.back_right.pressure = this.back_left.pressure;
            }
        }, 200),
        'back_left.couple':_.debounce(function (e) {
            if(this.modify_data){
                this.back_right.couple = this.back_left.couple;
            }
        }, 200),
        'back_right.mm':_.debounce(function (e) {
            this.back_right.active = 0;
        }, 200),
    },
    methods: {
        updateValues(){
            if(this.depotDetails && Object.keys(this.depotDetails).length > 0){
                this.modify_title = this.$t('tyre_hotel.add_modify_tyre.edit_title');
                this.tempDepotDetails = Object.assign({}, this.depotDetails);
                this.localisation = this.tempDepotDetails.localisation;
                this.note = this.tempDepotDetails.note;
                if(this.tempDepotDetails.details.length > 0){
                    // assign wheel type
                    this.wheel_type  = this.tempDepotDetails.details[0].rimtype;
                    // assign article and positions
                    this.tempDepotDetails.details.forEach(positionArticle => {
                        if(positionArticle.position_id == 10281002){ // front left
                            positionArticle.article.description = positionArticle.article.title_description[0].description;
                            this.frontParentSearchSelectedArticleId = positionArticle.article;
                            this.front_left.active = 0;
                            this.front_left.mm = positionArticle.mm;
                            this.front_left.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.front_left.pressure =  positionArticle.pressure;
                            this.front_left.couple =  positionArticle.couple;
                            this.front_left.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281001){ // front right
                            positionArticle.article.description = positionArticle.article.title_description[0].description; 
                            this.frontParentSearchSelectedArticleId = positionArticle.article;

                            this.front_right.active = 0;
                            this.front_right.mm = positionArticle.mm;
                            this.front_right.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.front_right.pressure =  positionArticle.pressure;
                            this.front_right.couple =  positionArticle.couple;
                            this.front_right.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281003){ // back right
                            positionArticle.article.description = positionArticle.article.title_description[0].description; 
                            this.$nextTick(() => {
                                this.backParentSearchSelectedArticleId = positionArticle.article;
                            });
                            
                            this.back_right.active = 0;
                            this.back_right.mm = positionArticle.mm;
                            this.back_right.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.back_right.pressure =  positionArticle.pressure;
                            this.back_right.couple =  positionArticle.couple;
                            this.back_right.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281004){ // back left
                            positionArticle.article.description = positionArticle.article.title_description[0].description; 
                            this.$nextTick(() => {
                                this.backParentSearchSelectedArticleId = positionArticle.article;
                            });
                            

                            this.back_left.active = 0;
                            this.back_left.mm = positionArticle.mm;
                            this.back_left.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.back_left.pressure =  positionArticle.pressure;
                            this.back_left.couple =  positionArticle.couple;
                            this.back_left.status =  positionArticle.article_status;
                        }
                        
                    });
                }
            }
        },
        closeTyreDialog(data) {
            this.modify_stock_dialog = false;
            this.$emit('modifyClose', false, data);
            
        },
        saveTyreDialog(){
            this.$store.commit("setValidation",true);
            const payload = {
                vehicle_id:     this.vehicleDriverDetails.id || this.vehicleDriverDetails.vehicle.vehicle_id,
                client_id:      this.vehicleDriverDetails.client_id || this.vehicleDriverDetails.client.client_id,
                localisation:   this.localisation,
                note:           this.note,
                wheel_type:     this.wheel_type ? this.wheel_type.id : null,
                front_left:{
                    active:     this.front_left.active,
                    mm:         this.front_left.mm,
                    with_enjo:  this.front_left.with_enjo,
                    pressure:   this.front_left.pressure,
                    couple:     this.front_left.couple,
                    status:     this.front_left.status ? this.front_left.status.id : null,
                    article_id: this.frontParentSearchSelectedArticleId ? this.frontParentSearchSelectedArticleId.id : null,
                },
                front_right:{
                    active:     this.front_right.active,
                    mm:         this.front_right.mm,
                    with_enjo:  this.front_right.with_enjo,
                    pressure:   this.front_right.pressure,
                    couple:     this.front_right.couple,
                    status:     this.front_right.status ? this.front_right.status.id : null,
                    article_id: this.frontParentSearchSelectedArticleId ? this.frontParentSearchSelectedArticleId.id : null,
                },
                back_left:{
                    active:     this.back_left.active,
                    mm:         this.back_left.mm,
                    with_enjo:  this.back_left.with_enjo,
                    pressure:   this.back_left.pressure,
                    couple:     this.back_left.couple,
                    status:     this.back_left.status ? this.back_left.status.id : null,
                    article_id: this.backParentSearchSelectedArticleId ? this.backParentSearchSelectedArticleId.id : null,
                },
                back_right:{
                    active:     this.back_right.active,
                    mm:         this.back_right.mm,
                    with_enjo:  this.back_right.with_enjo,
                    pressure:   this.back_right.pressure,
                    couple:     this.back_right.couple,
                    status:     this.back_right.status ? this.back_right.status.id : null,
                    article_id: this.backParentSearchSelectedArticleId ? this.backParentSearchSelectedArticleId.id : null
                },
            };
            if (this.$v.$invalid ){
                this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft'});
            }
            else{
                if(this.depotDetails && this.depotDetails.id){
                    this.updateDepot(payload,true);
                }
                else{
                    this.addDepot(payload, true);
                }
            }
        },
        addDepot(data, showMessage= true){
            return new Promise((resolve, reject) => {
                this.$store.state.isLoading = true;
                axios.post(API_BASE_URL + '/depots', data, {headers: this.header})
                .then(response => {
                    this.tempDepotDetails = response.data.data;
                    this.depotDetails = this.tempDepotDetails;

                    if(showMessage){
                        this.$noty.success( this.$t('tyre_hotel.add_modify_tyre.depot_added') , { timeout: 5000, layout: 'bottomLeft'});
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                    if(showMessage){
                        this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft'});
                    }
                })
                .finally(()=>{
                    this.$store.state.isLoading = false;
                    this.$store.commit("setValidation",false);
                    this.closeTyreDialog(this.depotDetails);
                });
            })
        },
        updateDepot(data, showMessage = true){
            return new Promise((resolve, reject) => {
                this.$store.state.isLoading = true;
                axios.put(API_BASE_URL + '/depots/' + this.depotDetails.id, data, {headers: this.header})
                .then(response => {
                    this.tempDepotDetails = response.data.data;
                    this.depotDetails = this.tempDepotDetails;
                    if(showMessage){
                        this.$noty.success( this.$t('tyre_hotel.add_modify_tyre.depot_updated') , { timeout: 5000, layout: 'bottomLeft'});
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                    if(showMessage){
                        this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft'});
                    }
                })
                .finally(()=>{
                    this.$store.state.isLoading = false;
                    this.$store.commit("setValidation",false);
                    this.closeTyreDialog(this.depotDetails);
                });
            })
        },
        getDepotDetailStatuses() {
            this.$store.state.isLoading = true
            axios.get(API_BASE_URL + '/codes?class_key=depot_details_statuses',{headers: this.header})
            .then(({data}) => {
                this.depotDetailStatuses = this.translateKeys(data.data, 'key', 'tyre_hotel.statuses');
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            })
            .finally(() => {
                this.$store.state.isLoading = false;
            });
        },
        getWheelMaterial() {
            this.$store.state.isLoading = true;
            axios.get(API_BASE_URL + '/codes?class_key=wheel_material',{headers: this.header})
            .then(({data}) => {
                this.wheel_types = this.translateKeys(data.data, 'key', 'tyre_hotel.wheel_materials');
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            })
            .finally(() => {
                this.$store.state.isLoading = false;
            });
        },
    },
}
</script>