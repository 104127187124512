export let API_BASE_URL = process.env.VUE_APP_APP_URL + '/api';
export const FAMILY_ARTICLE_CARS = 5;

export const RESET_MODE              = "000000000";
export const VIEW_MODE               = "000000001";
export const ADD_MODE                = "000000010";
export const UPDATE_MODE             = "000000011";
export const EDIT_MODE               = "000000100";
export const DELETE_MODE             = "000001000";
export const DETAILS_MODE            = "000010000";
export const JUST_CANCEL_MODE        = "000000111";
export const BILLING_MODE            = "000100000";
export const SCHEDULER_MODE          = "000110000";
export const SCHEDULER_EDIT_MODE     = "000111000";
export const SCHEDULER_LISTING_MODE  = "000111100";
export const RECEPTION_CREATE_MODE   = "000100001";
export const STOCK_LISTING           = "110000000";
export const TYRE_DEPOT_MODE         = "111000000";


export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [ 30, 60, 100 ];
