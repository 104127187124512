<template>
  <v-card flat>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="2">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="registrationNo"
            class="to-upper"
            name="registrationNo"
            v-on:keydown="handleInput($event,'alphanumeric',100);"
            :label="$t('vehicles.registration_number')"
            required
            :error-messages="$store.state.validation && !$v.registrationNo.required ? [this.$t('vehicles.text_messages.registration_number_required')] : [] "
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-model="selectedBrand"
            name="brand"
            :items="brands"
            item-text="name"
            class="to-upper"
            item-value="id"
            :label="$t('vehicles.brand')"
            append-icon="mdi-close"
            @click:append="selectedBrand = null"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="model"
            name="model"
            :label="$t('vehicles.model')"
            v-on:keydown="handleInput($event,'alphanumeric',80);"
            class="to-upper"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
              v-model="menuDtp"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="Regdate"
                  :label="$t('vehicles.registration_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append="Regdate = null"
                  append-icon="mdi-close"
                  class="mr-2 to-upper"
                  :outlined="is_boxes"
                  :dense="is_boxes"
              ></v-text-field>
              </template>
              <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  v-model="Regdate"
                  @input="menuDtp = false"
                  no-title 
                  scrollable
                  :locale="userLanguageId"
              ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="vin"
            class="to-upper"
            name="vin"
            v-on:keydown="handleInput($event,'alphanumeric',255);"
            :label="$t('vehicles.vin')"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="km"
            class="to-upper"
            v-on:keydown="handleInput($event,'alphanumeric',12);"
            :label="$t('vehicles.km')"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="power"
            class="to-upper"
            v-on:keydown="handleInput($event,'alphanumeric',12);"
            :label="$t('vehicles.power')"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        
      </v-row>
      <v-row>
        <v-col cols="12" sm="2">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="contract_number"
            class="to-upper"
            v-on:keydown="handleInput($event,'alphanumeric',80);"
            :label="$t('vehicles.contract_number')"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
              v-model="menu_contract_end_date"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="contract_end_date"
                  :label="$t('vehicles.contract_end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append="contract_end_date = null"
                  append-icon="mdi-close"
                  class="mr-2 to-upper"
                  :outlined="is_boxes"
                  :dense="is_boxes"
              ></v-text-field>
              </template>
              <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  v-model="contract_end_date"
                  @input="menu_contract_end_date = false"
                  no-title 
                  scrollable
                  :locale="userLanguageId"
              ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-model="fuel"
            :items="fuels"
            item-text="text"
            class="to-upper"
            item-value="id"
            :label="$t('vehicles.fuel')"
            append-icon="mdi-close"
            @click:append="fuel = null"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-model="transmission"
            :items="transmissions"
            item-text="text"
            class="to-upper"
            item-value="id"
            :label="$t('vehicles.transmission')"
            append-icon="mdi-close"
            @click:append="transmission = null"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-model="gearbox"
            :items="gearboxes"
            item-text="text"
            class="to-upper"
            item-value="id"
            :label="$t('vehicles.gearbox')"
            append-icon="mdi-close"
            @click:append="gearbox = null"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="cylinder_capacity"
            class="to-upper"
            v-on:keydown="handleInput($event,'int',11);"
            :label="$t('vehicles.cylinder_capacity')"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6" class="d-flex justify-content-between align-center">
          <v-combobox
              :autocomplete="autocompleteValueLocal"
              ref="clientName"
              v-model="company_client_name"
              :items="company_client_names"
              item-value="id"
              item-text="name"
              :label="$t('vehicles.client')"
              class="to-upper"
              required
              append-icon="mdi-close"
              v-on:keydown="handleInput($event,'alphanumeric',50);"
              :search-input.sync="search_client"
              no-filter
              v-on:keydown.enter.prevent=""
              @click:append="company_client_name = null"
              :error-messages="$store.state.validation && !$v.company_client_name.required ? [this.$t('vehicles.text_messages.client_required')] : [] "
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
          >
              <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                      <p class='client_name'>{{item.name}}</p>
                      <p class='client_detail' v-if="item.representative_company_id"> Company </p>
                      <p class='client_detail' v-else> Private </p>
                  </v-list-item-content>
              </template>
          </v-combobox>
          <v-btn class="ma-2 text-white-color"  color="primary" @click="openAddClientDialog">
            {{ $t('vehicles.add') }}
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6" class="d-flex justify-content-between align-center">
          <v-combobox
              :autocomplete="autocompleteValueLocal"
              ref="clientName"
              v-model="driver_name"
              :items="driver_names"
              item-value="id"
              item-text="name"
              :label="$t('vehicles.driver')"
              required
              class="to-upper"
              append-icon="mdi-close"
              v-on:keydown="handleInput($event,'alphanumeric',50);"
              :search-input.sync="search_driver"
              no-filter
              v-on:keydown.enter.prevent=""
              @click:append="driver_name = null"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
          >
              <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                      <p class='client_name'>{{item.name}}</p>
                      <p class='client_detail' v-if="item.representative_company_id"> Company </p>
                      <p class='client_detail' v-else> Private </p>
                  </v-list-item-content>
              </template>
          </v-combobox>
          <v-btn class="ma-2 px-4 text-white-color"  color="primary" @click="openAddDriverDialog">
            {{ $t('vehicles.add') }}
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" sm="2">
          <v-checkbox
            class="mt-6"
            v-model="is_out_of_circulation"
            :label="$t('vehicles.is_out_of_circulation')"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="1">
          <v-checkbox
            class="mt-6"
            v-model="is_leasing"
            :label="$t('vehicles.is_leasing')"
          ></v-checkbox>
        </v-col>
        <v-col  cols="12" sm="9">
          <v-autocomplete
            :autocomplete="autocompleteValueLocal"
            v-model="creditor"
            :items="creditors"
            item-text="creditor_company.name"
            item-value="creditor_id"
            :label="$t('vehicles.creditor')"
            v-on:keydown.enter.prevent=""
            clearable
            :outlined="is_boxes"
            hide-details="auto"
            :dense="is_boxes"
            class="to-upper"
            hide-no-data
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" sm="12">
          <v-textarea 
            :autocomplete="autocompleteValueLocal" 
            rows="2" 
            v-model="note"
            class="to-upper"
            v-on:keydown="handleInput($event,'alphanumeric',1000);"
            :label="$t('vehicles.note')"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="onSubmit()" id="submit-vehicle" class="d-none"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-vehicle"></v-btn>
    <v-btn @click="showAttachmentDialog()" class="d-none" id="add-attachment"></v-btn>
    <vehicles-page-tab v-if="vehicleId" :vehicle="vehicleData"/>
    <add-client-driver 
      v-if="addClientDriverDialog"
      :showDialog.sync="addClientDriverDialog"
      :create.sync="addClientDriverOperation"
      :defaultName.sync="defaultAddClientDriverName"
      @buttonClicked="addClientDriverDialogClose"
    />
    <attachment-dialog
        :showDialog.sync="attachmentDialog"
        :data="vehicleId"
        :resource="'vehicles'"
    ></attachment-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { validationMixin } from 'vuelidate'
import { required, numeric, email, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators'
import { helpers } from 'vuelidate/lib/validators'
const alpha = helpers.regex('alpha', /^[a-zA-Z0-9 ]*$/)
const phoneNum = helpers.regex('alpha', /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)
import { API_BASE_URL } from "@/config";
import { ADD_MODE } from "@/config";
import { DETAILS_MODE } from "@/config";
import { FAMILY_ARTICLE_CARS } from "@/config";
import VehiclesPageTab from '@/components/Vehicles/VehiclesPageTab';
import AddClientDriver from '@/components/Vehicles/AddClientDriver';
import ConfirmationModel from "@/components/ConfirmationModel";
import AttachmentDialog from "@/components/AttachmentDialog";
import { mdiCheck } from '@mdi/js'; 
export default {
  name:"EditVehicle",
  mixins: [validationMixin],
  components: {
    "vehicles-page-tab":VehiclesPageTab,
    "confirmation-model":	ConfirmationModel,
    "add-client-driver": AddClientDriver,
    "attachment-dialog": AttachmentDialog
  },
  validations: {
      registrationNo:{
          required
      },
      company_client_name:{
          required
      },
  },
  data () {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      registrationNo: '',
      model: '',
      selectedBrand: {},
      brands: [],
      vin: '',
      is_leasing: false,
      note: '',
      isEdit: false,
      vehicleId: this.$store.state.editId,
      Regdate: new Date().toISOString().substr(0, 10),
      menuDtp: false,
      selectedDriver: '',
      selectedClients: '',
      company_client_names:[],
      client: null,
      driver: null,
      company_client_name: null,
      driver_names: [],
      driver_name: null,
      search_client: null,
      search_driver: null,
      token: this.$store.state.AccessToken,
      header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
      autocompleteValueLocal : 'off',
      confirmationDialog: false,
      confirmationDialogConfirmationText:null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      addClientDriverDialog: false,
      addClientDriverOperation: null,
      defaultAddClientDriverName: null,
      nowDate: new Date().toISOString().slice(0,10),
      fuels: [],
      fuel: null,
      transmissions: [],
      transmission: null,
      gearboxes: [],
      gearbox: null,
      power: null,
      km: null,
      contract_end_date: new Date().toISOString().slice(0,10),
      contract_number: null,
      menu_contract_end_date: false,
      creditor: null,
      creditors: [],
      is_out_of_circulation: false,
      cylinder_capacity: null,
      vehicleData: null,
      attachmentDialog: false
    }
  },
  created () {
    this.header = { Authorization: 'Bearer ' + this.token }
    this.getFuels();
    this.getTransmission();
    this.getGearBox();
    this.getCreditors();
    this.$route.path == '/vehicles/edit' ? this.isEdit = true : this.isEdit = false
    if (this.isEdit) {
      this.$store.commit('setFormMode', DETAILS_MODE);
      this.fetchData();
    } else {
      this.$store.commit('setFormMode', ADD_MODE);
    }
    this.getBrands();
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  methods: {
        showAttachmentDialog(){
            this.attachmentDialog = true;
        },
      getCreditors(){
        this.$store.state.isLoading = true;
        axios.get(API_BASE_URL + '/creditors',{headers: this.header})
        .then(({data}) => {
            this.creditors = data.data;
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => (
            this.$store.state.isLoading = false
        ))
    },
    confirmationButtonClicked(buttonClicked){
        this.confirmationDialogButtonClickedIs = buttonClicked;
        this.confirmationDialog=false;
    },
    addClientDriverDialogClose(data){
        if(data){ // Client Added in last popup
          if( this.addClientDriverOperation=='client'){
            this.company_client_names = [data];
            this.company_client_name = data;
          }
          if( this.addClientDriverOperation=='driver'){
            this.driver_names = [data];
            this.driver_name = data;
          }
        }
        else{ // reset the values if its just search text because the form just cancelled
          if( this.addClientDriverOperation=='client'){
            this.company_client_name = typeof this.company_client_name == "object" ? this.company_client_name : null;
          }
          if( this.addClientDriverOperation=='driver'){
            this.driver_name = typeof this.driver_name == "object" ? this.driver_name : null;
          }
        }
        this.addClientDriverDialog=false;
        this.addClientDriverOperation=null;
    },
    openAddDriverDialog(){
      this.addClientDriverOperation='driver';
      this.addClientDriverDialog=true;
      this.defaultAddClientDriverName = this.driver_name ? "" : this.search_driver;
    },
    openAddClientDialog(){
      this.addClientDriverOperation='client';
      this.addClientDriverDialog=true;
      this.defaultAddClientDriverName = this.company_client_name ? "" : this.search_client;
    },
    deletePopUp(){
        this.confirmationDialogConfirmationText = this.$t("vehicles.DeleteConfirmation");
        this.confirmationDialogFalseText = this.$t("vehicles.Cancel");
        this.confirmationDialogTrueText = this.$t("vehicles.Delete");
        this.confirmationDialogOperation = 'delete';
        this.confirmationDialog = true;
    },
    onDelete(){
      console.log("delete clicked");
      this.deletePopUp();
    },
    getBrands() {
      if(FAMILY_ARTICLE_CARS){
        if(this.cachedData['brands'][FAMILY_ARTICLE_CARS] && this.cachedData['brands'][FAMILY_ARTICLE_CARS].length > 0){
          this.brands = this.cachedData['brands'][FAMILY_ARTICLE_CARS];
        }
        else{
          this.$store.state.isLoading = true;
          axios.get( API_BASE_URL + '/brands' + "?family=" + FAMILY_ARTICLE_CARS + "&data_paginated=0" ,{ headers: this.header })
          .then(({ data }) => {
            this.brands = data.data;
            this.cachedData['brands'][FAMILY_ARTICLE_CARS] = this.brands;
          })
          .catch(function (error) {

          })
          .finally(() =>{
            this.$store.state.isLoading = false;
          });
        }
      }
    },
    getFuels(){
      axios.get(API_BASE_URL + "/codes?class_key=vehicle_fuel", {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
      .then(({ data }) => {
        this.fuels =  this.translateKeys( data.data, "key", "vehicles.fuels");
      })
      .catch(function (error) {
        console.log("an error occured " + error);
      });
    },
    getTransmission(){
      axios.get(API_BASE_URL + "/codes?class_key=vehicle_transmission", {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
      .then(({ data }) => {
        this.transmissions = this.translateKeys( data.data, "key", "vehicles.transmissions");
      })
      .catch(function (error) {
        console.log("an error occured " + error);
      });
    },
    getGearBox(){
      axios.get(API_BASE_URL + "/codes?class_key=vehicle_ger_box", {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
      .then(({ data }) => {
        this.gearboxes = this.translateKeys( data.data, "key", "vehicles.gearboxes");
      })
      .catch(function (error) {
        console.log("an error occured " + error);
      });
    },
    fetchClientsListDebounced(){
        if(! this.search_client || typeof this.search_client == "object"){
            return ;
        }
        this.$store.state.isLoading = true;
        axios.get(API_BASE_URL + '/clients/search?' + (this.search_client ? ('&search=' + this.search_client) : '') ,{headers: this.header})
        .then(({data}) => {
            this.company_client_names = data.data;
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(()=>(
            this.$store.state.isLoading = false
        ))
    },
    fetchDriversListDebounced(){
        if(! this.search_driver || typeof this.search_driver == "object"){
            return ;
        }
        this.$store.state.isLoading = true;
        axios.get(API_BASE_URL + '/clients/search?' + 
          (this.search_driver ? ('&search=' + this.search_driver) : '')
          // "&driver=true" 
        ,{headers: this.header})
        .then(({data}) => {
            this.driver_names = data.data;
        })
        .catch((err) => {
          
        })
        .finally(() => (
            this.$store.state.isLoading = false
        ))
    },
    createVehicle (payload) {
      this.$store.state.isLoading = true;
      axios.post( API_BASE_URL + '/client_vehicles', payload, {headers: this.header})
      .then(response => {
        this.$noty.success( this.$t('vehicles.text_messages.vehicle_created_successfully'), { timeout: 5000, layout: 'bottomLeft'});
        this.$router.push('/vehicles').catch(() => {})
      })
      .catch((error) => {
        if(error.response.data.errors && error.response.data.errors[0]){
          this.$noty.error(this.$t('vehicles.text_messages.' + error.response.data.errors[0]), { timeout: 5000, layout: 'bottomLeft'});
        }
        else{
          this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft'});
        }
      })
      .finally(() => {
        this.$store.state.isLoading = false;
      })
    },
    editVehicle (payload) {
      this.$store.state.isLoading = true;
      axios.put( API_BASE_URL + '/client_vehicles/' + this.vehicleId, payload,{ headers: this.header})
      .then(response => {
        this.$noty.success( this.$t('vehicles.text_messages.vehicle_updated_successfully'), { timeout: 5000, layout: 'bottomLeft'});
        this.$router.push('/vehicles').catch(() => {});
      })
      .catch((error) => {
        this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft'});
      })
      .finally(() => {
        this.$store.state.isLoading = false;
      })
    },
    onSubmit () {
      this.$store.commit("setValidation",true); // enable validation
      this.company_client_name = typeof this.company_client_name == "object" ? this.company_client_name : null; // reset company client name if client is not choosen
      this.driver_name = typeof this.driver_name == "object" ? this.driver_name : null; // reset company client name if client is not choosen
      if(this.$v.$invalid) {
        this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft'});
      }
      else{
        if(this.isEdit) {
          this.editVehicle(this.makeSubmitData())
        } else {
          this.createVehicle(this.makeSubmitData())
        }
      }
    },
    makeSubmitData(){
      console.log("creditor id", typeof this.creditor == 'object');
      return {
        registration_number: this.registrationNo,
        brand_id: this.selectedBrand ? this.selectedBrand.id : null,
        model: this.model,
        registration_date: this.Regdate,
        vin: this.vin,
        client_id: this.company_client_name ? this.company_client_name.id : null,
        driver_id: this.driver_name ? this.driver_name.id : null,
        is_leasing: this.is_leasing ? 1 : 0,
        note: this.note,
        transmission_id: this.transmission ? (typeof this.transmission == 'object' ? this.transmission.id : this.transmission) : null,
        fuel_id: this.fuel ? (typeof this.fuel == 'object' ? this.fuel.id : this.fuel) : null,
        gearbox_id: this.gearbox ? (typeof this.gearbox == 'object' ? this.gearbox.id : this.gearbox) : null,
        km: this.km,
        power: this.power,
        contract_number: this.contract_number,
        contract_end_date: this.contract_end_date,
        creditor_id: this.creditor ? (typeof this.creditor == 'object' ? this.creditor.creditor_id : this.creditor) : null,
        cylinder_capacity: this.cylinder_capacity ?? null,
        is_out_of_circulation: this.is_out_of_circulation ?? null
      }
    },
    fetchData () {
      this.$store.state.isLoading = true
      axios.get(API_BASE_URL + '/client_vehicles/' + this.vehicleId, { headers: this.header})
      .then(res => {
        this.vehicleData = res.data;
        this.$store.state.isLoading = false
        this.id = res.data.id;
        this.registrationNo = res.data.registration_number;
        this.selectedBrand = res.data.brand;
        this.model = res.data.model;
        this.Regdate = res.data.registration_date;
        this.vin = res.data.vin;
        this.company_client_names = res.data.client ? [res.data.client] : [];
        this.company_client_name = res.data.client;
        this.driver_names = res.data.driver ? [res.data.driver] : []; 
        this.driver_name = res.data.driver;
        this.is_leasing = res.data.is_leasing;
        this.note = res.data.note;
        this.km = res.data.km;
        this.power = res.data.power;
        this.contract_number = res.data.contract_number;
        this.contract_end_date = res.data.contract_end_date;
        this.transmission = res.data.transmission;
        this.fuel = res.data.fuel;
        this.gearbox = res.data.gearbox;
        this.creditor = res.data.creditor_id;
        this.cylinder_capacity = res.data.cylinder_capacity;
        this.is_out_of_circulation = res.data.is_out_of_circulation;

      })
      .catch(() => {

      })
    },
    deleteVehicle(){
      this.$store.state.isLoading = true;
      axios.delete(API_BASE_URL + '/client_vehicles/' + this.vehicleId, {headers: this.header})
      .then(data => {
        this.$noty.success( this.$t('vehicles.text_messages.deleted_successfully'), { timeout: 5000, layout: 'bottomLeft'});
        this.$router.push('/vehicles').catch(() => {});
      })
      .catch((error) => {
        this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft'});
      })
      .finally(() => {
        this.$store.state.isLoading = false;
      })
    },
  },
  watch:{
    search_client(val) {
        if (!val || this.client_id == val) {
            return
        }
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
            this.fetchClientsListDebounced()
        }, 500);
    },
    search_driver(val) {
        if (!val || this.driver_id == val) {
            return
        }
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
            this.fetchDriversListDebounced()
        }, 500);
    },
    confirmationDialogButtonClickedIs(newVal){
        if(newVal == true &&  this.confirmationDialogOperation != null){
            if(this.confirmationDialogOperation == 'delete' && this.confirmationDialogButtonClickedIs == true){
                this.deleteVehicle();
            }
            this.confirmationDialogButtonClickedIs = null;
        }
    }
  },
  computed: {

  }
}
</script>
<style scoped>
</style>
