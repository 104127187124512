<template>
  <v-app id="inspire" :class="this.$route.path == '/' ? 'smooth-bg-color' : ''">
    <Navigation v-if="isAuth"></Navigation>

    <v-app-bar app absolute v-if="isAuth">
      <v-app-bar-nav-icon @click="invertDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="text-blue-color" >{{ $t('router_titles.' + pageTitle) }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <div id="top-action-buttons">

          <!-- EDIT Mode -->
          <template v-if="$store.state.inTyreDepotMode ">
            <v-btn id="top-load-localisation" class="ma-2"  color="success" @click="loadLocalisation()">
              {{ $t("nav_buttons.load_localisation") }}
              <v-icon dark right> mdi-arrow-up-box </v-icon>
            </v-btn>
          </template>

          <!-- Update Mode -->
          <template v-if="$store.state.inUpdateMode ">
            <v-btn id="top-save" class="ma-2 text-white-color"  color="success" @click="submitMainForm($event)">
              {{ $t("nav_buttons.save") }}
              <v-icon dark right> mdi-content-save </v-icon>
            </v-btn>
          </template>

          <!-- List Mode -->
          <template v-if="$store.state.inViewMode">
            <v-btn id="top-create" class="ma-2"  color="primary" @click="onAddClick()">
              {{ $t("nav_buttons.add") }}
              <v-icon dark right> mdi-plus </v-icon>
            </v-btn>
          </template>

          <!-- DETAIL Mode or JUST CANCEL Mode-->
          <template v-if="$store.state.inDetailsMode || $store.state.inJustCancelMode">
            <v-btn id="top-print" class="ma-2 text-white-color" v-if="this.$route.path.includes('/articles/edit')"  color="primary" @click="printReport()">
              {{ $t("nav_buttons.print_article_label") }}
              <v-icon dark right> mdi-printer </v-icon>
            </v-btn>
            <v-btn id="top-attachments" v-if="this.$route.path.includes('/vehicles/edit')" class="ma-2 text-white-color"  color="success" @click="showAttachmentDialog()">
                {{ $t("attachments") }} ({{ $store.state.attachmentCounts}})
                <v-icon dark right> mdi-paperclip </v-icon>
            </v-btn>
            <v-btn v-if="! $store.state.inJustCancelMode" id="top-delete" class="ma-2 text-white-color"  color="error" @click="onDelete($event)">
              {{ $t("nav_buttons.delete") }}
              <v-icon dark right> mdi-delete </v-icon>
            </v-btn>
            <v-btn id="top-cancel" class="ma-2 text-black-color"  color="grey lighten-2" @click="$router.go(-1)">
              {{ $t("nav_buttons.cancel") }}
              <v-icon dark right> mdi-keyboard-return </v-icon>
            </v-btn>
            <v-btn v-if="! $store.state.inJustCancelMode" id="top-save" class="ma-2 text-white-color"  color="success" @click="submitMainForm($event)">
              {{ $t("nav_buttons.save") }}
              <v-icon dark right> mdi-content-save </v-icon>
            </v-btn>
          </template>

          <!-- EDIT Mode -->
          <template v-if="$store.state.inEditMode ">
            <v-btn id="top-cancel" class="ma-2 text-black-color"  color="grey lighten-2" @click="$router.go(-1)">
              {{ $t("nav_buttons.cancel") }}
              <v-icon dark right> mdi-keyboard-return </v-icon>
            </v-btn>
            <v-btn id="top-save" class="ma-2 text-white-color"  color="success" @click="submitMainForm($event)">
              {{ $t("nav_buttons.save") }}
              <v-icon dark right> mdi-content-save </v-icon>
            </v-btn>
          </template>

          <!-- ADD Mode -->
          <template v-if="$store.state.inAddMode ">
            <v-btn id="top-cancel" class="ma-2 text-black-color"  color="grey lighten-2" @click="$router.go(-1)">
              {{ $t("nav_buttons.cancel") }}
              <v-icon dark right> mdi-keyboard-return </v-icon>
            </v-btn>
            <v-btn id="top-create"  class="ma-2 text-white-color"  color="success" @click="submitMainForm($event)">
              {{ $t("nav_buttons.create") }}
              <v-icon dark right> mdi-plus-thick </v-icon>
            </v-btn>
          </template>

          <!-- RECEPTION CREATE Mode -->
          <template v-if="$store.state.inReceptionCreateMode ">
            <v-btn id="top-cancel" class="ma-2 text-black-color"  color="grey lighten-2" @click="$router.go(-1)">
              {{ $t("nav_buttons.cancel") }}
              <v-icon dark right> mdi-keyboard-return </v-icon>
            </v-btn>

            <v-btn id="top-save" class="ma-2 text-white-color"  color="success" @click="createReception($event)">
              {{ $t("nav_buttons.save") }}
              <v-icon dark right> mdi-content-save </v-icon>
            </v-btn>
          </template>

          <!-- STOCK LISTING Mode -->
          <template v-if="$store.state.inStockListingMode">
            <v-btn id="top-create" class="ma-2 text-white-color"  color="primary" @click="addArticleToStock($event)">
              {{ $t("nav_buttons.add_article_to_stock") }}
              <v-icon dark right> mdi-plus-thick </v-icon>
            </v-btn>
          </template>
          
          <!-- Scheduler Listing Mode -->
          <template v-if="$store.state.inSchedulerListingMode">
                <v-btn id="top-create" class="ma-2"  color="primary" @click="onAddClick()">
                  {{ $t("nav_buttons.add") }}
                  <v-icon dark right> mdi-plus </v-icon>
                </v-btn>
                <v-btn id="top-print" class="ma-2"  color="primary" @click="printReport()">
                  {{ $t("nav_buttons.print_depot_report") }}
                  <v-icon dark right> mdi-printer </v-icon>
                </v-btn>
          </template>
          <!-- Billing and Scheduler Mode -->
          <template v-if="$store.state.inBillingMode || $store.state.inSchedulerMode || $store.state.inSchedulerEditMode">
            <!-- <v-row id="top-bill-type" class="d-flex justify-end"> -->
              <!-- <v-col cols="12" sm="12" class="d-flex justify-end"> -->
                <div id="top-bill-type">
                  <v-select
                      :class="checkTag('billing') ? '' : 'zeroOpacity'"
                      :items="billTypes"
                      v-model="billType"
                      class="d-flex to-upper mr-4 mt-6 shrink"
                      style="width:200px"
                      item-value="id"
                      item-text="text"
                      return-object
                      :background-color="textbackgroundcolor"
                      outlined
                      :readonly="billCancelVisible"
                      dense
                      :disabled="$store.state.inSchedulerMode || $store.state.inSchedulerEditMode"
                  >
                    <template v-slot:selection="{ item }">
                      <span>
                          <v-icon>{{ item.icon }}</v-icon>
                          <template v-if="item.text.length > 12">
                            {{item.text.substring(0,12)}} ...
                          </template>
                          <template v-else>
                            {{item.text}}
                          </template>
                          
                      </span>
                    </template>
                    <template slot="item" slot-scope="{ item }">
                        <v-list-item-content class="pa-0 ma-0">
                          <span>
                              <v-icon>{{ item.icon }}</v-icon>
                              {{item.text}}
                          </span>
                        </v-list-item-content>
                    </template>
                  </v-select>
                </div>
                <v-btn id="top-delete" v-if="$store.state.inSchedulerEditMode" class="mr-4 mt-7 text-white-color"  color="error" @click="onDelete($event)">
                  {{ $t("nav_buttons.delete") }}
                  <v-icon dark right> mdi-delete </v-icon>
                </v-btn>

                <v-btn id="top-cancel" v-if="$store.state.inSchedulerMode || $store.state.inSchedulerEditMode" class="mr-4 mt-7 text-black-color"  color="grey lighten-2" @click="$router.go(-1)">
                  {{ $t("nav_buttons.cancel") }}
                  <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>

                <v-btn id="top-make-purchase-order" v-if="makePurchaseOrderVisible && showSaveButton && selectedBillData && checkTag('billing')" class="text-white-color mr-4 mt-7" color="primary" @click="makePurchaseOrder()">
                  {{ $t("nav_buttons.make_purchase_order") }}
                  <v-icon dark right> mdi-tag-text </v-icon>
                </v-btn>

                <v-btn id="top-make-bill" v-if="makeBillVisible && showSaveButton && selectedBillData && checkTag('billing')" class="text-white-color mr-4 mt-7" color="primary" @click="makeBill()">
                  {{ $t("nav_buttons.make_bill") }}
                  <v-icon dark right> mdi-script-text </v-icon>
                </v-btn>

                <v-btn id="top-make-credit-note" v-if="makeCreditNoteVisible && showSaveButton && selectedBillData && checkTag('billing')" class="text-white-color mr-4 mt-7" color="primary" @click="makeCreditNote()">
                  {{ $t("nav_buttons.make_credit_note") }}
                  <v-icon dark right> mdi-note-multiple </v-icon>
                </v-btn>
                
                <v-menu  v-if="showSaveButton && selectedBillData && checkTag('billing')" open-on-hover bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn id="top-print" dark v-bind="attrs" v-on="on" class="text-white-color mr-4 mt-7"  color="primary">
                      {{ $t("nav_buttons.print") }}
                      <v-icon dark right> mdi-printer </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="printBill($event)">
                        <v-icon color="green darken-2" left> mdi-printer </v-icon>
                        <template v-if="billType && billType.key == 'estimation'">
                            {{ $t("nav_buttons.sub_menu_estimation_print") }}
                        </template>
                        <template v-if="billType && billType.key == 'purchase_order'">
                            {{ $t("nav_buttons.sub_menu_purchase_print") }}
                        </template>
                        <template v-if="billType && billType.key == 'bill'">
                            {{ $t("nav_buttons.sub_menu_bill_print") }}
                        </template>
                        <template v-if="billType && billType.key == 'credit_note'">
                            {{ $t("nav_buttons.sub_menu_credit_print") }}
                        </template>
                    </v-list-item>
                    <v-list-item @click="emailBill($event)">
                        <v-icon color="green darken-2" left> mdi-email </v-icon>
                        {{ $t("nav_buttons.email") }}
                    </v-list-item>
                    <v-list-item @click="worksheetBill($event)">
                        <v-icon color="green darken-2" left> mdi-book-open-page-variant </v-icon>
                        {{ $t("nav_buttons.worksheet") }}
                    </v-list-item>
                    <v-list-item v-if="$store.state.showPrintReportInPrintDropDown" @click="printReport($event)">
                        <v-icon color="green darken-2" left> mdi-book-open-page-variant </v-icon>
                        {{ $t("nav_buttons.print_report") }}
                    </v-list-item>
                    
                  </v-list>
                </v-menu>

                <v-btn id="top-depot-in" color="primary" class="text-white-color mr-4 mt-7" v-if="checkTag('vehicles') && (showDepotButtons == 1 || showDepotButtons == 3 || showDepotButtons == 4)" @click="depotIn($event)">
                    <v-icon dark left> mdi-selection-ellipse-arrow-inside </v-icon>
                    {{ $t("nav_buttons.depot_in") }}
                </v-btn>
                <v-btn id="top-depot-out" :disabled="showDepotButtons == 4" color="primary" class="text-white-color mr-4 mt-7" v-if="checkTag('vehicles') && billType && billType.key != 'estimation' && (showDepotButtons == 2 || showDepotButtons == 3 || showDepotButtons == 4 )" @click="depotOut($event)">
                    <v-icon dark left> mdi-debug-step-out </v-icon>
                    {{ $t("nav_buttons.depot_out") }}
                </v-btn>

                <v-btn id="top-cancel" v-if="showSaveButton && !($store.state.inSchedulerMode || $store.state.inSchedulerEditMode)" class="text-black-color mr-4 mt-7"  color="grey lighten-2" @click="resetPageData()">
                  <template v-if="isBillSelected">{{ $t("nav_buttons.close") }}</template>
                  <template v-else>{{ $t("nav_buttons.cancel") }}</template>
                  <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
              
              
                <v-btn id="top-save" v-if="showSaveButton" class="text-white-color mr-4 mt-7"  color="success" @click="submitMainForm($event)">
                  {{ $t("nav_buttons.save") }}
                  <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>

                <v-btn id="top-new" v-if="showNewButton" class="text-white-color mr-4 mt-7"  color="primary" @click="resetPageData()">
                  {{ $t("nav_buttons.new") }}
                  <v-icon dark right> mdi-newspaper-plus </v-icon>
                </v-btn>
              <!-- </v-col> -->
            <!-- </v-row> -->
            
          </template>
      </div>
      <v-progress-linear class="data-progressbar" :active="this.$store.state.isLoading" indeterminate color="primary">
        
      </v-progress-linear>
    </v-app-bar>

    <v-main class="ml-5 mr-5 mt-8 mb-0" :class="this.$route.path == '/' ? 'smooth-bg-color' : ''">
      <!-- Alert Dialog  -->
      <div class="v-alert-positation-wrapper" v-for="alert in this.$store.state.alerts" :key="alert" :hidden="!alert.show">
        <v-alert class="v-alert-positation" :type="alert.type" dismissible border="left" elevation="10" colored-border>
          <span v-html="alert.msg"></span>
        </v-alert>
      </div>
      <keep-alive :include="isAuth ? includeAlive : ''">
        <router-view > </router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>


<script>

import Navigation from './components/Navigation';
import { API_BASE_URL } from "@/config";
import axios from '@/plugins/axios';

export default {
  components: {
    Navigation
  },
  data: () => ({
    includeAlive:['ArticlesListing','SchedulerListing','Scheduler'],
    right: null,
    pageTitle: "",
    isAuth: false,
    textbackgroundcolor: 'grey lighten-4',
    languages: [
      {
        title: 'English',
        code: 'en'
      },
      {
        title: 'Freanch',
        code: 'fr',
      }
    ],
  }),
  methods: {
    invertDrawer: function () {
      this.$store.commit('invertNavDrawerVisibility');

      return this.$store.state.showNavDrawer;
    },
    /**
     * Submit main form when click save button
     */
    submitMainForm: function () {
      document.getElementById(this.$route.meta.submit).click();
    },
    showAttachmentDialog: function () {
      document.getElementById('add-attachment').click();
    },
    createReception: function(){
      document.getElementById('create-reception').click();
    },
    addArticleToStock: function(){
      document.getElementById('add-article-to-stock').click();
    },
    loadLocalisation: function(){
      document.getElementById('add-localisation').click();
      console.log("loading");
    },
    printBill: function(){
      document.getElementById('print-bill').click();
    },
    worksheetBill: function(){
      document.getElementById('worksheet-bill').click();
    },
    printReport: function(){
      document.getElementById('print-report').click();
    },
    emailBill: function(){
      document.getElementById('email-bill').click();
    },
    depotIn: function(){
      document.getElementById('depot-in').click();
    },
    depotOut: function(){
      document.getElementById('depot-out').click();
    },
    resetPageData: function(){
      document.getElementById('reset-page-data').click();
    },
    makeBill: function(){
      document.getElementById('make-bill').click();
    },
    makePurchaseOrder: function(){
      document.getElementById('make-purchase-order').click();
    },
    makeCreditNote: function(){
      document.getElementById('make-credit-note').click();
    },
    onDelete: function () {
      document.getElementById(this.$route.meta.delete).click();
    },
    onAddClick () {
      this.$router.push(this.$route.path + '/add').catch(() => {});
    },
    logout () {
      const token = this.$store.state.AccessToken
      axios({ method: 'get', url: API_BASE_URL + '/logout', headers: { Authorization: 'Bearer ' + token},})
      .then(resLogout => {
        localStorage.clear();
        this.$store.state.AccessToken = ''
        this.$router.push('/login').catch(() => {});
      })
    },
    onListClick (title) {
      if (title == 'Logout') {
        this.logout()
      }
    },
    getBillTypes() {
      const token = this.$store.state.AccessToken;
      axios({ method: 'get', url: API_BASE_URL + '/codes?class_key=order_types', headers: { Authorization: 'Bearer ' + token},})
          .then(({data}) => {
              data.data = this.translateKeys(data.data, 'key', 'billings.types');
              data.data.forEach((element,index) => {
                if(element.key == "bill"){
                  data.data[index].icon = "mdi-script-text";
                }
                else if(element.key == "purchase_order"){
                  data.data[index].icon = "mdi-tag-text";
                }
                else if(element.key == "estimation"){
                  data.data[index].icon = "mdi-book-open";
                }
                else if(element.key == "credit_note"){
                  data.data[index].icon = "mdi-note-multiple";
                }
                
              });
              this.$store.commit('setBillTypes', data.data);
              this.billType = this.billTypes[1];
          })
          .catch(function (error) {
              console.log('an error occured ' + error);
          });
    },
    getFamilyArticles() {
        console.log(axios);
        axios.get(API_BASE_URL + '/family_articles?items_per_page=-1&sortby=order,name', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
        .then(({data}) => {
            data.data.forEach((family_article) => {
              // If family article has properties then its properties will be of family article
              if(family_article && family_article.parent_family_properties.length > 0){
                family_article.properties = family_article.parent_family_properties;
              }
            })
            this.$store.commit("setFamilyArticles",data.data);
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
    },
    getTyreTypes() {
        axios.get(API_BASE_URL + '/codes?class_key=tyre_types', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
        .then(({data}) => {
            this.$store.commit("setTyreTypes",data.data);
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
    },
    getWheelTypes(){
      axios.get(API_BASE_URL + "/codes?class_key=wheel_types", {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
      .then(({ data }) => {
        this.$store.commit("setWheelTypes", this.translateKeys( data.data, "key", "types"));
      })
      .catch(function (error) {
        console.log("an error occured " + error);
      });
    },
    getTyreSeason(){
      axios.get(API_BASE_URL + '/codes?class_key=tyre_season', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
      .then(({data}) => {
          this.$store.commit("setTyreSeasons",data.data);
      })
      .catch(function (error) {
          console.log('an error occured ' + error);
      });
    },
    // getResourceTypes(){
    //   axios.get(API_BASE_URL + '/codes?class_key=resource_type', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
    //   .then(({data}) => {
    //       this.$store.commit("setResourceTypes",this.translateKeys( data.data, "key", "resource_types"));
    //   })
    //   .catch(function (error) {
    //       console.log('an error occured ' + error);
    //   });
    // },
    getStatuses(){
      axios.get(API_BASE_URL + '/codes?class_key=article_statuses', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
      .then(({data}) => {
          this.$store.commit("setStatuses",this.translateKeys(data.data, 'key', 'article_statuses'));
      })
      .catch(function (error) {
          console.log('an error occured ' + error);
      });
    },
    getApprovals() {
        axios.get(API_BASE_URL + '/codes?class_key=article_approval_statuses', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
        .then(({data}) => {
            this.$store.commit("setApprovals",this.translateKeys(data.data, 'key', 'article_approval_statuses'));
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
    },
    getFamilyClients(){
        axios.get(API_BASE_URL + '/family_clients', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
        .then(({ data }) => {
            this.$store.commit("setFamilyClients",data.data);
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
    },
  },
  created () {
    setInterval(() => {
      this.pageTitle = this.$route.meta.title;
      if (this.$route.path == '/' || this.$route.path == '/rentals' || this.$route.path == '/rentals/history') {
        this.$store.state.inViewMode = false
      }
     
      this.isAuth = this.$route.meta.auth
      // console.log("Auth Route", this.isAuth);
      if ((!localStorage.getItem('accessToken') || !localStorage.getItem('applicationResources') ) && this.isAuth) {
        localStorage.clear();
        this.$router.push('/login').catch(() => {});
      }
    }, -1);
  },
  watch: {
    '$route' (to, from) {
      document.title = (localStorage.getItem("company_name") ? localStorage.getItem("company_name") + " - " : "") + this.$t('router_titles.' + to.meta.title);
      this.pageTitle = document.title;
      
    },
    billType:{
        handler:function(){
          if (this.billType.key == "purchase_order") {
            this.textbackgroundcolor = "green lighten-4";
          } else if (this.billType.key == "bill") {
            this.textbackgroundcolor = "deep-orange lighten-4";
          } else {
            this.textbackgroundcolor = "grey lighten-4";
          }
        }
    },
    isAuth:{
      handler:function(){
        if(this.$route.meta.auth){
          this.getFamilyArticles();
          this.getApprovals();
          this.getWheelTypes();
          this.getTyreSeason();
          this.getStatuses();
          this.getTyreTypes();
          // this.getResourceTypes();
          this.getFamilyClients();
        }
      }
    }
  },
  computed: {
    billType : {
      get: function() {
        if(!this.$store.state.billType || this.$store.state.billTypes.length == 0 ){
          if(this.$route.path.includes('billing') || this.$route.path.includes('scheduler')){
            this.getBillTypes();
          }
        }
        return this.$store.state.billType;  
      },
      set: function(newValue){
        this.$store.commit("setBillType",newValue);
      }
    },
    billCancelVisible : function(){
      return this.$store.state.billCancelVisible;  
    },
    makePurchaseOrderVisible : function(){
      return this.$store.state.makePurchaseOrder;  
    },
    makeBillVisible : function(){
      return this.$store.state.makeBillVisible;  
    },
    makeCreditNoteVisible : function(){
      return this.$store.state.makeCreditNote;  
    },
    showNewButton : function(){
      return this.$store.state.showNewButton;  
    },
    showSaveButton : function(){
      return this.$store.state.showSaveButton;  
    },
    showDepotButtons : function(){
      return this.$store.state.showDepotButtons;  
    },
    isBillSelected : function(){
      return this.$store.state.isBillSelected;  
    },
    selectedBillData : function(){
      return this.$store.state.selectedBillData;  
    }
  },
  mounted() {

  },
}
</script>
<style scoped>
  #top-action-buttons{
    display: flex;
    display: -webkit-flex;
    flex-direction: row-reverse; 
  }
  #top-new{
    order: 0;
  }
  #top-create{
    order: 1;
  }
  #top-save{
    order: 1;
  }
  #top-cancel{
    order: 2;
  }
  #top-delete{
    order: 3;
  }
  #top-depot-in{
    order: 4;
  }
  #top-depot-out{
    order: 5;
  }
  #top-make-bill{
    order: 6;
  }
  #top-make-purchase-order{
    order: 6;
  }
  #top-make-credit-note{
    order: 6;
  }
  #top-print{
    order: 7;
  }
  #top-bill-type{
    order: 8;
  }
  .zeroOpacity{
    opacity: 0;
  }
  #top-attachments{
    order: 9;
  }
</style>