<template>
  <v-card flat>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-form
      ref="form"
      class="main_form"
      id="family-client-form"
      @submit.prevent="onSubmit()"
    >
      <v-btn type="submit" class="d-none" id="submit-family-client"></v-btn>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-autocomplete
                :autocomplete="autocompleteValueLocal"
                flat
                :items="family_articles"
                v-model="family_article"
                :label="$t('brand.family_article')"
                class="mr-2 to-upper"
                item-value="id"
                item-text="name"
                return-object
                :error-messages="$store.state.validation && !$v.family_article.required ? [this.$t('brand.family_article_required')] : [] "
                :outlined="is_boxes"
                :dense="is_boxes"
                hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              v-model="name"
              :autocomplete="autocompleteValueLocal"
              name="name"
              :label="$t('brand.name')"
              v-on:keydown="handleInput($event,'alphanumeric',20);"
              class="to-upper"
              required
              :error-messages="$store.state.validation && !$v.name.required ? [this.$t('brand.name_required')] : [] "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
        </v-row>
        
        
      </v-container>
    </v-form>

    <v-btn @click="onSubmit()" class="d-none" id="submit-brand"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-brand"></v-btn>
  </v-card>
</template>

<script>
import axios from "axios";
import { API_BASE_URL, VIEW_MODE } from "@/config";
import { DETAILS_MODE, JUST_CANCEL_MODE } from "@/config";
import { ADD_MODE } from "@/config";
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha, minValue, maxValue } from 'vuelidate/lib/validators'
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  mixins: [validationMixin],
  components: {
    "confirmation-model":	ConfirmationModel,
  },
  validations: {
    name:{
      required: required
    },
    family_article:{
      required: required
    }
  },
  name: "Brand",
  data () {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      DeleteConfirm: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
      brands: [],
      brand: null,
      autocompleteValueLocal:'off',
      name: "",
      family_id: null,
      family_articles: [],
      family_article: null,
      confirmationDialog: false,
      confirmationDialogConfirmationText:null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
    }
  },
  mounted () {
    this.$store.commit("setValidation",false); 
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = { Authorization: "Bearer " + this.token };
    
    this.getAllFamilyArticles().then(() => {
        
        this.$store.commit("setValidation",false);
        if(this.$store.state.editId){ 
          this.$store.commit('setFormMode', DETAILS_MODE);
          this.fetchData();
        }
        else{
          this.$store.commit('setFormMode', ADD_MODE);
        }
    })
    
  },
  methods: {
    confirmationButtonClicked(buttonClicked){
        this.confirmationDialogButtonClickedIs = buttonClicked;
        this.confirmationDialog=false;
    },
    deleteItem(){
      this.$store.state.isLoading = true
      axios({ method: 'delete', url: API_BASE_URL + '/brands/' + this.$store.state.editId, headers: this.header })
      .then(() => {
        this.$router.push('/brands').catch(() => {});
      }).catch((error) => {
        // 
      })
      .finally(() => {
        this.$store.state.isLoading = false;
      })
    },
    onDelete() {
      console.log("delete clicked");
      this.deletePopUp();
    },
    fetchData() {
      this.$store.state.isLoading = true;
      return new Promise((resolve, reject) => {
          axios.get(API_BASE_URL + '/brands/' + this.$store.state.editId, {headers: this.header})
          .then(({ data }) => {
            this.brand = data.data;
            this.name = this.brand.name;
            this.family_article = this.brand.family;
            if(this.family_article.company_id == null){
              this.$store.commit('setFormMode', JUST_CANCEL_MODE);
            }
            else{
              this.$store.commit('setFormMode', DETAILS_MODE);
            }
            resolve();
          })
          .catch((err) => {
            reject();
          })
          .finally(() => {
            this.$store.state.isLoading = false;
          });
      });
    },
    onSubmit() {
      this.$store.commit("setValidation",true); 
      if (!this.$v.$invalid) {
        if (this.$store.state.editId) {
          this.updateBrand(this.makeData());
        } else {
          this.addBrand(this.makeData());
        }
      }
      else{
        this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft'})
      }
    },
    makeData(){
      return {
        // Right now we have to add brand to the parent family article
        family_id: this.family_article.parent_family_id ? this.family_article.parent_family_id :  this.family_article.id,
        // family_id : this.family_article.id,
        name: this.name
      };
    },
    addBrand(data) {
        this.$store.state.isLoading = true;
        axios.post(API_BASE_URL + '/brands', data, {headers: this.header})
        .then(response => {
            this.$router.push("/brands").catch(() => {});
        })
        .catch(function (error) {
          this.$noty.error( this.$t('brand.text_messages.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
        })
        .finally(() => {
          this.$store.state.isLoading = false;
        });
    },
    getAllFamilyArticles() {
      return new Promise((resolve, reject) => {
          this.$store.state.isLoading = true;
          axios.get(API_BASE_URL + '/family_articles', { headers: this.header})
          .then((data) => {
            this.family_articles = data.data.data;
            resolve();
          })
          .catch((err) => {
            reject();
          })
          .finally(() => {
            this.$store.state.isLoading = false;
          });
      });
    },
    updateBrand (data) {
        this.$store.state.isLoading = true;
        axios.put(API_BASE_URL + '/brands/' + this.brand.id , data, {headers: this.header})
        .then(response => {
            this.$router.push("/brands").catch(() => {});
        })
        .catch(function (error) {
          console.log('an error occured ' + error);
          this.$noty.error( this.$t('brand.text_messages.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
        })
        .finally(() => {
          this.$store.state.isLoading = false;
        });
    },
    deletePopUp(){
        this.confirmationDialogConfirmationText = this.$t("brand.DeleteConfirmation");
        this.confirmationDialogFalseText = this.$t("brand.Cancel");
        this.confirmationDialogTrueText = this.$t("brand.Delete");
        this.confirmationDialogOperation = 'delete';
        this.confirmationDialog = true;
    }
  },
  computed: {
    
  },
  watch:{
    confirmationDialogButtonClickedIs(newVal){
        if(newVal == true &&  this.confirmationDialogOperation != null){
            if(this.confirmationDialogOperation == 'delete' && this.confirmationDialogButtonClickedIs == true){
                this.deleteItem();
            }
            this.confirmationDialogButtonClickedIs = null;
        }
    }
  }
}
</script>

<style scoped>
</style>
