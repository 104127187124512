<template>
	<v-card flat>
		<confirmation-model :showDialog.sync="confirmationDialog" :openedForOperation.sync="confirmationDialogOperation"
			:text.sync="confirmationDialogConfirmationText" :trueText.sync="confirmationDialogTrueText"
			:falseText.sync="confirmationDialogFalseText"
			@buttonClicked="confirmationButtonClicked"></confirmation-model>
		<v-container fluid>
			<v-row>
				<v-col cols="12" md="8">
					<v-row>
						<v-col cols="12" md="4">
							<v-text-field autocomplete="off" v-model="registrationNo"
								class="to-upper" name="registrationNo" readonly
								v-on:keydown="handleInput($event, 'alphanumeric', 100);"
								:label="$t('vehicles.registration_number')" required
								:error-messages="$store.state.validation && !$v.registrationNo.required ? [this.$t('vehicles.text_messages.registration_number_required')] : []"
								:outlined="is_boxes" :dense="is_boxes"></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-select autocomplete="off" v-model="selectedBrand" name="brand"
								:items="brands" item-text="name" class="to-upper" item-value="id" readonly
								:label="$t('vehicles.brand')"  return-object :outlined="is_boxes"
								:dense="is_boxes" hide-no-data></v-select>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field autocomplete="off" v-model="model" name="model" readonly
								:label="$t('vehicles.model')" v-on:keydown="handleInput($event, 'alphanumeric', 80);"
								class="to-upper" :outlined="is_boxes" :dense="is_boxes"></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-select autocomplete="off" v-model="fuel" :items="fuels" readonly
								item-text="text" class="to-upper" item-value="id" :label="$t('vehicles.fuel')" return-object :outlined="is_boxes"
								:dense="is_boxes" hide-no-data></v-select>
						</v-col>
						<v-col cols="12" md="4">
							<v-select autocomplete="off" v-model="transmission" readonly
								:items="transmissions" item-text="text" class="to-upper" item-value="id"
								:label="$t('vehicles.transmission')"  return-object :outlined="is_boxes" :dense="is_boxes"
								hide-no-data></v-select>
						</v-col>
						<v-col cols="12" md="4">
							<v-select autocomplete="off" v-model="gearbox" :items="gearboxes" readonly
								item-text="text" class="to-upper" item-value="id" :label="$t('vehicles.gearbox')" return-object
								:outlined="is_boxes" :dense="is_boxes" hide-no-data></v-select>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="4">
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field autocomplete="off" v-model="vin" class="to-upper" readonly
								name="vin" v-on:keydown="handleInput($event, 'alphanumeric', 255);"
								:label="$t('vehicles.vin')" :outlined="is_boxes" :dense="is_boxes"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
              <v-text-field autocomplete="off" v-model="cylinder_capacity" readonly
                            class="to-upper" v-on:keydown="handleInput($event, 'int', 11);"
                            :label="$t('vehicles.cylinder_capacity')" :outlined="is_boxes"
                            :dense="is_boxes"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field autocomplete="off" v-model="power" class="to-upper" readonly
								v-on:keydown="handleInput($event, 'alphanumeric', 12);" :label="$t('vehicles.power')"
								:outlined="is_boxes" :dense="is_boxes"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field autocomplete="off" v-model="contract_number" readonly
								class="to-upper" v-on:keydown="handleInput($event, 'alphanumeric', 80);"
								:label="$t('vehicles.contract_number')" :outlined="is_boxes"
								:dense="is_boxes"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="12">
					<v-row>
						<v-col cols="12" md="12">
              <v-text-field autocomplete="off" v-model="km" class="to-upper"
                            v-on:keydown="handleInput($event, 'alphanumeric', 12);" :label="$t('vehicles.km')"
                            :outlined="is_boxes" :dense="is_boxes"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
		<rentals-page-tab v-if="vehicleId" :vehicle="vehicleData" />
	</v-card>
</template>

<script>
	import axios from "axios";
	import { validationMixin } from 'vuelidate'
	import { required, numeric, email, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators'
	import { helpers } from 'vuelidate/lib/validators'
	const alpha = helpers.regex('alpha', /^[a-zA-Z0-9 ]*$/)
	const phoneNum = helpers.regex('alpha', /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)
	import { API_BASE_URL } from "@/config";
	import { ADD_MODE } from "@/config";
	import { DETAILS_MODE } from "@/config";
	import { FAMILY_ARTICLE_CARS } from "@/config";
	//   import VehiclesPageTab from '@/components/Vehicles/VehiclesPageTab';
	import RentalsPageTab from '@/components/Rentals/RentalsPageTab'
	import AddClientDriver from '@/components/Vehicles/AddClientDriver';
	import ConfirmationModel from "@/components/ConfirmationModel";
	//   import AttachmentDialog from "@/components/AttachmentDialog";
	import { mdiCheck } from '@mdi/js';
	export default {
		name: "EditVehicle",
		mixins: [validationMixin],
		props: {
			is_reload_component: {
				type: Boolean,
				default: false
			},
		},
		components: {
			//   "vehicles-page-tab":VehiclesPageTab,
			"rentals-page-tab": RentalsPageTab,
			"confirmation-model": ConfirmationModel,
			//   "attachment-dialog": AttachmentDialog
		},
		validations: {
			registrationNo: {
				required
			},
			company_client_name: {
				required
			},
		},
		data() {
			return {
				is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
				registrationNo: '',
				model: '',
				selectedBrand: {},
				brands: [],
				vin: '',
				is_leasing: false,
				note: '',
				isEdit: false,
				vehicleId: this.$store.state.vehicleId,
				Regdate: new Date().toISOString().substr(0, 10),
				menuDtp: false,
				selectedDriver: '',
				selectedClients: '',
				company_client_names: [],
				client: null,
				driver: null,
				company_client_name: null,
				driver_names: [],
				driver_name: null,
				search_client: null,
				search_driver: null,
				token: this.$store.state.AccessToken,
				header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
				autocompleteValueLocal: 'off',
				confirmationDialog: false,
				confirmationDialogConfirmationText: null,
				confirmationDialogFalseText: null,
				confirmationDialogTrueText: null,
				confirmationDialogButtonClickedIs: null,
				confirmationDialogOperation: null,
				addClientDriverDialog: false,
				addClientDriverOperation: null,
				defaultAddClientDriverName: null,
				nowDate: new Date().toISOString().slice(0, 10),
				fuels: [],
				fuel: null,
				transmissions: [],
				transmission: null,
				gearboxes: [],
				gearbox: null,
				power: null,
				km: null,
				contract_end_date: new Date().toISOString().slice(0, 10),
				contract_number: null,
				menu_contract_end_date: false,
				creditor: null,
				creditors: [],
				is_out_of_circulation: false,
				is_for_rent: false,
				cylinder_capacity: null,
				vehicleData: null,
				// attachmentDialog: false
			}
		},
		created() {
			this.header = { Authorization: 'Bearer ' + this.token }
			this.getFuels();
			this.getTransmission();
			this.getGearBox();
			this.getCreditors();
			this.$route.path == '/vehicles/edit' ? this.isEdit = true : this.isEdit = false
			if (this.isEdit) {
				this.$store.commit('setFormMode', DETAILS_MODE);

			} else {
				this.$store.commit('setFormMode', ADD_MODE);
			}
			this.getBrands();
			this.autocompleteValueLocal = this.autocompleteValue();
		},
		methods: {
			//   showAttachmentDialog(){
			// 	  this.attachmentDialog = true;
			//   },
			getCreditors() {
				this.$store.state.isLoading = true;
				axios.get(API_BASE_URL + '/creditors', { headers: this.header })
					.then(({ data }) => {
						this.creditors = data.data;
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => (
						this.$store.state.isLoading = false
					))
			},
			confirmationButtonClicked(buttonClicked) {
				this.confirmationDialogButtonClickedIs = buttonClicked;
				this.confirmationDialog = false;
			},
			addClientDriverDialogClose(data) {
				if (data) { // Client Added in last popup
					if (this.addClientDriverOperation == 'client') {
						this.company_client_names = [data];
						this.company_client_name = data;
					}
					if (this.addClientDriverOperation == 'driver') {
						this.driver_names = [data];
						this.driver_name = data;
					}
				}
				else { // reset the values if its just search text because the form just cancelled
					if (this.addClientDriverOperation == 'client') {
						this.company_client_name = typeof this.company_client_name == "object" ? this.company_client_name : null;
					}
					if (this.addClientDriverOperation == 'driver') {
						this.driver_name = typeof this.driver_name == "object" ? this.driver_name : null;
					}
				}
				this.addClientDriverDialog = false;
				this.addClientDriverOperation = null;
			},
			openAddDriverDialog() {
				this.addClientDriverOperation = 'driver';
				this.addClientDriverDialog = true;
				this.defaultAddClientDriverName = this.driver_name ? "" : this.search_driver;
			},
			openAddClientDialog() {
				this.addClientDriverOperation = 'client';
				this.addClientDriverDialog = true;
				this.defaultAddClientDriverName = this.company_client_name ? "" : this.search_client;
			},
			deletePopUp() {
				this.confirmationDialogConfirmationText = this.$t("vehicles.DeleteConfirmation");
				this.confirmationDialogFalseText = this.$t("vehicles.Cancel");
				this.confirmationDialogTrueText = this.$t("vehicles.Delete");
				this.confirmationDialogOperation = 'delete';
				this.confirmationDialog = true;
			},
			onDelete() {
				console.log("delete clicked");
				this.deletePopUp();
			},
			getBrands() {
				if (FAMILY_ARTICLE_CARS) {
					if (this.cachedData['brands'][FAMILY_ARTICLE_CARS] && this.cachedData['brands'][FAMILY_ARTICLE_CARS].length > 0) {
						this.brands = this.cachedData['brands'][FAMILY_ARTICLE_CARS];
					}
					else {
						this.$store.state.isLoading = true;
						axios.get(API_BASE_URL + '/brands' + "?family=" + FAMILY_ARTICLE_CARS + "&data_paginated=0", { headers: this.header })
							.then(({ data }) => {
								this.brands = data.data;
								this.cachedData['brands'][FAMILY_ARTICLE_CARS] = this.brands;
							})
							.catch(function (error) {

							})
							.finally(() => {
								this.$store.state.isLoading = false;
							});
					}
				}
			},
			getFuels() {
				axios.get(API_BASE_URL + "/codes?class_key=vehicle_fuel", { headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken } })
					.then(({ data }) => {
						this.fuels = this.translateKeys(data.data, "key", "vehicles.fuels");
					})
					.catch(function (error) {
						console.log("an error occured " + error);
					});
			},
			getTransmission() {
				axios.get(API_BASE_URL + "/codes?class_key=vehicle_transmission", { headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken } })
					.then(({ data }) => {
						this.transmissions = this.translateKeys(data.data, "key", "vehicles.transmissions");
					})
					.catch(function (error) {
						console.log("an error occured " + error);
					});
			},
			getGearBox() {
				axios.get(API_BASE_URL + "/codes?class_key=vehicle_ger_box", { headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken } })
					.then(({ data }) => {
						this.gearboxes = this.translateKeys(data.data, "key", "vehicles.gearboxes");
					})
					.catch(function (error) {
						console.log("an error occured " + error);
					});
			},
			fetchClientsListDebounced() {
				if (!this.search_client || typeof this.search_client == "object") {
					return;
				}
				this.$store.state.isLoading = true;
				axios.get(API_BASE_URL + '/clients/search?' + (this.search_client ? ('&search=' + this.search_client) : ''), { headers: this.header })
					.then(({ data }) => {
						this.company_client_names = data.data;
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => (
						this.$store.state.isLoading = false
					))
			},
			fetchDriversListDebounced() {
				if (!this.search_driver || typeof this.search_driver == "object") {
					return;
				}
				this.$store.state.isLoading = true;
				axios.get(API_BASE_URL + '/clients/search?' +
					(this.search_driver ? ('&search=' + this.search_driver) : '')
					// "&driver=true" 
					, { headers: this.header })
					.then(({ data }) => {
						this.driver_names = data.data;
					})
					.catch((err) => {

					})
					.finally(() => (
						this.$store.state.isLoading = false
					))
			},
			createVehicle(payload) {
				this.$store.state.isLoading = true;
				axios.post(API_BASE_URL + '/client_vehicles', payload, { headers: this.header })
					.then(response => {
						this.$noty.success(this.$t('vehicles.text_messages.vehicle_created_successfully'), { timeout: 5000, layout: 'bottomLeft' });
						this.$router.push('/vehicles').catch(() => { })
					})
					.catch((error) => {
						if (error.response.data.errors && error.response.data.errors[0]) {
							this.$noty.error(this.$t('vehicles.text_messages.' + error.response.data.errors[0]), { timeout: 5000, layout: 'bottomLeft' });
						}
						else {
							this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft' });
						}
					})
					.finally(() => {
						this.$store.state.isLoading = false;
					})
			},
			editVehicle(payload) {
				this.$store.state.isLoading = true;
				axios.put(API_BASE_URL + '/client_vehicles/' + this.vehicleId, payload, { headers: this.header })
					.then(response => {
						this.$noty.success(this.$t('vehicles.text_messages.vehicle_updated_successfully'), { timeout: 5000, layout: 'bottomLeft' });
						this.$router.push('/vehicles').catch(() => { });
					})
					.catch((error) => {
						this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft' });
					})
					.finally(() => {
						this.$store.state.isLoading = false;
					})
			},
			onSubmit() {
				this.$store.commit("setValidation", true); // enable validation
				this.company_client_name = typeof this.company_client_name == "object" ? this.company_client_name : null; // reset company client name if client is not choosen
				this.driver_name = typeof this.driver_name == "object" ? this.driver_name : null; // reset company client name if client is not choosen
				if (this.$v.$invalid) {
					this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft' });
				}
				else {
					if (this.isEdit) {
						this.editVehicle(this.makeSubmitData())
					} else {
						this.createVehicle(this.makeSubmitData())
					}
				}
			},
			makeSubmitData() {
				console.log("creditor id", typeof this.creditor == 'object');
				return {
					registration_number: this.registrationNo,
					brand_id: this.selectedBrand ? this.selectedBrand.id : null,
					model: this.model,
					registration_date: this.Regdate,
					vin: this.vin,
					client_id: this.company_client_name ? this.company_client_name.id : null,
					driver_id: this.driver_name ? this.driver_name.id : null,
					is_leasing: this.is_leasing ? 1 : 0,
					note: this.note,
					transmission_id: this.transmission ? (typeof this.transmission == 'object' ? this.transmission.id : this.transmission) : null,
					fuel_id: this.fuel ? (typeof this.fuel == 'object' ? this.fuel.id : this.fuel) : null,
					gearbox_id: this.gearbox ? (typeof this.gearbox == 'object' ? this.gearbox.id : this.gearbox) : null,
					km: this.km,
					power: this.power,
					contract_number: this.contract_number,
					contract_end_date: this.contract_end_date,
					creditor_id: this.creditor ? (typeof this.creditor == 'object' ? this.creditor.creditor_id : this.creditor) : null,
					cylinder_capacity: this.cylinder_capacity ?? null,
					is_out_of_circulation: this.is_out_of_circulation ?? null,
					// is_for_rent: this.is_for_rent ?? null
					is_for_rent: this.is_for_rent ? 1 : 0
				}
			},
			fetchData() {
				this.$store.state.isLoading = true
				axios.get(API_BASE_URL + '/client_vehicles/' + this.vehicleId, { headers: this.header })
					.then(res => {
						console.log('Vehicle Response : ', res)
						this.vehicleData = res.data;
						this.$store.state.isLoading = false
						this.id = res.data.id;
						this.registrationNo = res.data.registration_number;
						this.selectedBrand = res.data.brand;
						this.model = res.data.model;
						this.Regdate = res.data.registration_date;
						this.vin = res.data.vin;
						this.company_client_names = res.data.client ? [res.data.client] : [];
						this.company_client_name = res.data.client;
						this.driver_names = res.data.driver ? [res.data.driver] : [];
						this.driver_name = res.data.driver;
						this.is_leasing = res.data.is_leasing;
						this.note = res.data.note;
						this.km = res.data.km;
						this.power = res.data.power;
						this.contract_number = res.data.contract_number;
						this.contract_end_date = res.data.contract_end_date;
						this.transmission = res.data.transmission;
						this.fuel = res.data.fuel;
						this.gearbox = res.data.gearbox;
						this.creditor = res.data.creditor_id;
						this.cylinder_capacity = res.data.cylinder_capacity;
						this.is_out_of_circulation = res.data.is_out_of_circulation;
						this.is_for_rent = res.data.is_for_rent;

					})
					.catch(() => {

					})
			},
			deleteVehicle() {
				this.$store.state.isLoading = true;
				axios.delete(API_BASE_URL + '/client_vehicles/' + this.vehicleId, { headers: this.header })
					.then(data => {
						this.$noty.success(this.$t('vehicles.text_messages.deleted_successfully'), { timeout: 5000, layout: 'bottomLeft' });
						this.$router.push('/vehicles').catch(() => { });
					})
					.catch((error) => {
						this.$noty.error(error, { timeout: 5000, layout: 'bottomLeft' });
					})
					.finally(() => {
						this.$store.state.isLoading = false;
					})
			},
		},
		watch: {
			is_reload_component(oldvalue, newValue) {
				if (oldvalue != newValue) {
					this.vehicleId = this.$store.state.vehicleId;
					this.fetchData();
				}

			},
			search_client(val) {
				if (!val || this.client_id == val) {
					return
				}
				clearTimeout(this._searchTimerId);
				this._searchTimerId = setTimeout(() => {
					this.fetchClientsListDebounced()
				}, 500);
			},
			search_driver(val) {
				if (!val || this.driver_id == val) {
					return
				}
				clearTimeout(this._searchTimerId);
				this._searchTimerId = setTimeout(() => {
					this.fetchDriversListDebounced()
				}, 500);
			},
			confirmationDialogButtonClickedIs(newVal) {
				if (newVal == true && this.confirmationDialogOperation != null) {
					if (this.confirmationDialogOperation == 'delete' && this.confirmationDialogButtonClickedIs == true) {
						this.deleteVehicle();
					}
					this.confirmationDialogButtonClickedIs = null;
				}
			}
		},
		computed: {

		}
	}
</script>
<style scoped>

</style>