<template>                       
    <v-dialog v-model="show_dialog" @click:outside="closeUpdater()"  @keydown.esc="closeUpdater()" persistent max-width="700px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ $t('bulk_updater.title') }}</span>
            </v-card-title>
            <v-card-text>
                <v-container class="pt-0">
                    <v-row class="mb-n8">
                        <v-col>
                            <p class="filter">
                                <strong>    
                                    {{ $t('bulk_updater.filter') }}:  
                                    <span>{{ getAppliedFilters() }}</span>
                                </strong>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col>
                            <p class="orderSubTitle" style="color:red"> 
                                <strong>
                                    <u>
                                        <span style="">{{ $t('bulk_updater.warning') }}: </span>
                                    </u>
                                </strong><br>
                                {{ $t('bulk_updater.warning_note_1') }} <br>
                                {{ $t('bulk_updater.warning_note_2') }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="">
                        <v-col>
                            <td v-on:keydown.exact="checkIfMinusPressed($event, 'amount')">
                                <custom-money-input
                                    :autocomplete="autocompleteValueLocal"
                                    class="pt-0 to-upper right-aligned-input shrink"
                                    hide-details="auto"
                                    :label="$t('bulk_updater.amount')"
                                    dense
                                    tabindex="3"
                                    ref="tableUnitPrice"
                                    v-model="amount"
                                    v-bind:properties="{
                                        prefix: currencySymbol,
                                        readonly: false,
                                        disabled: false,
                                        outlined: false,
                                        placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                        dense: true,
                                        hideDetails:'auto',
                                    }"
                                    v-bind:options="{
                                        locale: userLanguageId,
                                        length: 6,
                                        precision: getDecimalNumber(),
                                        empty: getDecimalNumber() == 2 ? 0.00 : 0.000,
                                    }"
                                />
                            </td>
                        </v-col>
                        <v-col>
                            <td v-on:keydown.exact="checkIfMinusPressed($event, 'percent')">
                                <custom-money-input
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="percent"
                                    :label="$t('bulk_updater.percentage')"
                                    :background-color="!$v.percent.maxValue ? '#ffc2b7' : (!$v.percent.minValue ? '#ffc2b7' : 'white') "                      
                                    v-bind:properties="{
                                        suffix: '%',
                                        readonly: false,
                                        disabled: false,
                                        outlined: false,
                                        clearable: false,
                                        placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                        dense: true,
                                        hideDetails:'auto'
                                    }"
                                    v-bind:options="{
                                        locale: userLanguageId,
                                        length: 4,
                                        precision: getDecimalNumber(),
                                        empty: getDecimalNumber() == 2 ? 0.00 : 0.000,
                                    }"
                                    class="pt-0 to-upper right-aligned-input shrink"
                                    hide-details="auto"
                                    dense
                                    tabindex="4"
                                    v-bind:focus="true"
                                />
                            </td>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-2" @click="closeUpdater()">
                    {{ $t('bulk_updater.cancel') }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn color="success" @click="saveUpdater()" :disabled="(amount == 0 && percent == 0) || getAppliedFilters().length == 0">
                    {{ $t('bulk_updater.save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped>
    .right-aligned-input >>> input {
        text-align: right
    }
    .text-red >>> input{
        color: red !important;
    }
</style>
<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import {API_BASE_URL} from "@/config";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import { validationMixin } from 'vuelidate'
import { minValue, maxValue } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    validations: {
        percent: {
            maxValue: maxValue(100),
            minValue: minValue(0)
        },
    },
    components:{
        "custom-money-input": CutsomMoneyInput
    },
    props: {
        ruleType:{
            Object
        },
        condition:{
            Object
        },
        familyArticle:{
            Object
        },
        supplier:{
            Object
        },
        familyClient:{
            Object
        },
        startDate:{
            String
        },
        endDate:{
            String
        },
        search:{
            Object
        }
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            show_dialog :   true,
            autocompleteValueLocal : 'off',
            modifyDots: false,
            amount: 0,
            percent: 0,
            currencySymbol: this.getCurrencySymbol('EUR'),
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            token: this.$store.state.AccessToken,
        };
    },
    methods: {
        checkIfMinusPressed(event, model_name){
            if(/^[/-]$/i.test(event.key)){
                console.log(model_name, model_name == 'percent');
                if(model_name == 'amount'){
                    this.amount = this.amount * -1;
                }
                if(model_name == 'percent'){
                    this.percent = this.percent * -1;
                }
            }
        },
        getAppliedFilters(){
            let filters = "";
            if(this.ruleType && this.ruleType.text){
                filters += this.ruleType.text.toUpperCase() + " / ";
            }
            if(this.condition && this.condition.text){
                filters += this.condition.text.toUpperCase() + " / ";
            }
            if(this.familyArticle && this.familyArticle.name){
                filters += this.familyArticle.name.toUpperCase() + " / ";
            }
            if(this.supplier && this.supplier.name){
                filters += this.supplier.name.toUpperCase() + " / ";
            }
            if(this.familyClient && this.familyClient.name){
                filters += this.familyClient.name.toUpperCase() + " / ";
            }
            if(this.startDate){
                filters += this.startDate.toUpperCase() + " / ";
            }
            if(this.endDate){
                filters += this.endDate.toUpperCase() + " / ";
            }
            if(this.search){
                filters += this.search.toUpperCase() + " / ";
            }
            return filters.slice(0,-2);
        },
        mounted() {
            this.autocompleteValueLocal = this.autocompleteValue();
            this.header = { Authorization: 'Bearer ' + this.token };
        },
        showModifyDotDialog(id){
            this.modifyDots = true;
        },
        changeModifyArticleDialog(){
            this.modifyDots = ! this.modifyDots;
        },
        closeUpdater() {
            this.show_dialog = false;
            this.$emit('modifyClose', false);
        },
        saveUpdater(){
            let data = {
                ruleTypeId:     this.ruleType       ? this.ruleType.id : null,
                conditionId:    this.condition      ? this.condition.id : null,
                familyArticleId:this.familyArticle  ? this.familyArticle.id : null,
                supplierId:     this.supplier       ? this.supplier.id : null,
                familyClientId: this.familyClient   ? this.familyClient.id : null,
                startDate:      this.startDate      ? this.startDate : null,
                endDate:        this.endDate        ? this.endDate : null,
                search:         this.search         ? this.search : null,
                amount:         this.amount         ? this.amount : null,
                percent:        this.percent        ? this.percent : null 
            }; 
            axios.put(API_BASE_URL + '/pricing_rules', data,{headers: this.header})
            .then(response => {
                if (response.status === 200) {
                    // this.$router.push("/family-clients").catch(() => {});
                }
                else {
                    alert('ERROR on saving. Please try again later');
                }
                console.log("response added", response);
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            })
            .finally(() => {
                this.show_dialog = false;
                this.$emit('modifyClose', true);
            });
        }
    }
}
</script>
<style scoped>
    .filter{
        color: black;
        font-size: 20px;
    }
    .filter span{
        color: #1976d2;
    }
</style>