<template>
  <v-row>
    <v-row>
      <v-col class="d-flex pl-6 pa-2 ma-0" cols="12" sm="12">
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('billings.search')"
          prepend-icon="mdi-magnify"
          v-bind:clearable="true"
          v-model="search"
          @focus="search=null"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('billings.identifiant')"
          v-bind:clearable="true"
          @focus="ftrByIdentifiant=null"
          v-model="ftrByIdentifiant"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
        <v-autocomplete
            flat
            :items="statuses"
            v-model="status"
            :label="$t('billings.status')"
            class="mr-2 to-upper"
            item-value="id"
            item-text="text"
            @focus="status=null"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
        ></v-autocomplete>
        <v-select
            :items="billTypes"
            v-model="billType"
            class="d-flex to-upper"
            item-value="id"
            item-text="text"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
        >
        </v-select>

        <v-col class="d-flex" cols="12" sm="2">
          <v-combobox
            v-on:keydown="handleInput($event,'int',2);"
            @keyup="handleAutoFocus($event,2,'day','int')"
            autocomplete="donotautocomplete"
            v-model="day"
            :items="days"
            @input="debounceInput"
            @focus="day=null"
            class="pt-0 to-upper"
            item-value="day"
            item-text="day"
            ref="day"
            :label="$t('billings.day')"
            v-on:keydown.enter.prevent=""
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-combobox>
          <v-combobox
              v-on:keydown="handleInput($event,'int',2);"
              @keyup="handleAutoFocus($event,2,'month','int')"
              autocomplete="donotautocomplete"
              v-model="month"
              ref="month"
              :items="months"
              class="pt-0 to-upper"
              @input="debounceInput"
              @focus="month=null"
              item-value="id"
              item-text="text"
              :label="$t('billings.month')"
              v-on:keydown.enter.prevent=""
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-combobox>
          <v-combobox
              v-on:keydown="handleInput($event,'int',4);"
              autocomplete="donotautocomplete"
              @input="debounceInput"
              v-model="year"
              ref="year"
              :items="years"
              class="pt-0 to-upper"
              @focus="year"
              item-value="year"
              item-text="year"
              :label="$t('billings.year')"
              v-on:keydown.enter.prevent=""
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-combobox>
        </v-col>
        
      </v-col>
    </v-row>

    <v-row>
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions
        }"
        :headers="headers"
        :items="billings"
        :options="options"
        :server-items-length="totalItems"
        :loading="isLoading"
        :loading-text="this.$t('loading')"
        class="elevation-0"
        :items-per-page="itemsPerPage"
        item-key="identifier"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        style="width:100%"
        :height="'calc(-600px + 100vh)'"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortOrder"
      >
      <template v-slot:item="{ item }">
        
              <tr :class="item.due_date_status == 0 && billType.key == 'bill' ?  'dueDate' : ''" :key="item.id">
                <td>
                  <v-tooltip right z-index="9999">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning"  v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <v-list-item class="pa-0 ma-0" v-for="(articles, index) in item.details" :key="index">
                        <template v-if="articles.article && articles.article.descriptions.length > 0 " >
                          {{ articles.article.descriptions[0].description }}
                        </template>
                    </v-list-item>
                  </v-tooltip>
                </td>
                <td>{{ item.id || "-" }}</td>
                <!-- <td>{{ item.parent_order_id }}</td> -->
                <td>{{ item.bill_number }}</td>
                <!-- <td>
                  <span>{{ item.client.name }} {{ item.client.first_name ? item.client.first_name : '' }}</span>
                </td> -->
                <!-- <td>{{ item.reference }}</td> -->
                <td align="right">{{ item.total_without_vat }}</td>
                <td align="right">{{ item.total }}</td>
                <!-- <td>
                  <span v-if="item.payedBy">{{ item.payedBy.key }}</span>
                </td> -->
                <td>{{ $t("billings.statuses."+item.status.value) }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                  <v-btn class="ma-2 text-white-color"  color="primary" small @click="editItem(item)">
                    {{ $t("btn_view") }}
                    <v-icon dark right> mdi-eye </v-icon>
                  </v-btn>
                </td>
              </tr>
          
      </template>
      </v-data-table>
    </v-row>
  </v-row>
</template>

<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";


export default {
  name: "Bill Tab",
  props:{
    'client': [Object]
  },
  data () {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      isLoading:                true,
      totalItems:               0,
      page:                     1,
      lastPage:                 1,
      sortBy:                   'id',
      sortDesc:                 true,
      sortOrd:                  'DESC',
      itemsPerPage:             DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      billings:                 [],
      billType:                 '',
      billTypes:                [],
      search:                   '',
      ftrByIdentifiant:        '',
      ftrByCompany:            '',
      date:                     null,
      menu_date:                false,
      options:                  {},
      dataTableOptions:         DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token:                    this.$store.state.AccessToken,
      header:                   {},
      companies:                [],
      company:                  '',
      nextEvents:               [],
      nextEvent:                '',
      allStatuses:              [],
      statuses:                 [],
      status:                   '',
      years:                    [],
      year:                     '',
      months:                   [],
      month:                    '',
      days:                     [],
      day:                      '',
      isPageLoaded:             false,
      autocompleteValueLocal:   'off',
      isDebounceCancelled:      false

    }
  },
  mounted () {
    // this.search = '';
    this.fillYears();
    this.fillMonths();
    this.header = { Authorization: 'Bearer ' + this.token },
    this.$store.commit('setAddButtonRoute', 'AddClient')
    
    this.getStatuses().then(() => {
      this.getBillTypes().then(() => {
        this.isPageLoaded = true;
        this.billType = this.billTypes[1];

      });
    });
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  
  created(){
    this.$watch('search', _.debounce(() => {
      this.isLoading = true;
      this.fetchData()
    }, 500))
  },
  methods: {
    debounceInput: _.debounce(function (e) {
      if(this.isDebounceCancelled){
        this.isDebounceCancelled = false;
        return false;
      }
      this.fetchData();
    }, 500),
    /**
     * This Function is responsible to autofocus on next property field, if current's max length reached
     */
    handleAutoFocus(event,search_length,propertyName,dataType, forceToJumpOnProperty){
      let currentFieldLength = 0;
      if(dataType == 'int' || dataType == 'float'){
        currentFieldLength = (event.target.value + '').replace('.', '').length; 
      }
      else{
        currentFieldLength = (event.target.value + '').length; 
      }
      if(forceToJumpOnProperty && search_length != null && currentFieldLength == search_length){
        this.$refs[forceToJumpOnProperty][0].focus();
      }
      else if(propertyName.search("property") == 0 && search_length != null && currentFieldLength == search_length){
        let underscorePos = propertyName.search("_") + 1;
        let propertyNo = Number(propertyName.substr(underscorePos)) + 1;
        let nextPropertyName = "property_" + (propertyNo < 10 ? '0' : '') + propertyNo;
        this.$refs[nextPropertyName][0].focus();
      }
    },
    fillYears(){
      for(let i = new Date().getFullYear(); i > 2014 ; i--){
        this.years.push({year: i});
      }
      this.year = this.years[0];
    },
    fillMonths(){
      // this.months = [{'id'  : "01",'text' : 'January'},{'id'  : "02",'text' : 'February'},{'id'  : "03",'text' : 'March'},{'id'  : "04",'text' : 'April'},{'id'  : "05",'text' : 'May'},{'id'  : "06",'text' : 'June'},{'id'  : "07",'text' : 'July'},{'id'  : "08",'text' : 'August'},{'id'  : "09",'text' : 'September'},{'id'  : "10",'text' : 'October'},{'id'  : "11",'text' : 'November'},{'id'  : "12",'text' : 'December'}];
      this.months = [{'id'  : "01",'text' : '01'},{'id'  : "02",'text' : '02'},{'id'  : "03",'text' : '03'},{'id'  : "04",'text' : '04'},{'id'  : "05",'text' : '05'},{'id'  : "06",'text' : '06'},{'id'  : "07",'text' : '07'},{'id'  : "08",'text' : '08'},{'id'  : "09",'text' : '09'},{'id'  : "10",'text' : '10'},{'id'  : "11",'text' : '11'},{'id'  : "12",'text' : '12'}];
    },
    fetchData () {
      this.isLoading = true
      this.billings = [];
      axios.get(API_BASE_URL + '/orders?page=' + this.page +
          '&items_per_page=' + this.itemsPerPage + 
          '&sortBy=' + (this.sortBy ? this.sortBy : 'id') +
          '&sortOrd=' + (this.sortOrd ? this.sortOrd : '') +
          (this.search ? ('&search=' + this.search) : '' )+ 
          (this.ftrByIdentifiant ? ('&document_number=' + this.ftrByIdentifiant) : '' ) + 
          (this.company ? ('&company_id=' + this.company.company_id) : ''  ) +
          (this.client ? ('&client_id=' + this.client.id) : ''  ) +
          (this.status ? ('&status_id=' + this.status.id) : '' ) +
          (this.day ? ('&day=' + this.day) : '' ) + 
          (this.year ? ('&year=' + this.year.year) : '' ) + 
          (this.month ? ('&month=' + this.month.id) : '' ) + 
          (this.billType ? ('&type_id=' + this.billType.id) : '' ) 
      , {
        headers: this.header
      })
        .then(({ data }) => {
          this.billings = data.data;
          this.lastPage = data.meta.last_page;
          this.totalItems = data.meta.total;
          this.page = data.meta.current_page;
          // this.itemsPerPage = parseInt(data.meta.per_page);
          // this.sortBy = null;
          // this.sortDesc = null;
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        });
    },
    filterOutStatuses(){
      console.log("bill Types",this.billType);
      if(this.billType && this.billType.key == "estimation"){
        this.statuses = this.allStatuses.filter((element) => {
          if(this.listingStatusFilters.estimation.find((el) => {return element.key == el})){
            return element;
          }
        });
      }
      else if(this.billType && this.billType.key == "purchase_order"){
        this.statuses = this.allStatuses.filter((element) => {
          if(this.listingStatusFilters.purchase_order.find((el) => {return element.key == el})){
            return element;
          }
        });
      }
      else if(this.billType && this.billType.key == "bill"){
        this.statuses = this.allStatuses.filter((element) => {
          if(this.listingStatusFilters.bill.find((el) => {return element.key == el})){
            return element;
          }
        });
      }
      else if(this.billType && this.billType.key == "credit_note"){
        this.statuses = this.allStatuses.filter((element) => {
          if(this.listingStatusFilters.credit_note.find((el) => {return element.key == el})){
            return element;
          }
        });
      }
      else{
        return this.allStatuses;
      }
      this.status = this.statuses[0];
    },
    getBillTypes() {
      return new Promise((resolve, reject) => {
        axios(API_BASE_URL + '/codes?class_key=order_types',{headers: this.header})
            .then(({data}) => {
              console.log(data.data);
                this.billTypes = this.translateKeys(data.data, 'key', 'billings.types');
                this.billTypes.forEach((element,index) => {
                  if(element.key == "bill"){
                    this.billTypes[index].icon = "mdi-script-text";
                  }
                  else if(element.key == "purchase_order"){
                    this.billTypes[index].icon = "mdi-tag-text";
                  }
                  else if(element.key == "estimation"){
                    this.billTypes[index].icon = "mdi-book-open";
                  }
                  else if(element.key == "credit_note"){
                    this.billTypes[index].icon = "mdi-note-multiple";
                  }
                  
                });
                // this.billType = this.billTypes[1];
                resolve();
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
                reject();
            });
      });
    },
    getStatuses() {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/codes?class_key=order_status',{headers: this.header})
            .then(({data}) => {
              console.log(data.data);
                this.allStatuses = this.translateKeys(data.data, 'key', 'billings.statuses');
                resolve();
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
                reject();
            });
      });
    },
    getCompanies() {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/companies',{headers: this.header})
            .then(({data}) => {
              console.log(data.data);
                this.companies = data.data.result.data;
                resolve();
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
                reject();
            });
      });
    },
    getNextEvents() {
      return new Promise((resolve, reject) => {
      axios.get(API_BASE_URL + '/codes?class_key=order_next_event',{headers: this.header})
        .then(({data}) => {
          console.log(data.data);
            this.nextEvents = this.translateKeys(data.data, 'key', 'billings.nextEvents');
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
      });
    },
    
    updatePagination (page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage (itemsPerPage) {
      this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
      this.fetchData();
      this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
    },
    editItem (item) {
      
      this.$store.commit("seteditId",item.id);
      console.log(this.$store.state.editId,"commited Bill ID");
      this.$router.push('/billing/edit').catch(() => {});
    },
    deleteItem (item) {
      console.log("Delete Item");
    },
    

    updateSort (sortBy) {
      console.log('data =>', sortBy);
      if (typeof sortBy == 'object') {
        this.sortBy = sortBy[0]
      } else {
        this.sortBy = sortBy
      }
      this.fetchData()
      console.log('sort by => ', this.sortBy);
    },
    updateSortOrder (sortDesc) {
      console.log('data => ', sortDesc);
      if (typeof sortDesc == 'object') {
        !sortDesc[0] ? this.sortOrd = 'ASC' : this.sortOrd = 'DESC'
      } else {
        !sortDesc ? this.sortOrd = 'ASC' : this.sortOrd = 'DESC'
      }
      
      this.fetchData()
      console.log('sort order => ', this.sortOrd);
    },
    onSearch () {
      this.fetchData();
    }
  },
  computed:{
    headers(){
      return [
        { text: this.$t('billings.info'),        value: 'id',              width:50, sortable:false },
        { text: 'ID',                            value: 'id',              width:50, sortable:true },
        // { text: this.$t('billings.parent'),      value: 'parent_order_id', width:100, sortable:false },
        { text: this.$t('billings.identifiant'), value: 'bill_number',     width:100, sortable:false },
        // { text: this.$t('billings.client'),      value: 'client_id' ,      width:100, sortable:false},
        // { text: this.$t('billings.message'),     value: 'message' ,        width:220, sortable:false},
        { text: this.$t('billings.totalThtva'),  value: 'message' ,        width:120, sortable:false, align: 'end'},
        { text: this.$t('billings.total'),       value: 'message' ,        width:100, sortable:false, align: 'end'},
        // { text: this.$t('billings.payed_by'),    value: 'payed_by_id' ,    width:90, sortable:false, align: 'center'},
        { text: this.$t('billings.status'),      value: 'status.key',      width:100, sortable:false },
        { text: this.$t('billings.createdAt'),   value: 'created_at' ,     width:200, sortable:false},
        { text: this.$t('billings.actions'),     value: 'id' ,     width:20, sortable:false},
      ];
    }
  },
  watch: {
    search:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    ftrByIdentifiant:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    company:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    client:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    status:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    billType:{
      handler:function(){
        this.filterOutStatuses()
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    date:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    year:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    day:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
    month:{
      handler:function(){
        if(this.isPageLoaded){
          this.fetchData();
        }
      }
    },
  }
}

</script>

<style scoped>
    tr:hover {
      border: 2px solid blue;
      color:#000;
      font-weight: 500;
    }
    tr.dueDate {
      background-color: rgb(255, 220, 220) !important;
    }
    tr.dueDate:hover {
      background: rgb(255, 153, 153) !important;
      border: 2px solid blue;
      color:#fff;
      font-weight: 500;
    }
    .v-tooltip__content {
      font-size: 14px !important;
      background: rgb(255, 255, 255) !important;
      border: 1px solid black;
      line-height: 0%;
      opacity: 1 !important;
    }

</style>
BillsTab